import React, { Component } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "../api/api"
import { logoutUser } from '../store/actions';

// users
import user4 from "../assets/images/users/user-4.jpg";

class ProfileMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState((prevState) => ({
      menu: !prevState.menu,
    }));
  }

  handleLogouts(){
    const loginDetails = this.props.login
    logout(loginDetails).then((res) => {
      //console.log("logout", res);
    })
    this.props.logoutUser( this.props.login, this.props.history);
    //redireciconar a la pagina de logout en otra pagina
    window.location.href = `${process.env.REACT_APP_HREF_URL}/web/logout`

  }

  render() {
    //console.log('for logouts',this.props);
    const {user} = this.props
    let avatar = user4
    if (user && !user.isCollaborator){
      avatar = user.image_app_get
    }

    return (
      <React.Fragment>
        <Dropdown
          isOpen={this.state.menu}
          toggle={this.toggle}
          className="d-inline-block"
        >
          <DropdownToggle
            className="btn header-item waves-effect"
            id="page-header-user-dropdown"
            tag="button"
          >
            {!this.props.cargando ? (
              <span style={{ paddingRight: "15px" }}>
                {
                  this.props.user ?
                  `${this.props.user.name}`                      
                    : ''
                }
              </span>
            ) : (
              ""
            )}

            <img
              className="rounded-circle header-profile-user"
              src={avatar}
              alt="Header Avatar"
            />
          </DropdownToggle>
          <DropdownMenu right>
            {/* <DropdownItem tag="a" href="#">
              <i className="mdi mdi-account-circle font-size-17 align-middle mr-1"></i>
              Profile
            </DropdownItem>
            <DropdownItem tag="a" href="#">
              <i className="mdi mdi-wallet font-size-17 align-middle mr-1"></i>
              My Wallet
            </DropdownItem>
            <DropdownItem tag="a" href="#">
              <span className="badge badge-success float-right">11</span>
              <i className="mdi mdi-settings font-size-17 align-middle mr-1"></i>
              Settings
            </DropdownItem>
            <DropdownItem tag="a" href="#">
              <i className="mdi mdi-lock-open-outline font-size-17 align-middle mr-1"></i>
              Lock screen
            </DropdownItem> */}
            <div className="dropdown-divider"></div>
            <button onClick={() => this.handleLogouts()} className="dropdown-item">
              <i className="mdi mdi-logout font-size-17 align-middle mr-1"></i>
              <span>Logout</span>
            </button>
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    ...state.User,
    login: state.Login,
    history: state.history,
  };
};

export default connect(mapStatetoProps, { logoutUser })(ProfileMenu);
