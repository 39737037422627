import React from "react";
import { Link } from "react-router-dom";

export default function qashopmainnav(props) {
  const baseURL= props.url;
  return (
    <li>
      <Link to="/#" className="has-arrow waves-effect">
        <i>
        <span className="iconify" data-icon="ant-design:share-alt-outlined" data-inline="false"></span>
        </i>
        <span>Marketing</span>
      </Link>
      <ul className="sub-menu" aria-expanded="false">
        <li>
          <a href="{% url 'cms_campaigns' %}">
          Campañas redes sociales
          </a>
        </li>
      </ul>
      <li>
        <a href={baseURL+"/marketing/dashboard"}>
        Estadísticas
        </a>
      </li>
      <li>
        <a href={baseURL+"/marketing/cms/wa-domains"}>
        Dominios
        </a>
      </li>
      <li>
        <a href={baseURL+"/marketing/cms/wa-contacts"}>
        Contactos
        </a>
      </li>
      <li>
        <a href={baseURL+"/marketing/cms/wa-landings"}>
        Links MiWhats
        </a>
      </li>
      <li>
        <a href={baseURL+"/web/cms/messages/"}>
        Solicitudes
        </a>
      </li>
    </li>
  );
}
