import React from 'react'

import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import { Container, Jumbotron, Row, Col, Button } from 'reactstrap';
import { Image } from 'react-bootstrap';
import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import accounting from 'accounting'
import ShopPageCheckout from './ShopPageCheckout';
import ShopCart from '../shop/ShopCart';


class PlanDetails extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            client: null,
            loading: true,
            selectedPlan: null,
            isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
        }
    }

    componentDidMount() {
        // console.log('Plans', this.props)
    }

    render() {
        const {plan, distributorSiteId, sellerSiteId} = this.props.location.state;
        const {master} = this.props;
        const {isMobile} = this.state;
        const logo = master[0].logo;
        const brand = master[0].name;
        
        return (
            <div className="wrapper-page page-full" 
                style={{backgroundColor: '#e9ecef', minHeight:"100vh"}}>
                    <Row noGutters className='pl-1 pr-1'
                        style={{alignItems: 'center', backgroundColor: '#fff' }}>
                        <Col
                            md={{ size: 1, order: 1 }}
                            xs={{ size: 1, order: 1 }}
                            className='mb-1 mt-2'
                            style={{ display: 'flex', alignItems: 'flex-end', justifyContent: isMobile ? 'center' : 'flex-start' }}>
                                <Link 
                                    to={{
                                        pathname:`/plans/${distributorSiteId}/${sellerSiteId}`,
                                    }} 
                                    >
                                    <span className="iconify" data-icon="eva:arrow-ios-back-fill" data-inline="false"></span>
                                </Link>
                        </Col>
                        <Col
                            md={{ size: 8, order: 2 }}
                            xs={{ size: 8, order: 2 }}
                            className='mb-1 mt-2 pl-1'
                            style={{ display: 'flex', fontSize: '1.5rem', fontWeight: '300', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                            <span>Planes</span>
                        </Col>
                        <Col
                            md={{ size: 2, order: 3 }}
                            xs={{ size: 2, order: 3 }}
                            className='mb-1 mt-2'
                            style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                            {
                                logo ?
                                    <Image
                                        alt="brand"
                                        src={logo}
                                        style={{ height: 30, width: 'auto' }}
                                        className="d-inline-block align-top"
                                    />
                                    : brand ?
                                        <h2>{brand.toUpperCase()}</h2>
                                        : null
                            }
                        </Col>
                    </Row>
                    <Row noGutters>
                        <Col  
                            md={{ size: 12, order: 4 }}
                            xs={{ size: 12, order: 4 }}
                            style={{ height: 10, backgroundColor: '#FA2441'}}>
                        </Col>
                    </Row>
                    <ToastContainer autoClose={5000} hideProgressBar />
                    {
                        this.renderModalCheckout()
                    }
                    <Container>
                        <Row>
                            <Col>
                                <div style={{marginTop:20}}>
                                    <h2 style={styles.title}>{plan.name}</h2>
                                </div>
                                <Jumbotron style={styles.jumbo}>
                                    <div dangerouslySetInnerHTML={{
                                        __html:plan.description ? plan.description.replaceAll('\n', '<br />'): ''}}/>
                                </Jumbotron>
                                <div style={{marginTop:20}}>
                                    <h5 style={styles.price}>${plan.price_get} al mes</h5>
                                </div>
                                <div style={{marginTop:50, marginBottom:50}}
                                    className="d-flex justify-content-center">
                                    <Button color="link" 
                                        style={{backgroundColor:'#FA2441', color:'white', fontWeight: 'bold', padding:"20px 50px"}}
                                        onClick={() => this.onBuyPress()}
                                        >
                                        Comprar ahora
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Container>
            </div>
        )
    }

    renderModalCheckout() {
        const {
            showModalCheckout, 
            selectedPlan,
            client,
            loading,
            distributorSiteId,
            sellerSiteId
        } = this.state

        if (showModalCheckout && selectedPlan && client) {            

            let shopCart = new ShopCart([
                {
                    product: selectedPlan,
                    quantity: 1,
                    price: selectedPlan.price_get,
                    total: selectedPlan.price_get
                }
            ])
            return (
                <Modal isOpen={showModalCheckout}  centered size="xl">
                    <ModalHeader toggle={() => this.setState({showModalCheckout: false})}>Realizar pago</ModalHeader>
                    <ShopPageCheckout 
                        cart={shopCart} 
                        user={client}
                        distributorSiteId={distributorSiteId}
                        sellerSiteId={sellerSiteId}
                        />
                </Modal>
            )
        } else {
            if (!client && !loading) {
                return (
                    <div>
                        <span>Este usuario no tiene cliente para hacer la compra.</span>
                    </div>
                )
            }
        }
    }

    onBuyPress() {
        // console.log('state', this.state)
        const {plan, client, distributorSiteId, sellerSiteId} = this.props.location.state
        if (plan) {
            this.setState({
                client,
                distributorSiteId,
                sellerSiteId,
                selectedPlan: plan,
                showModalCheckout: true,
            })
        } else {
            toast.info('Elije un plan')
        }
    }
}

const mapStateToProps = (state) => ({
    master: state.User.master,
    login: state.Login
});

export default connect(mapStateToProps, null)(PlanDetails);


const styles = {
    planContainer: {
        marginBottom:40,
    },
    box: {
        backgroundColor: 'white',         
        borderRadius:10,
        padding:10
    },
    title: {
        color: '#000',
        textAlign:'left'
    },
    price: {
        color: '#000',
        textAlign:'center'
    },
    jumbo: {
        backgroundColor: 'white',
    }
}