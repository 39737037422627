import moment from "moment"

export function isValidDate(expires_at) {        
    const now = moment()
    const expiraAt = moment(expires_at)
    let diff = expiraAt.diff(now, "hours", true)
    const hours = Math.floor(diff)        
    if (hours >=0) {
        return true
    } else {
        return false
    }        
}


export function isMobile() {
    return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
}

export function isTrue(value){
    if (typeof(value) === 'string'){
        value = value.trim().toLowerCase();
    }
    switch(value){
        case true:
        case "true":
        case 1:
        case "1":
        case "on":
        case "yes":
            return true;
        default: 
            return false;
    }
}


export function renderOnlyXCharacters(text, limit=40) {
    if (text){
        if (text.length > limit)
            return text.substr(0, limit) + '...'
        return text
    }
    return ''
}

export function logInDebug(message, ...optionalParameters) {
    if (process.env.NODE_ENV === 'development'){
        console.log(message, ...optionalParameters)
    }
}