import React, { Component } from "react";
//import Taskboard from "./Taskboard";
import Taskboard from "../../components/Kanban/Taskboard";
import {
  changeLayoutWidth,
  changeSidebarType,
} from "../../store/layout/actions";
import { connect } from "react-redux";
import { Row, Col, Alert } from "reactstrap";
import { Link } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";

import { getProviders, getBoards, updateProviderStatus } from "../../api/api";

class index extends Component {

  constructor(props) {
    super(props)
    this.state = {
      columns:[],
      processing: false
    }
  }

  componentDidMount() {
    this.props.changeLayoutWidth("boxed");
    this.props.changeSidebarType("icon", false);
    this.fetchBoard()
  }

  fetchBoard() {
    const {login} = this.props
    if (login && login.key) {     
      this.setState({processing: true}) 
      getBoards(login).then(result => {
        if (result.status == 200) {
          const board = result.data.results.find(it => it.slug === "tablero-de-proveedores")          
          if (board){
            this.setState({
              board
            }, () => this.fetchProviders(board))
          } else {
            this.setState({processing: false})
          }
        } else {          
          const error = result.status >= 500 ? 'Error en el servidor':
              result.status >= 400 ? (result.data && result.data.detail ? result.data.detail : JSON.stringify(result.data))
                : result.problem
          this.setState({
            processing: false,
            error: `Ocurrio un problema: ${error}`
          })          
        }
      }).catch(error => console.warn('Error', error))

    }
  }

  fetchProviders(board) {
    const {login} = this.props
    if (login && login.key) {
      this.setState({processing: true})
      getProviders(login).then(result => {
        if (result.status == 200) {
          this.fillBoard( board, result.data)
        }
        this.setState({processing: false})
      }).catch(error => console.warn('Error', error))
    }
  }

  fillBoard( board, providers) {
    //console.log('fillBoard( board, result.data)', board, providers)
    const columnsMap = new Map()    
    for (let i = 0; i < board.stages.length; i++) {
      const stage = board.stages[i];      
      const columnObject = {...stage, tasks:[]}
      columnsMap.set(stage.slug, columnObject)
    }
    
    for (let i = 0; i < providers.length; i++) {
      const provider = providers[i];
      const columnProvider = columnsMap.get(provider.status.slug)
      if (columnProvider) {
        columnProvider.tasks.push(this.createCard(provider))
        }      
    }

    const columns = []
    columnsMap.forEach((value, key) => {      
      columns.push(value)
    })
    this.setState({
      columns
    })
  }

  createCard(provider) {
    return ({            
      title: (
        <Grid container>
          <Grid item xs={10}> 
            <span>{provider.name}</span>                   
          </Grid>
          <Grid item xs={2}>
            <Avatar>{provider.name && provider.name.length > 0 ? provider.name.substr(0,1).toUpperCase() : '?'}</Avatar>
          </Grid>
        </Grid>
      ),
      ...provider
    })    
  }
    
  onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const {login} = this.props
    const source = result.source;
    const destination = result.destination;
    var providerId = result.draggableId;
    if (source && destination && providerId) {      
      this.setState({processing: true})
      updateProviderStatus(providerId, destination.droppableId, login).then(result =>{
        if (result.status ==200) {
          this.updateProviderInColumns(result.data)
        }
        this.setState({processing: false})
      }).catch(error => console.warn('Error', error))
    }
  }

  updateProviderInColumns(provider) {
    const {columns} = this.state
    for (let i = 0; i < columns.length; i++) {
      const column = columns[i];
      const tasks = column.tasks.filter(it => it.id !== provider.id)
      if (column.slug === provider.status.slug) {
        tasks.push(this.createCard(provider))
      }
      column.tasks = tasks      
    }
    this.setState({columns})
  }

  render() {
    return (
      <div>
        <Row className="align-items-center">
          <Col sm={6}>
            <div class="page-title-box">
              <h4 className="font-size-18">Proveedores</h4>
              <ol className="breadcrumb mb-0">                
                <li className="breadcrumb-item">
                  <Link to="#">Kanban</Link>
                </li>
                <li className="breadcrumb-item active">
                  Proveedores
                </li>
              </ol>
            </div>
          </Col>

          <Col sm="6">
            <div className="float-right d-none d-md-block">
              {/* <SettingMenu /> */}
            </div>
          </Col>
        </Row>
        {this.state.error ? (
            <Alert color="danger">{this.state.error}</Alert>
          ) : null}
        {
          this.state.columns && 
            (<Taskboard columns={this.state.columns} 
                onDragEnd={this.onDragEnd} 
                processing={this.state.processing}
                readOnly={this.state.board ? this.state.board.workflow.read_only: true}
                type={"provider"} />)
        }        
      </div>
    );
  }
}

const mapStatetoProps = (state) => {
  return { 
    login: state.Login,
    ...state.User
  };
};

const mapDispatchToProps = {  
  changeLayoutWidth,
  changeSidebarType,
};

export default connect(mapStatetoProps, mapDispatchToProps)(index);
