import React, { Component } from 'react'

import { Row, Col, Card, CardBody, CardFooter, FormGroup, Button, Label } from 'reactstrap';
import { AvForm, AvField } from "availity-reactstrap-validation";
import { searchProducts, sanitizeImageURL } from '../../../api/api';

// Editable
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";
import { Link } from 'react-router-dom';
import accounting from 'accounting'
import Order from '../objects/Order';
import Paginator from '../../../components/Paginator';
import SweetAlert from "react-bootstrap-sweetalert";

export default class ProductsSelecter extends Component {

    columns = [    
        {
          dataField: "name",
          text: "Producto",
          editable: false
        },
        {
          dataField: "sku",
          text: "SKU",
          editable: false
        },
        {
          dataField: "quantity",
          text: "Cantidad"
        },
        {
          dataField: "price_rules.price",
          text: "Precio",
          editable: false,
          formatter: (cell, row, rowIndex, formatExtraData) => {          
              return accounting.formatMoney(row.price_rules.price)
          }
        },
        {
            dataField: "delete",
            text: "",
            isDummyField: true,
            editable: false,
            formatter: (cell, row, rowIndex, formatExtraData) =>   
                <i className="ion ion-md-remove-circle" style={{color:'red', fontSize:22}} onClick={() => this.onDeleteRow(row)}></i>                 
        }
      ]

    constructor(props) {
        super(props)
        this.state = {   
            query:'',            
            selectedProducts: [],
            searching: false,

            pages: [],
            currentPage: 0
        }
    }

    componentDidMount() {
        const {login} = this.props
        searchProducts(null, '', login, null, true).then(result => {
            if (result.status == 200) {
                let products = result.data.count                
                if (products == 0) {
                    this.setState({showAlert: true})
                } else {
                    const {pages, currentPage} = this.state
                    pages[currentPage] = result.data
                    this.setState({
                        pages
                    })
                }
            }            
        }).catch(error => console.error('Error', error))
    }

    render() {   
        const {query, pages, currentPage, selectedProducts, searching} = this.state     
        const order = new Order(selectedProducts)
        let products = []
        let totalResults = 0
        let showPagination = false
        if (pages[currentPage]) {
            products = pages[currentPage].results
            totalResults = pages[currentPage].count
            showPagination = true
        }
        return (
            <React.Fragment>
                {this.state.showAlert ? (
                    <SweetAlert
                      title={
                        <span>
                          No tiene productos
                        </span>
                      }
                      warning
                      onConfirm={() => this.setState({ showAlert: false })}
                    >
                      Para generar pedidos debe tener productos en su Catálogo
                    </SweetAlert>
                  ) : null}

                <Col lg={6}>
                    <Card>
                        <CardBody> 
                            <div>
                                <Label>Buscar Productos:</Label>                       
                                <input
                                    className="form-control"
                                    type="search"                                
                                    placeholder= 'Buscar Productos'
                                    id="search-input"
                                    value={query}
                                    onChange={e => this.onSearchSubmit(e)}                                
                                />      
                            </div> 
                            <div className="row" style={{marginTop:'8px'}}>
                                {
                                    searching ? 
                                        <div style={{display:'flex', flex:1, justifyContent:'center'}}>
                                            <div className="spinner-grow text-info m-1" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        </div>
                                        : null
                                }
                                {products.map(product => (
                                <Col key={product.id} xl={3} md={6}>
                                    <Link
                                    to="#"
                                    className="gallery-popup"
                                    title="Seleccionar"
                                    onClick={() =>
                                        this.addToSelectedProducts(product)
                                    }
                                    >
                                    <div className="project-item">
                                        <div className="overlay-container">
                                        <img
                                            src={sanitizeImageURL(product.image_app_get)}
                                            alt="img"
                                            className="gallery-thumb-img"
                                        />
                                        <div className="project-item-overlay">
                                            <h6 style={{overflowWrap:'break-word'}}>{product.name}</h6>
                                            <p>
                                            <img
                                                src={sanitizeImageURL(product.image_app_get)}
                                                alt="user"
                                                className="avatar-sm rounded-circle"
                                            />
                                            <span className="ml-2">{accounting.formatMoney(product.price_rules.price)}</span>
                                            </p>
                                        </div>
                                        </div>
                                    </div>
                                    </Link>
                                </Col>
                                ))}
                            </div>                                            
                        </CardBody>
                        <CardFooter>
                            <Row>
                                {
                                    query && 
                                    (<Label>{`Resultados de "${query}": ${totalResults}`}</Label>)
                                }
                                {
                                    showPagination &&
                                    (<Col>
                                        <div style={{display:'flex', justifyContent:'flex-end'}}>
                                            <Paginator 
                                                onPreviusClick = {() => this.onPreviusClick()}
                                                onNextClick = {() => this.onNextClick()}
                                                onPageClick = {(pageSelected) => this.onPageClick(pageSelected)}
                                                currentPage = {this.state.currentPage + 1}
                                                totalPages = {this.state.pages.length}
                                                />                                        
                                        </div>
                                    </Col>)
                                }
                            </Row>
                        </CardFooter>
                    </Card>
                </Col>
                <Col lg={6}>
                    <Card>
                        <CardBody>    
                        <div style={{marginTop:20}}>
                                <Label>Mi Compra</Label>
                                <BootstrapTable
                                    keyField="id"
                                    data={selectedProducts}
                                    columns={this.columns}
                                    striped
                                    wrapperClasses="table-responsive" // This is the style provided by bootstrap 4, this will set the parent div with that class
                                    cellEdit={
                                        cellEditFactory({ 
                                            mode: "click" ,
                                            autoSelectText: true,
                                            afterSaveCell: () => this.onUpdateSelectedProducts(),
                                            beforeSaveCell(oldValue, newValue, row, column, done) {
                                                setTimeout(() => {
                                                    let digitRegExp = /^[0-9]+$/
                                                    if (digitRegExp.test(newValue)){
                                                        done(); // contine to save the changes
                                                    } else {
                                                    done(false); // reject the changes
                                                  }
                                                }, 0);
                                                return { async: true };
                                              }
                                        })
                                    }
                                    onDeleteRow={() => console.log('onDeleteRow')}
                                />
                            </div>

                            <div className="table-responsive">
                                <table className="table mb-0">                                
                                <tbody>
                                    <tr className="thead-light" style={{textAlign:'right'}}>
                                        <th scope="row">Subtotal:</th>                                                
                                        <th scope="row">{accounting.formatMoney(order.getSubTotal())}</th>  
                                    </tr>                                       
                                    <tr className="thead-light" style={{textAlign:'right'}}>
                                        <th scope="row">IVA:</th>                                                
                                        <th scope="row">{accounting.formatMoney(order.getIVA())}</th>  
                                    </tr>                                       
                                    <tr className="thead-light" style={{textAlign:'right'}}>
                                        <th scope="row"><h2>Total:</h2></th>                                                
                                        <th scope="row"><h2>{accounting.formatMoney(order.getTotal())}</h2></th>  
                                    </tr>                                       
                                </tbody>
                                </table>
                            </div>                                                 
                        </CardBody>                        
                    </Card>
                </Col>
                
            </React.Fragment>
        )
    }


    onSearchSubmit(e) {        
        console.log('onSearchSubmit', this.props)
        const query = e.target.value
        this.setState({query})
        if (this.searchTimeout && !this.searching) {
            clearTimeout(this.searchTimeout)
        }
        this.searchTimeout = setTimeout(() => {
            this.fetchProducts(0, null, query)           
        }, 1000);
    }
   

    fetchProducts(currentPage, nextUrl, query) {
        const {client, login} = this.props
        this.setState({searching: true})            
        searchProducts(nextUrl, query, login, client ? client.id: null).then(result => {
            const {pages} = this.state
            pages[currentPage] = result.data
            this.setState({
                pages
            })
            this.setState({searching: false})
        }).catch(error => console.error('Error', error))
    }

    addToSelectedProducts(product) {
        const {selectedProducts} = this.state
        const existProduct = selectedProducts.find(it => it.id == product.id)
        if (!existProduct) {
            let selected = {
                ...product,
                quantity: 1
            }
            selectedProducts.push(selected)
            this.setState({selectedProducts}, () => this.fireProductsSelectedChange())
        }
    }

    onUpdateSelectedProducts() {
        const {selectedProducts} = this.state        
        this.forceUpdate()
        this.fireProductsSelectedChange()
    }

    onDeleteRow(row) {        
        const {selectedProducts} = this.state
        if (row && row.id) {
            const result = selectedProducts.filter(it => it.id != row.id)
            this.setState({
                selectedProducts: result
            },  () => this.fireProductsSelectedChange())
        }
    }

    fireProductsSelectedChange() {
        const {productsSelectedChange} = this.props
        const {selectedProducts} = this.state
        if (productsSelectedChange) {
            productsSelectedChange(selectedProducts)
        }
    }


    onNextClick() {
        const { pages, currentPage } = this.state
        const nextPage = pages[currentPage + 1]        
        if (nextPage) {
            this.setState({
                currentPage: currentPage + 1
            })
        } else {
            const page = pages[currentPage]        
            if (page && page.next) {            
                this.setState({
                    currentPage: currentPage + 1
                }, this.fetchProducts(currentPage + 1, page.next))
            }
        }
    }

    onPreviusClick() {
        const { pages, currentPage } = this.state
        if (currentPage > 0) {
            this.setState({
                currentPage: currentPage - 1
            })            
        }
    }

    onPageClick(pageSelected) {
        const { pages } = this.state        
        if (pages[pageSelected]) {
            this.setState({
                currentPage: pageSelected
            })            
        }
    }

}