import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  InputGroup,
  Label,
  Input,
  Button
} from "reactstrap";
import Select from "react-select";
import user2 from "../../assets/images/users/user-2.jpg";
import { Link } from "react-router-dom";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import { connect } from "react-redux";
import SettingMenu from "../Shared/SettingMenu";

const LoadingContainer = props => <div>Loading...</div>;

const optionGroup = [
  {
    label: "Picnic",
    options: [
      { label: "Mustard", value: "Mustard" },
      { label: "Ketchup", value: "Ketchup" },
      { label: "Relish", value: "Relish" }
    ]
  }
];

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedGroup: null,
      selectedMulti: null,
      selectedPlace: {}
    };
  }

  render() {
    const { selectedGroup } = this.state;
    const { selectedMulti } = this.state;
    return (
      <React.Fragment>
        <div className="container-fluid">

        <Row className="align-items-center">
            <Col sm={6}>
              <div class="page-title-box">
                <h4 className="font-size-18">Crear Nueva Ruta</h4>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="#">invitaShopping</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#">Ruta</Link>
                  </li>
                  <li className="breadcrumb-item active">Nueva Ruta</li>
                </ol>
              </div>
            </Col>

            <Col sm="6">
              <div className="float-right d-none d-md-block">
                {/* <SettingMenu /> */}
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={8}>
              <Card>
                <CardBody>
                  <h4 className="card-title">Seleccionar Bodega</h4>
                  <p className="card-title-desc">
                    A mobile and touch friendly input spinner component for
                    Bootstrap
                  </p>
                  <Form>
                    <FormGroup>
                      <Label>Bodega</Label>
                      <Select
                        value={selectedGroup}
                        onChange={this.handleSelectGroup}
                        options={optionGroup}
                      />
                    </FormGroup>
                    <FormGroup className="mb-0">
                      <Label>Chofer</Label>
                      <Select
                        value={selectedMulti}
                        isMulti={true}
                        onChange={this.handleMulti}
                        options={optionGroup}
                      />
                    </FormGroup>
                  </Form>{" "}
                </CardBody>
              </Card>
            </Col>
            <Col lg={4}>
              <Card className="directory-card">
                <CardBody>
                  <div className="media">
                    <img
                      src={user2}
                      alt=""
                      className="img-fluid img-thumbnail rounded-circle avatar-lg"
                    />
                    <div className="media-body ml-3">
                      <h5 className="text-primary font-size-18 mt-0 mb-1">
                        Jerome A. Hebert
                      </h5>
                      <p className="font-size-12 mb-2">Chofer</p>
                      <p className="mb-0">Jerome@veltrix.com</p>
                    </div>
                    <ul className="list-unstyled social-links float-right">
                      <li>
                        <Link to="#" className="btn-primary">
                          <i className="mdi mdi-facebook"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="btn-info">
                          <i className="mdi mdi-twitter"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <hr />
                  <p className="mb-0">
                    <b>Intro : </b>At vero eos et accusamus et iusto odio
                    dignissimos ducimus qui blanditiis atque corrupti quos
                    dolores.{" "}
       
                  </p>
                </CardBody>
              </Card>
            </Col>
          </Row>
       

        <Row>
        <Col lg={6}>
          <Card>
            <CardBody>
              <h4 className="card-title">Mapa</h4>
              <p className="card-title-desc">Example of google maps.</p>
              <div
                id="gmaps-markers"
                className="gmaps"
                style={{ position: "relative" }}
              >
                <Map
                  google={this.props.google}
                  style={{ width: "100%", height: "100%" }}
                  zoom={14}
                >
                  <Marker
                    title={"The marker`s title will appear as a tooltip."}
                    name={"SOMA"}
                    position={{ lat: 37.778519, lng: -122.40564 }}
                  />
                  <Marker name={"Dolores park"} />
                  <InfoWindow>
                    <div>
                      <h1>{this.state.selectedPlace.name}</h1>
                    </div>
                  </InfoWindow>
                </Map>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col xl={4}>
          <Card>
            <CardBody>
              <h4 className="card-title mb-4">Ruta</h4>
              <ol className="activity-feed">
                <li className="feed-item">
                  <div className="feed-item-list">
                    <span className="date">Jan 22</span>
                    <span className="activity-text">
                      Responded to need “Volunteer Activities”
                    </span>
                  </div>
                </li>
                <li className="feed-item">
                  <div className="feed-item-list">
                    <span className="date">Jan 20</span>
                    <span className="activity-text">
                      At vero eos et accusamus et iusto odio dignissimos ducimus
                      qui deleniti atque...
                      <Link to="#" className="text-success">
                        Read more
                      </Link>
                    </span>
                  </div>
                </li>
                <li className="feed-item">
                  <div className="feed-item-list">
                    <span className="date">Jan 19</span>
                    <span className="activity-text">
                      Joined the group “Boardsmanship Forum”
                    </span>
                  </div>
                </li>
                <li className="feed-item">
                  <div className="feed-item-list">
                    <span className="date">Jan 17</span>
                    <span className="activity-text">
                      Responded to need “In-Kind Opportunity”
                    </span>
                  </div>
                </li>
                <li className="feed-item">
                  <div className="feed-item-list">
                    <span className="date">Jan 16</span>
                    <span className="activity-text">
                      Sed ut perspiciatis unde omnis iste natus error sit rem.
                    </span>
                  </div>
                </li>
              </ol>
         {/*      <div className="text-center">
                <Link to="#" className="btn btn-primary">
                  Load More
                </Link>
              </div> */}
            </CardBody>
          </Card>
        </Col>
        </Row>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = state =>{
  return {...state.User}
}

export default connect(
  mapStatetoProps,
  {}
)(
  GoogleApiWrapper({
    apiKey: "AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE",
    LoadingContainer: LoadingContainer,
    v: "3"
  })(index)
);
