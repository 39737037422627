import React from "react";
import { Link } from "react-router-dom";

export default function qafinancemainnav(props) {
  const baseURL= props.url;
  return (
    <li>
      <Link to="/#" className="has-arrow waves-effect">
        <i ><span className="iconify" data-icon="ant-design:dollar-circle-outlined" data-inline="false"></span></i>
        <span>Finance</span>
      </Link>
      <ul className="sub-menu" aria-expanded="false">
        <li>
          <a href={baseURL+"/finance/dashboard/"}>
            Dashboard
          </a>
        </li>
        <li>
          <a href={baseURL+"/finance/transactions/finance/"}>
            Balances
          </a>
        </li>
        <li>
          <a href={baseURL+"/finance/cms/transactions/all/0/"}>
            Transacciones
          </a>
        </li>
        <li>
          <a href={baseURL+"/finance/cms/accounts/"}>
            Mis Cuentas
          </a>
        </li>
        <li>
          <a>Fiscal</a>
          <ul className="sub-menu" aria-expanded="false">
            <li>
              <a href={baseURL+"/finance/invoice-SAT/"}>
                Facturas
              </a>
            </li>
            <li>
              <a href={baseURL+"/finance/xml-upload/"}>
                Subir XML
              </a>
            </li>
          </ul>
        </li>
        <li>
          <a href="#">
          Configuración
          </a>
          <ul className="sub-menu" aria-expanded="false">
            <li>
              <a href={baseURL+"/finance/cms/categories/"}>
              Categorías
              </a>
            </li>
            <li>
              <a href={baseURL+"/finance/cms/tags-fin/"}>
              Etiquetas
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </li>
  );
}
