export default class ShopCart {

    extraLines= [];
    quantity= 0;
    subtotal= 0;
    total= 0;
    items= [];
    
    constructor(items) {
        if (items) {
            this.items = items
            this.syncCart()
        }
    }

    syncCart() {
        this.quantity = this.items.reduce((quantity, item) => quantity + item.quantity, 0);
        this.subtotal = this.items.reduce((subtotal, item) => subtotal + item.total, 0)
        this.total = this.subtotal + this.extraLines.reduce((suma, extraline) => suma + extraline.price, 0)
    }

    addExtraLine(extraline) {
        let foundExtraline = this.extraLines.findIndex(it => it.type === extraline.type)
        if (foundExtraline >= 0) {
            this.extraLines[foundExtraline] = extraline
        } else {
            this.extraLines.push(extraline)
        }
        this.syncCart()
    }

}