import React from "react";
import { Link } from "react-router-dom";

export default function qawebmainnav(props) {
  const baseURL= props.url;
  return (
    <li>
      <Link to="/#" className="has-arrow waves-effect">
        <i className="icon ion-ios-cloud" style={{backgroundColor:'transparent'}}></i>
        <span>Web</span>
      </Link>
    
      <ul className="sub-menu" aria-expanded="false">
        <li>
          <a href={baseURL+"/web/cms/"}>
          Contenidos
          </a>
        </li>
      </ul>

    
    </li>
  );
}