import React, { useState, useEffect, useLayoutEffect } from 'react';

import { Row } from 'reactstrap';

import SelectSearchList from './selectSearchList';

import { getVariantAttrsByParam, postCategoryVariantsAttr, deleteRelCatVariantsAttr } from '../../../api/api';


const delCatVarAttr = async ({ categoryId, variantAttrId, reply, loginDetails }) => new Promise(async(resolve, reject) => {
  // console.log({ categoryId, variantAttrId, reply, loginDetails });
  if (!loginDetails || !categoryId || !variantAttrId) reject(false)
  await deleteRelCatVariantsAttr({
    categoryId, variantAttrId, reply, loginDetails
  }).then((res) => {
    if (res.status >= 400) reject(res)
    resolve(res)
  }).catch((err) => {
    reject(err)
  });
})

const searchByParam = async (term, categoryId, scope) => new Promise(async (resolve, reject) => {
  await getVariantAttrsByParam({ term, categoryId, scope }).then((res) => {
    let arr = []
    if (res.data.results && Array.isArray(res.data.results)) {
      res.data.results.map(x => {
        let aux = x.variant
        delete x.variant
        arr.push({
          variant: aux.name,
          variant_id: aux.id,
          name: x.value + ' - ' + aux.name,
          category_id: categoryId,
          variant_attribute_id: x.id,
          ...x
        })
      })
    }
    resolve({data: arr})
  }).catch((err) => {
    resolve(err)
  });
})

const saveAttrs = async (
  categoryId = 123,
  variantAttrIds = {},
  loginDetails = {},
  reply = true,
  asArr = [],
  setter = (e) => console.log(e)
) => new Promise(async (resolve, reject) => {
  if (!categoryId && !variantAttrIds && !loginDetails || !asArr) reject(false)
  let a = []
  if (variantAttrIds && variantAttrIds.length > 0) variantAttrIds.map((x, i) => {
    if (!asArr.find(y => y.variant_attribute_id === x.variant_attribute_id)) a.push({
      ...x
    })
  })
  // console.log(a);
  if (loginDetails && a && a.length > 0) await postCategoryVariantsAttr({
    variantAttrIds: a, loginDetails
  }).then((res) => {
    if (res.status >= 400) reject(res)
    let arr = []
    if (res.data && Array.isArray(res.data)) {
      res.data.map(x => {
        let aux = x.variant_attribute.variant
        delete x.variant_attribute.variant
        arr.push({
          variant: aux.name,
          variant_id: aux.id,
          relation: x.id,
          name: `${x.variant_attribute.value} - ${aux.name}`,
          category_id: categoryId,
          variant_attribute_id: x.variant_attribute.id,
          ...x.variant_attribute
        })
      })
    } else if (res.data && typeof res.data == 'object') arr = [{...res.data}]
    // console.log(arr);
    resolve(arr)
  }).catch((err) => {
    reject(err)
  });
  else reject()
})

export default function Attr({
  loginDetails,
  varAttrs = [],
  catAttrs = [],
  variant = {},
  catID
}) {
  const [ attrs, setattrs ] = useState([])
  const [ asCatAttrs, setasCatAttrs ] = useState(catAttrs)
  const [ selattrs, setselattrs ] = useState([])
  const [ deselattrs, setdeselattrs ] = useState([])
  const [ reply, setReply ] = useState(true)

  // console.log(attrs, selattrs, deselattrs, catAttrs, asCatAttrs);

  useLayoutEffect(() => {
    if (variant && variant.id && attrs.length === 0) {
      let arr = []
      if (variant && Array.isArray(catAttrs)) {
        catAttrs.map(x => {
          // console.log("corresponding variant: ", variant.slug, ' its: ', x.variant_attribute.variant.slug);
          if (x.variant_attribute.variant.slug === variant.slug) arr.push({
            variant: variant.name,
            variant_id: variant.id,
            relation: x.id,
            name: `${x.variant_attribute.value} - ${variant.name}`,
            category_id: catID,
            variant_attribute_id: x.variant_attribute.id,
            ...x.variant_attribute,
          })
        })
        // console.log(arr);
        setattrs(arr)
      }
    }
  }, [ variant, catAttrs ])

  useEffect(() => {
    if (attrs && attrs.length > 0) {
      let arr = []
      attrs.map(x => {
        arr.push(x)
      })
      setselattrs(arr)
    } else setselattrs([])
  }, [ attrs ])

  useEffect(() => {
    if (attrs && attrs.length > 0 && selattrs && selattrs.length > 0) {
      /* console.log(selattrs); */
      let arr = []
      attrs.map(x => {
        if (!selattrs.find(y => y.variant_attribute_id === x.variant_attribute_id)) arr.push(x)
      })
      setdeselattrs(arr)
    } else setdeselattrs([])
  }, [ selattrs ])

  useEffect(() => {
    if (deselattrs && deselattrs.length > 0 && attrs && attrs.length > 0) {
      let arr = []
      deselattrs.map(async (x) => {
        let target = attrs.find(k => k.variant_attribute_id === x.variant_attribute_id)
        /* console.log(x.relation, target, x.id, x.variant_attribute_id); */
        if (!selattrs.find(y => y.id === x.id) && target) await delCatVarAttr({
          categoryId: catID,
          variantAttrId: target && target.relation ? target.relation : x.relation ? x.relation : x.id,
          reply: true,
          loginDetails,
        }).then((res) => {
          /* console.log(res); */
        }).catch((err) => {
          console.log(err);
        });
      })
    }
  }, [ deselattrs ])

  return (
    <Row>
      <SelectSearchList
        loginDetails={ loginDetails }
        asArr={ attrs }
        setasArr={ setattrs }
        deselArr={ deselattrs }
        selArr={ selattrs }
        setselArr={ setselattrs }
        reply={ reply }
        setReply={ setReply }
        searchFunc={ searchByParam }
        typoLimit={ 1 }
        categoryId={ catID }
        saveFunc={ saveAttrs }
        deleteFunc={ delCatVarAttr }
      />
    </Row>
  )
}