import React from "react";
import { Link } from "react-router-dom";

export default function qacadimainnav(props) {
  const baseURL= props.url;
  return (
    <li>
      <Link to="/#" className="has-arrow waves-effect">
        <i><span className="iconify" data-icon="ant-design:credit-card-outlined" data-inline="false"></span></i>
        <span>Mi Cadi</span>
      </Link>
      <ul className="sub-menu" aria-expanded="false">
        <li>
          <a href={baseURL+"/cadi/dashboard/"}>
            Dashboard
          </a>
        </li>
        <li>
          <a href={baseURL+"/cadi/cms/card-new/"}>
          Agregar Tarjeta
          </a>
        </li>
        <li>
          <a href={baseURL+"/cadi/cms/transactions/"}>
          Interacciones
          </a>
        </li>
        <li>
          <a href={baseURL+"/cadi/cms/user-cards/"}>
          Clientes
          </a>
        </li>
        <li>
          <a href={baseURL+"/cadi/cms/influencers/"}>
          Influencers
          </a>
        </li>
        <li>
          <a href={baseURL+"/shop/cms/products/all/"}>
          Recompensas
          </a>
        </li>
        <li>
          <a href="{% url 'cms_push_notifications_send' %}">
          Notificaciones
          </a>
        </li>
        <li>
          <a href={baseURL+"/cadi/cms/cards/"}>
          Mi Tarjeta
          </a>
        </li>
        <li>
          <a href={baseURL+"/cadi/client/scanner/"}>
          Escanear Tarjetas
          </a>
        </li>
        <li>
          <a href={baseURL+"/cadi/cms/cadipacks/"}>
          CadiPacks
          </a>
        </li>
        <li>
          <a href={baseURL+"/cadi/cms/cadisearch/terms/"}>
          CadiSearch Tems
          </a>
        </li>
        <li>
          <a href={baseURL+"/cadi/cms/assign/cards/"}>
          Asignar Card
          </a>
        </li>
      </ul>
    </li>
  );
}
