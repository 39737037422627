import React, { Component } from "react";
import { Switch, BrowserRouter as Router } from "react-router-dom";
import { connect } from "react-redux";

// Import Routes
import { authProtectedRoutes, publicRoutes } from "./routes/";
import AppRoute from "./routes/route";

// layouts
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./assets/scss/theme.scss";

// Import Firebase Configuration file
import { initFirebaseBackend } from "./helpers/authUtils";
import { getApiGeneral } from "./api/api";
import { setGeneral } from "./store/general";
import { HelmetProvider, Helmet } from "react-helmet-async";

const firebaseConfig = {
  apiKey: "AIzaSyBUctS2mlPY58Y-E1AypSUz7OO0zhlBLC8",
  authDomain: "themesbrand-admin.firebaseapp.com",
  databaseURL: "https://themesbrand-admin.firebaseio.com",
  projectId: "themesbrand-admin",
  storageBucket: "themesbrand-admin.appspot.com",
  messagingSenderId: "427667224207",
  appId: "1:427667224207:web:3b97af80b8b4824619a2fa",
  measurementId: "G-S4LDYNV7FY"
};

// init firebase backend
initFirebaseBackend(firebaseConfig);

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.getLayout = this.getLayout.bind(this);
  }
  /**
   * Returns the layout
   */
  getLayout = () => {
    let layoutCls = VerticalLayout;

    switch (this.props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  };

  componentDidMount() {
    getApiGeneral().then(result => {
      if (result.status === 200) {        
        let general = result.data[0]
        this.props.setGeneral(general)        
      }
    }).catch(error => console.error('error', error))
  }

  render() {
    const Layout = this.getLayout();
    const {general} = this.props
    let title = null
    if (general) {
        title = `${general.page_title}`
    } 

    return (
      <React.Fragment>
        <HelmetProvider>
          {
              title && (<Helmet><title>{title}</title></Helmet>)
          }
          <Router>
            <Switch>
              {publicRoutes.map((route, idx) => (
                <AppRoute
                  path={route.path}
                  layout={NonAuthLayout}
                  component={route.component}
                  key={idx}
                  isAuthProtected={false}
                />
              ))}

              {authProtectedRoutes.map((route, idx) => (
                <AppRoute
                  path={route.path}
                  layout={route.nonLayout ? NonAuthLayout : Layout}
                  component={route.component}
                  key={idx}
                  isAuthProtected={true}
                  loginDetails={this.props.login}
                />
              ))}
            </Switch>
          </Router>
        </HelmetProvider>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
    login: state.Login,
    general: state.General.general
  };
};

export default connect(mapStateToProps, {setGeneral})(App);
