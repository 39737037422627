import React, { useState, useEffect, useLayoutEffect } from 'react';
import { Row, Col, Card, CardBody, CardColumns, CardHeader, CardImg, CardTitle, CardSubtitle, CardFooter } from 'reactstrap';

import { Link, Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';

import SelectSearchList from './selectSearchList';

import { getCategoryProviders, getProviders, getProvidersByParam, postCategoryProviders, deleteRelCatProviders } from '../../../api/api';

function getAndSetProviders(setter) {
  getProviders().then((res) => {
    if (Array.isArray(res.data)) setter(res.data)
    else setter([])
  }).catch((err) => {
    console.log('---------');
    console.log(err);
    console.log('---------');
  });
}

function getASProviders(categoryID, setter) {
  getCategoryProviders(categoryID).then((res) => {
    if (Array.isArray(res.data)) {
      // console.log(res.data);
      let arr = []
      res.data.map(x => {
        if (x.provider && !arr.filter(y => y.id === x.provider.id)[ 0 ]) {
          arr.push({
            ...x.provider, relation: x.id
          })
        }
      })
      setter(arr)
    } else if (!res.data || !res.data[ 0 ]) setter([])
  }).catch((err) => {
    console.log(err);
  });
}

const updateCatProviders = (
  categoryId = '123',
  providerIds = [],
  loginDetails = {},
  reply = true,
  asArr = [],
  setter = (e) => console.log(e)
) => new Promise(async (resolve, reject) => {
  let a = []
  if (providerIds && providerIds.length > 0) providerIds.map((x, i) => {
    if (!asArr.find(y => y.id === x.id)) a.push({ category_id: categoryId, relation_id: x.id, reply_to_parents: x.reply, order: i, public: true })
  })
  console.log(a);
  if (loginDetails && a && a.length > 0) {
    await postCategoryProviders({
      categoryId, providerIds: a, loginDetails, reply
    }).then(resp => {
      if (Array.isArray(resp.data)) {
        // console.log(resp.data);
        let arr = []
        resp.data.map(x => {
          if (x.provider && !arr.filter(y => y.id === x.provider.id)[ 0 ]) {
            arr.push({
              ...x.provider, relation: x.id
            })
          }
        })
        setter([...asArr, ...arr])
      } else if (!resp.data || !resp.data[ 0 ]) setter([])
    }).catch(err => {
      console.log(err);
    })
    resolve()
  }
  else reject()
})

const delRelCatProviders = ({
  categoryId = '',
  providerId = [],
  loginDetails = {},
  reply = true,
  setter = () => console.log('hi'),
}) => new Promise((resolve, reject) => {
  /* let a = []
  if (providerIds && providerIds.length > 0) providerIds.map((x) => {
    a.push(x.id)
  })
  console.log(a, a.join(','));
  console.log(loginDetails); */
  deleteRelCatProviders({
    categoryId, providerId, loginDetails, reply
  }).then((res) => {
    // console.log(res);
    if (!res) reject()
    else resolve()
  }).catch((err) => {
    console.log('---------');
    console.log(err);
    console.log('---------');
    reject()
  });
})

const searchByParam = (name, categoryId) => new Promise(async (resolve) => {
  resolve(await getProvidersByParam({
    term: name, categoryId
  }))
})

export default function CatProviders({ props = {}, bindState = {}}) {
  // console.log(props, bindState);
  const [ categoryID, setcategoryID ] = useState(bindState && bindState.categoryID ? bindState.categoryID : props.match.params && props.match.params.categoryId ? props.match.params.categoryId : undefined)
  const [Providers, setProviders] = useState([])
  const [ASProviders, setASProviders] = useState([])
  const [selProviders, setselProviders] = useState([])
  const [deselProviders, setdeselProviders] = useState([])
  const [ reply, setReply ] = useState(false)

  const loginDetails = props.login

  useLayoutEffect(() => {
    // if (Providers.length == 0) getAndSetProviders(setProviders)
    if (categoryID) getASProviders(categoryID, setASProviders)
  }, [categoryID])

  useEffect(() => {
    setcategoryID(bindState && bindState.categoryID ? bindState.categoryID : props.match.params && props.match.params.categoryId ? props.match.params.categoryId : undefined)
    // console.log(categoryID);
  }, [ props ])

  useEffect(() => {
    setdeselProviders([])
    if (ASProviders && ASProviders.length > 0) {
      let arr = []
      ASProviders.map((x, i) => {
        if (!selProviders.find(z => z.id === x.id)) {
          arr.push(x)
        }
      })
      setdeselProviders(arr)
    }
  }, [ selProviders ])

  useEffect(() => {
    if (ASProviders && ASProviders.length > 0 && selProviders !== ASProviders) {
      let arr = []
      ASProviders.map(x => {
        if (!selProviders.find(z => z.id === x.id)) {
          arr.push(x)
        }
      })
      setselProviders(arr)
    }
  }, [ ASProviders ])

  useEffect(() => {
    if (deselProviders && deselProviders.length > 0 && deselProviders !== ASProviders) deselProviders.map(z => {
      // console.log(z.id);
      if (z.id && !selProviders.find(k => k.id === z.id)) delRelCatProviders({
        categoryId: categoryID,
        providerId: z.relation,
        loginDetails,
        reply,
      }).then((res) => {
        setASProviders([...ASProviders.filter(e => e.id !== z.id)])
      }).catch((err) => {
        console.log(err);
      });
    })
  }, [ deselProviders ])

  // console.log(Providers, ASProviders, selProviders, deselProviders);

  return (
    <div className="container-fluid" id="cat_config_container" >
      <Row >
        <Col sm='12' md='12' lg='12' xl='12' >
          <Card>
            <CardHeader className="d-flex">
              <span className="title mr-auto ml-2">
                Proveedores disponibles
              </span>
            </CardHeader>
            <CardBody>
              <div>
                <span className="d-flex py-2 mb-2">
                  Arrastrar para especificar el orden de aparici&oacute;n
                </span>

                <SelectSearchList
                  loginDetails={ loginDetails }
                  asArr={ ASProviders }
                  setasArr={ setASProviders }
                  deselArr={ deselProviders }
                  selArr={ selProviders }
                  setselArr={ setselProviders }
                  reply={ reply }
                  setReply={ setReply }
                  searchFunc={ searchByParam }
                  categoryId={ categoryID }
                  saveFunc={ updateCatProviders }
                  deleteFunc={ delRelCatProviders }
                  placeholder="Selecciona un proveedor/vendedor" />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}