import React, { Component } from "react";
import { Row, Col, Button, Input, Card, CardBody, Modal, FormGroup, Form, Label, Alert } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Switch from "react-switch";
import Select from "react-select";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Link } from "react-router-dom";
// Custom Scrollbar
import SimpleBar from "simplebar-react";
// Sweet alerts
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';

const optionGroup = [
  {
    label: "Modelos",
    options: [
      { label: "TK03", value: "TK03" }
    ]
  }
];

const otypeOptions = [
  {
    label: "Tipo de Dispositivo",
    options: [
      { label: "Auto", value: "Auto" },
      { label: "Mascota", value: "Mascota" },
      { label: "Persona", value: "Persona" },
      { label: "Otro", value: "Otro" }
    ]
  }
];

class IotDevicesList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      modal_center: false,
      switch3: true,
      switchNew: false,
      selectedGroup: null,
      otypeGroup: null,
      allDevices: [],
      devices: [],
      deviceName: null,
      imei: null,
      model: null,
      sim: null,
      showCreateAlert: false,
      showDisableAlert: false,
      showDeleteAlert: false,
      showDisableAxiosAlert: false,
      showErrCreateAlert: false,
      showAlertDeviseSesion: false,
      showAlertDeviseMaxGGPS: false,
      deviceStatus: 'Activos',
      max_gps: false,
      n_max_gps: 0,
      site_id: 0,
      message_usr: '',
      Edition: false,
      id_d: "",
      message_alert: "",
      title_modal: "Agregar nuevo Dispositivo"
    };
    this.backendNode = process.env.REACT_APP_NODE_BACKEND_URL;
    this.tog_center = this.tog_center.bind(this);
    this.handleShowAlertDelete = this.handleShowAlertDelete.bind(this);
  }

  removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  tog_center() {
    //console.log(this.state.Edition);
    this.setState(prevState => ({
      modal_center: !prevState.modal_center
    }));
    this.removeBodyCss();
  }

  show() {
    this.setState({ visible: true });
  }

  hide() {
    this.setState({ visible: false });
  }

  //Select
  handleSelectGroup = selectedGroup => {
    this.setState({ selectedGroup });
  };

  handleOtypeGroup = otypeGroup => {
    this.setState({ otypeGroup });
  }

  handleChangeImei = event => {
    this.setState({ imei: event.target.value });
  }

  handleChangeSim = event => {
    this.setState({ sim: event.target.value });
  }

  handleChangeName = event => {
    this.setState({ deviceName: event.target.value });
  }

  handleSubmit = event => {
    event.preventDefault();

    console.log(this.props.User.user.id);
    
    var _usr_id = this.props.User.user.id;
    //let DateToday = new Date();
    const last_position_new = {
      imei: Number(this.state.imei),
      name: this.state.deviceName,
      time: "",
      lat: 19.45426676270879,
      long: -99.13530593750001,
      speed: 0,
      batery_status: 0,
      movement: false,
      date: "",
      orientation: "",
      direction: "Norte 0°",
      speedgps: "0 Km/h",
      geoloc_at: new Date(Date.now()),
      orientation_grade: 0
    };


    const data = {
      name: this.state.deviceName,
      imei: Number(this.state.imei),
      sim_card: this.state.sim,
      model: this.state.selectedGroup.value,
      otype: this.state.otypeGroup.value,
      is_active: this.state.switchNew,
      usr_id: _usr_id,
      site_id: this.state.site_id,
      is_deleted: false,
      last_position: last_position_new
    };

    const { name, otype, imei, sim_card, model, is_active, usr_id, site_id, is_deleted, last_position } = data;
    const headers = {
      'Content-Type': 'application/json'
    }
    console.log('data post', data);
    // this.handleValidateImei(imei);

    if (this.state.Edition == true) {

      console.log('EDITAR');
      this.setState({ Edition: false });
      axios.put(
        `http://${this.backendNode}/api/devices/update/${this.state.id_d}`,
        { name, otype, imei, sim_card, model, is_active, usr_id, site_id, is_deleted },
        { headers }
      )
        .then(res => {
          this.setState({ message_alert: "El dispositivo se ha actualizado exitosamente." })
          this.setState({ showCreateAlert: true })
          console.log(res);
          console.log(res.data);
          this.componentDidMount()
        }).catch(err => {
          console.log('error al enviar data', err);
          this.setState({ message_alert: "No pudimos actualizar el dispositivo, intenta nuevamente." });
          this.setState({ showErrCreateAlert: true })
        });
    } else {
      console.log('Agregar');
      this.setState({ Edition: false });
      axios.get(`http://${this.backendNode}/api/devices/test/findone/${imei}`)
        .then(res => {
          if (res.data.test.length >= 1) {
            console.log(res.data.test.length);
            this.setState({ message_alert: "El Imei ya se encuentra registrado, favor de verificarlo" });
            this.setState({ showErrCreateAlert: true })
            //this.setState({ showCreateAlert: true })
          } else {
            axios.post(
              `http://${this.backendNode}/api/devices/create`,
              { name, otype, imei, sim_card, model, is_active, usr_id, site_id, is_deleted, last_position },
              { headers }
            )
              .then(re => {
                this.setState({ showCreateAlert: true })
                this.setState({ message_alert: "El dispositivo se ha agregado exitosamente." });
                console.log(re);
                console.log(re.data);
                this.componentDidMount();
              }).catch(err => {
                console.log('error al enviar data', err)
                this.setState({ message_alert: "No pudimos agregar el dispositivo, intenta nuevamente." });
                this.setState({ showErrCreateAlert: true })
              });
          }
        });



      // console.log(this.props.User.user.id);
      // var _usr_id = this.props.User.user.id;
      // const data = {
      //   name: this.state.deviceName,
      //   imei: Number(this.state.imei),
      //   sim_card: this.state.sim,
      //   model: this.state.selectedGroup.value,
      //   otype: this.state.otypeGroup.value,
      //   is_active: this.state.switchNew,
      //   usr_id: _usr_id,
      //   site_id: this.state.site_id,
      // };
      // const { name, otype, imei, sim_card, model, is_active, usr_id, site_id } = data;
      // const headers = {
      //   'Content-Type': 'application/json'
      // }
      // console.log('data post', data);
      // axios.post(
      //   `http://${this.backendNode}/api/devices/create`,
      //   { name, otype, imei, sim_card, model, is_active, usr_id, site_id },
      //   { headers }
      // )
      //   .then(res => {
      //     this.setState({ showCreateAlert: true })
      //     console.log(res);
      //     console.log(res.data);
      //     this.componentDidMount()
      //   }).catch(err => {
      //     console.log('error al enviar data', err)
      //     this.setState({ showErrCreateAlert: true })
      //   });

    }

    this.setState({ otypeGroup: 0 });
    this.setState({ selectedGroup: 0 });
    this.setState({ title_modal: 'Agregar nuevo Dispositivo' })

  }

  handleDisableAlert(id) {
    this.setState({ showDisableAlert: true })
  }

  handleDisable(id, status) {

    console.log("ID: ", id);
    console.log(this.state.id_d);

    const newStatus = !status;
    this.setState({ showDisableAlert: false })
    axios.put(`http://${this.backendNode}/api/devices/update-device-status/${this.state.id_d}`,
      { is_active: newStatus })
      .then(res => {
        //console.log(res);
        this.componentDidMount();
        this.setState({ showDisableAxiosAlert: false })
      });
  }

  handleDelete(id) {
    this.setState({ showDeleteAlert: false });
    console.log(this.state.id_d);
    axios.put(`http://${this.backendNode}/api/devices/update-device-delete/${this.state.id_d}`,
      { is_deleted: true })
      .then(res => {
        console.log(res);
        this.setState({ message_alert: "El dispositivo fue eliminado exitosamente." });
        this.setState({ showCreateAlert: true });
        this.componentDidMount();
      });
  }

  handleShowAlertDelete = event => {
    console.log(event.target.getAttribute('data-item'));
    var id = event.target.getAttribute('data-item');
    this.setState(prevState => ({
      showDeleteAlert: true,
      id_d: id
    }));
  }

  handleEventDisable(active_, _id) {

    console.log(_id);
    this.setState({ id_d: _id });

    if (active_ == true) {
      this.setState({ showDisableAlert: true });
    } else {
      console.log(this.state.max_gps);
      console.log(this.state.n_max_gps);
      if (this.state.max_gps == true) {
        this.setState({ showAlertDeviseMaxGGPS: true });
      } else {
        this.setState({ showAlertDeviseMaxGGPS: false });
        this.handleDisable(_id, active_);
      }
    }

  }

  // handleGetSideId(id_usr) {
  //   axios.get(`http://${this.backendNode}/api/users/find/${id_usr}`)
  //     .then(res => {
  //       this.setState({ site_id: res.data.data.site_id });
  //       console.log(this.state.site_id);
  //     });
  // }

  handleMaxGPS(_headers, total_devises_usr) {
    // console.log('Entreeeee');
    // console.log(_headers);
    axios.post(`http://${this.backendNode}/api/devices/get-max-gps`, null, {
      headers: _headers
    })
      .then((response) => {
        if (response.status == 200) {
          this.setState({ n_max_gps: response.data.max_gps });
          if (total_devises_usr == response.data.max_gps) {
            this.setState({ max_gps: true });
            this.setState({ message_usr: "No cuenta con dispositivos disponibles en su plan" });
          } else {
            this.setState({ max_gps: false });
            this.setState({ message_usr: "" });
          }
        } else {
          this.setState({ showAlertDeviseSesion: true });
        }
      })
      .catch((error) => {
        this.setState({ showAlertDeviseSesion: true });
      });
  }

  handleDeviceFilter(status) {
    this.setState({ deviceStatus: status })
    let devices = [];
    if (status == 'Activos') {
      devices = this.state.allDevices.filter(device => device.is_active == true && device.is_deleted == false && device.site_id == this.state.site_id)
    } else if (status == 'Inactivos') {
      devices = this.state.allDevices.filter(device => device.is_active == false && device.is_deleted == false && device.site_id == this.state.site_id)
    } else {
      devices = this.state.allDevices
    }
    this.setState({ devices });
  }

  handleShowEdition = event => {
    console.log(event.target.getAttribute('data-item'));
    this.setState({ title_modal: 'Actualizar Dispositivo' })
    let id = event.target.getAttribute('data-item');
    let device = this.state.allDevices.filter(device => device._id == id);
    let _imei = device[0].imei;
    let _name = device[0].name;
    let _sim = device[0].sim_card;
    let _otype = device[0].otype;
    let _model = device[0].model;
    //console.log(device[0]);
    this.setState({ imei: _imei });
    this.setState({ deviceName: _name });
    this.setState({ sim: _sim });
    this.setValueModelDevice(_model);
    this.setValueTypeDevice(_otype);
    this.setState({ Edition: true });
    this.setState({ id_d: id });
    this.tog_center();
  }

  setValueTypeDevice = value => {
    this.setState(prevState => ({
      otypeGroup: {
        label: value,
        ...prevState.otypeGroup,
        value
      }
    }));
  };

  setValueModelDevice = value => {
    this.setState(prevState => ({
      selectedGroup: {
        label: value,
        ...prevState.selectedGroup,
        value
      }
    }));
  };

  componentDidMount() {

    //console.log(this.props.User.master[0].permissions);





    var _csrftoken = this.props.login.csrftoken;
    var _key = this.props.login.key;
    var _sessionid = this.props.login.sessionid;
    //console.log(this.props.Login.key);
    const headers = {
      'Content-Type': 'application/json',
      'XCSRFToken': _csrftoken,
      'key': _key,
      'sesionId': _sessionid
    }
    // console.log(headers);
    axios.post(`http://${this.backendNode}/api/users/validation-auth`, null, {
      headers: headers
    })
      .then((response) => {
        if (response.status == 200) {
          axios.get(`http://${this.backendNode}/api/devices`)
            .then(res => {
              console.log(res);
              let id_usr = this.props.User.user.id;
              let devices, allDevices;
              allDevices = res.data.data;
              axios.get(`http://${this.backendNode}/api/users/find/${id_usr}`)
                .then(res => {
                  this.setState({ site_id: res.data.data.site_id });
                  //devices = allDevices.filter(device => device.is_active == true && device.usr_id == this.props.User.user.id);
                  devices = allDevices.filter(device => device.is_active == true && device.is_deleted == false && device.site_id == this.state.site_id);
                  //console.log(devices.length);
                  this.setState({ devices, allDevices });
                  this.handleMaxGPS(headers, devices.length);
                });
            });


        } else {
          this.setState({ showAlertDeviseSesion: true });
        }
      })
      .catch((error) => {
        this.setState({ showAlertDeviseSesion: true });
      });
  }

  render() {

    const Offsymbol = props => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            fontSize: 12,
            color: "#fff",
            paddingRight: 2
          }}
        >
        </div>
      );
    };

    const OnSymbol = props => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            fontSize: 12,
            color: "#fff",
            paddingRight: 2
          }}
        >
        </div>
      );
    };

    const { selectedGroup } = this.state;
    const { otypeGroup } = this.state;

    return (
      <React.Fragment>
        <div className="container mt-4">
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Dispositivos {this.state.deviceStatus}</h4>

                  <div style={{ position: 'absolute', top: '15px', right: '25px' }}>

                    <button className="btn btn-info mr-2"
                      onClick={() => this.handleDeviceFilter('Activos')}>
                      Dispositivos Activos
                    </button>

                    <button className="btn btn-secondary mr-2"
                      onClick={() => this.handleDeviceFilter('Inactivos')}>
                      Dispositivos Inactivos
                    </button>

                    <i className="fas fa-plus-circle text-primary"
                      style={{ visibility: !this.state.max_gps ? 'visible' : 'hidden', fontSize: '18px', cursor: 'pointer' }}
                      onClick={this.tog_center}
                      data-toggle="modal"
                      data-item='0'
                      data-target=".bs-example-modal-center">
                    </i>
                  </div>


                  {this.state.devices.length == 0 &&
                    <div className="m-2">
                      <Alert color="secondary">
                        No hay dispositivos {this.state.deviceStatus}
                      </Alert>
                    </div>
                  }
                  {this.state.devices.length > 0 &&
                    <div className="table-responsive">
                      <table className="table table-hover table-centered table-nowrap mb-0">
                        <thead>
                          <tr>
                            {/* <th scope="col">(#) Id</th> */}
                            <th scope="col">Nombre</th>
                            <th scope="col">Tipo</th>
                            <th scope="col">IMEI</th>
                            <th scope="col">SIM</th>
                            <th scope="col">Modelo</th>
                            <th scope="col">Fecha creación</th>
                            <th scope="col">Activo</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.devices.map(device => {
                            return (
                              <tr key={device._id}>
                                {/* <th scope="row">#14256</th> */}

                                <td>{device.name}</td>
                                <td>{device.otype}</td>
                                <td>
                                  <div>
                                    {/* <img
                                    src="#"
                                    alt=""
                                    className="avatar-xs rounded-circle mr-2"
                                  />{" "} */}
                                    {device.imei}
                                  </div>
                                </td>
                                <td>{device.sim_card}</td>
                                <td>{device.model}</td>
                                <td>{device.created_at.slice(0, -5)}</td>

                                <td>
                                  <Switch
                                    key={device._id}
                                    uncheckedIcon={<Offsymbol />}
                                    checkedIcon={<OnSymbol />}
                                    onColor="#02a499"
                                    onChange={() => this.handleEventDisable(device.is_active, device._id)}
                                    checked={device.is_active}
                                  />
                                  <SweetAlert
                                    key={device._id + 1}
                                    show={this.state.showDisableAlert}
                                    warning
                                    title="Quieres desactivar el dispositivo?"
                                    onConfirm={() => this.handleDisable(device._id, device.is_active)}
                                    onCancel={() => this.setState({ showDisableAlert: false })}
                                  > No podrás realizar seguimiento una vez desactivado.
                                </SweetAlert>
                                </td>

                                <td>
                                  <div>

                                    {/*
                                    <Link to="#"
                                      className="btn btn-primary btn-sm mr-sm-1"
                                      onClick={this.handleShowEdition}
                                      data-toggle="modal"
                                      data-item={device._id}
                                      data-target=".bs-example-modal-center">
                                      Edit
                                    </Link> */}
                                    <i class="fa fa-edit"
                                      onClick={this.handleShowEdition}
                                      data-item={device._id}
                                      style={{ fontSize: '18px', cursor: 'pointer' }}
                                    ></i>
                                    {/* <Link to="#" className="btn btn-danger btn-sm ml-sm-1">
                                      Delete
                                  </Link>
                                  */}
                                  </div>
                                </td>


                                <td>

                                  <i
                                    class="fas fa-trash-alt"
                                    data-item={device._id}
                                    style={{ fontSize: '18px', cursor: 'pointer' }}
                                    onClick={this.handleShowAlertDelete}>
                                  </i>

                                  <SweetAlert
                                    key={device._id + 1}
                                    show={this.state.showDeleteAlert}
                                    warning
                                    title="¿Desea eliminar el dispositivo?"
                                    text="SweetAlert in React"
                                    showCancel
                                    confirmBtnText="SI"
                                    cancelBtnText="NO"
                                    confirmBtnBsStyle="danger"
                                    cancelBtnBsStyle="primary"
                                    onConfirm={() => this.handleDelete(device._id)}
                                    onCancel={() => this.setState({ showDeleteAlert: false })}>
                                    Una vez eliminado no podrá recuperarlo.
                                </SweetAlert>

                                </td>

                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
          <div align="center">{this.state.message_usr}</div>
        </div>

        <Modal
          isOpen={this.state.modal_center}
          toggle={this.tog_center}>
          <div className="modal-header">
            <h5 className="modal-title mt-0">{this.state.title_modal}</h5>
            <button
              type="button"
              onClick={() =>
                this.setState({ modal_center: false })
              }
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <form onSubmit={this.handleSubmit}>
                      <AvForm>
                        <AvField
                          name="DeviceName"
                          label="Nombre"
                          placeholder="Nombre asociado al dispositivo"
                          type="text"
                          value={this.state.Edition == true ? this.state.deviceName : ''}
                          errorMessage="Este campo es requerido"
                          validate={{ required: { value: true } }}
                          onChange={this.handleChangeName}
                        />
                        <AvField
                          name="IMEI"
                          label="Imei"
                          placeholder="código único del dispositivo"
                          type="number"
                          value={this.state.Edition == true ? this.state.imei : ''}
                          errorMessage="Este campo es requerido"
                          validate={{
                            required: { value: true },
                            pattern: {
                              value: "^[0-9]+$",
                              errorMessage: "Only Numbers"
                            }
                          }}
                          onChange={this.handleChangeImei}
                        />
                        <AvField
                          name="number"
                          label="Tarjeta SIM"
                          placeholder="Solo campos numéricos"
                          type="number"
                          value={this.state.Edition == true ? this.state.sim : ''}
                          errorMessage="Este campo es requerido y solo acepta valores numéricos"
                          validate={{
                            required: { value: true },
                            pattern: {
                              value: "^[0-9]+$",
                              errorMessage: "Only Numbers"
                            }
                          }}
                          onChange={this.handleChangeSim}
                        />
                        <FormGroup>
                          <Label>Modelo de dispositivo</Label>
                          <Select
                            value={selectedGroup}
                            onChange={this.handleSelectGroup}
                            options={optionGroup}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label>Tipo de Dispositivo</Label>
                          <Select
                            value={otypeGroup}
                            onChange={this.handleOtypeGroup}
                            options={otypeOptions}
                          />
                        </FormGroup>

                        <div style={{ visibility: !this.state.Edition ? 'visible' : 'hidden' }}>

                          <Switch
                            className="my-2"
                            uncheckedIcon={<Offsymbol />}
                            checkedIcon={<OnSymbol />}
                            onColor="#02a499"
                            onChange={() =>
                              this.setState({ switchNew: !this.state.switchNew })
                            }
                            checked={this.state.switchNew}
                          />

                        </div>


                        <FormGroup className="mb-0">
                          <div className="ml-auto">

                            <Button type="reset"
                              color="secondary"
                              onClick={() =>
                                this.setState({ modal_center: false, Edition: false, otypeGroup: 0, selectedGroup: 0, title_modal: 'Agregar nuevo Dispositivo' })
                              }
                              data-dismiss="modal"
                              aria-label="Close">
                              Cancel
                            </Button>
                            {" "}
                            <Button type="submit"
                              color="primary"
                              className="mr-1"
                              onClick={() =>
                                this.setState({ modal_center: false })
                              }>
                              Submit
                            </Button>


                          </div>
                        </FormGroup>
                      </AvForm>
                    </form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Modal>

        {/* ALERT CREATE */}
        <SweetAlert
          show={this.state.showCreateAlert}
          success
          title="Listo!"
          onConfirm={() => this.setState({ showCreateAlert: false })} >
          {this.state.message_alert}
        </SweetAlert>

        <SweetAlert
          show={this.state.showErrCreateAlert}
          danger
          title="Error!"
          onConfirm={() => this.setState({ showErrCreateAlert: false, modal_center: true })}>
          {this.state.message_alert}
        </SweetAlert>

        {/* ALERT DISABLE */}

        <SweetAlert
          show={this.state.showDisableAxiosAlert}
          success
          title="Desactivado"
          onConfirm={() => this.setState({ showDisableAxiosAlert: false })}
        >
          El dispositivo se ha desactivado exitosamente.
        </SweetAlert>

        <SweetAlert
          show={this.state.showAlertDeviseSesion}
          warning
          title="Advertencia!"
          onConfirm={() => this.setState({ showAlertDeviseSesion: false })}
        >
          Su sesión no pudo ser validada, favor de salir y volver a acceder al sistema.
        </SweetAlert>

        <SweetAlert
          show={this.state.showAlertDeviseMaxGGPS}
          warning
          title="Advertencia!"
          onConfirm={() => this.setState({ showAlertDeviseMaxGGPS: false })}
        >Ha acanzado su maximo de dispositivos GPS.
        </SweetAlert>

      </React.Fragment>
    )
  }

}

const mapStateToProps = (state) => ({
  login: state.Login,
  User: state.User
});

//export default IotDevicesList;
// export default IotDevicesList(
//   connect(
//     mapStateToProps,
//     { }
//   )
// );

export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(IotDevicesList)
);

