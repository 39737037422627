import {
  create
} from 'apisauce';

export const baseURL = process.env.REACT_APP_BACKEND_URL;
export const baseHREF = process.env.REACT_APP_HREF_URL;

export const MARKETPLACE_URL = `${window.location.protocol}//${window.location.hostname}`;

function createHeaders(loginDetails) {
  const defaultHeaders = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  };
  let sessionHeaders = {};
  if (loginDetails) {
    sessionHeaders = {
      'Authorization': `Token ${loginDetails.key}`,
      'wf-authorization': `sessionid=${loginDetails.sessionid}; csrftoken=${loginDetails.csrftoken}`,
      'X-CSRFToken': loginDetails.csrftoken,
    };
  }
  return {
    ...defaultHeaders,
    ...sessionHeaders
  };
}

const URL_TESTER = /(http|https):\/\/.*/;
export const sanitizeImageURL = (imageUrl) => {
  if (imageUrl) {
    if (URL_TESTER.test(imageUrl)) return imageUrl;
    return baseURL + imageUrl;
  }
  return null;
};

// let wf_site_id;

// function createBaseHeaders() {

//   var request = new XMLHttpRequest();
//   //request.open('GET', `${baseURL}/commons/api/master-vars/?site_url=${'www.miwhats.com'}`, false);  // `false` makes the request synchronous
//   request.open('GET', `${baseURL}/commons/api/master-vars/?site_url=${window.location.hostname}`, false);  // `false` makes the request synchronous
//   request.send(null);

//   if (request.status === 200) {
//     const result = JSON.parse(request.responseText)
//     if (result[0]) {
//       wf_site_id = result[0].id
//       console.log('WF-SITE-ID: ', wf_site_id)
//     } else {
//       console.warn('No hay configuración para el site:', window.location.hostname)
//     }
//   } else {
//     console.error('ATENCION: No se pudo recuperar el site.', request.responseText)
//   }
//   const headers = {
//     'Cache-Control': 'no-cache',
//     ...(wf_site_id ? {'wf-site-id': wf_site_id} : {})
//   }
//   return headers
// }

const api = create({
  baseURL,
  // here are some default headers
  headers: {
    'Cache-Control': 'no-cache',
  },
  // headers: createBaseHeaders(),
  //withCredentials: true
});

if (process.env.NODE_ENV === 'development') {
  const naviMonitor = (response) => {
    const {
      url
    } = response.config;
    //console.log(`listen! ${response.config.method.toUpperCase()} ${url}`, response);
  };
  api.addMonitor(naviMonitor);
}

export const login = (email, password) => api.post(
  'web/api/login/', {
    email,
    password,
  }, {
    withCredentials: true,
  },
);

export const register = (email, first_name, last_name, tel_mobile, password1, password2) => api.post(
  'web/api/registration/', {
    email,
    first_name,
    last_name,
    tel_mobile,
    password1,
    password2,
  }, {
    withCredentials: true,
  },
);

export const updateUser = (loginDetails, id, name, last_name, tel_mobile) => api.patch(
  `commons/api/clients/${id}/`, {
    name,
    last_name,
    tel_mobile,
  }, {
    headers: createHeaders(loginDetails),
  }, {
    withCredentials: true,
  },
);

export const getFacebookLogin = (token) => api.post('web/api/facebook/', {
  access_token: token,
  code: '',
});

export const logout = (loginDetails) => api.post(
  'web/api/logout/',
  null, {
    headers: createHeaders(loginDetails),
    withCredentials: true,
  },
);

/**
 *
 * @param {*} data :
 *                name
                  email
                  subject
                  phone
                  message
                  user_id
                  appId
 * @param {*} loginDetails
 */
export const sendContact = (data, loginDetails) => api.post(
  'web/api/contact/',
  data, {
    // headers: createHeaders(loginDetails)
  },
);

export const getUserData = (loginDetails) => api.get(
  'web/api/clients/',
  null, {
    headers: createHeaders(loginDetails),
  },
);

export const getMasterVars = (loginDetails) => api.get(
  '/commons/api/master-vars/', null, {
    headers: createHeaders(loginDetails),
  },
);

export const getMasterConfigs = (loginDetails, wfSiteId) => {
  let headers = createHeaders();
  if (wfSiteId) {
    headers = {
      ...headers,
      'wf-site-id': wfSiteId,
    };
  }

  return api.get(
    '/commons/api/master/', null, {
      headers,
    },
  );
};

export const getSlides = (siteId) => api.get(
  `/commons/api/app-slides/?site_id=${siteId}`,
);

export const getCategoriasMenu = () => api.get(
  'shop/api/categories/?parents=true&is_featured=true&section=app-menu-superior',
);

export const getCategoryById = (categoryId) => api.get(
  `shop/api/categories/${categoryId}`,
);

export const getSubCategoriasMenu = (categoryId) => api.get(
  `shop/api/categories/?parent_id=${categoryId}`,
);

export const getCategoryBrands = (categoryId) => api.get(
  `shop/api/filters/category-brands/?category_id=${categoryId}`,
);

export const getBrandsByParam = ({
  term = '',
  categoryId
}) => api.get(
  `shop/api/brands/?term=${term}`,
);

export const getVariantsByParam = ({
  term = '',
  categoryId
}) => api.get(
  `commons/api/variants/?t4srch=${term}`,
);

export const getVariantAttrsByParam = ({
  term = '',
  categoryId = 123,
  scope = ''
}) => api.get(
  `commons/api/variant-attributes/?t4srch=${term}&scope=${scope}`,
);

export const getTagsByParam = ({
  term = '',
  categoryId
}) => api.get(
  `commons/api/tags/?t4srch=${term}`,
);

export const getProvidersByParam = ({
  term = '',
  categoryId
}) => api.get(
  `commons/api/providers/?term=${term}`,
);

export const deleteRelCatBrands = ({
  categoryId = 123,
  brandId = 123,
  reply = false,
  loginDetails = {}
}) => api.delete(
  `shop/api/filters/category-brands/${brandId}/`, {
    category_id: Number(categoryId),
    relation_id: brandId,
    public: true,
    reply_to_parents: reply
  }, {
    headers: createHeaders(loginDetails),
  }
);

export const deleteRelCatVariants = ({
  categoryId = 123,
  variantId = 123,
  reply = false,
  loginDetails = {}
}) => api.delete(
  `shop/api/filters/category-variants/${variantId}/`, {
    category_id: Number(categoryId),
    relation_id: variantId,
    variant_id: variantId,
    public: true,
    reply_to_parents: reply
  }, {
    headers: createHeaders(loginDetails),
  }
);

export const deleteRelCatVariantsAttr = ({
  categoryId = 123,
  variantAttrId = 123,
  reply = false,
  loginDetails = {}
}) => api.delete(
  `shop/api/filters/category-variants-attributes/${variantAttrId}/`, {
    category_id: Number(categoryId),
    relation_id: variantAttrId,
    variant_attribute_id: variantAttrId,
    public: true,
    reply_to_parents: reply
  }, {
    headers: createHeaders(loginDetails),
  }
);

export const deleteRelCatProviders = ({
  categoryId = 123,
  providerId = 123,
  reply = false,
  loginDetails = {}
}) => api.delete(
  `shop/api/filters/category-providers/${providerId}/`, {
    category_id: Number(categoryId),
    relation_id: providerId,
    public: true,
    reply_to_parents: reply
  }, {
    headers: createHeaders(loginDetails),
  }
);

export const deleteRelCatTags = ({
  categoryId = 123,
  tagId = 123,
  reply = false,
  loginDetails = {}
}) => api.delete(
  `shop/api/filters/category-tags/${tagId}/`, {
    category_id: Number(categoryId),
    relation_id: tagId,
    public: true,
    reply_to_parents: reply
  }, {
    headers: createHeaders(loginDetails),
  }
);

export const postCategoryBrands = ({
  brandIds = [],
  loginDetails = {},
}) => api.post(
  `shop/api/filters/category-brands/`, brandIds, {
    headers: createHeaders(loginDetails),
  }
);

export const postCategoryProviders = ({
  providerIds = [],
  loginDetails = {}
}) => api.post(
  `shop/api/filters/category-providers/`, providerIds, {
    headers: createHeaders(loginDetails),
  }
);

export const postCategoryVariants = ({
  variantIds = [],
  loginDetails = {}
}) => api.post(
  `shop/api/filters/category-variants/`, variantIds, {
    headers: createHeaders(loginDetails),
  }
);

export const postCategoryVariantsAttr = ({
  variantAttrIds = [],
  loginDetails = {}
}) => api.post(
  `shop/api/filters/category-variants-attributes/`, variantAttrIds, {
    headers: createHeaders(loginDetails),
  }
);

export const postCategoryTags = ({
  tagIds = [],
  loginDetails = {}
}) => api.post(
  `shop/api/filters/category-tags/`, tagIds, {
    headers: createHeaders(loginDetails),
  }
);

export const getBrands = (categoryId) => api.get(
  `shop/api/brands/`,
);

export const getTags = (categoryId, t4srch) => api.get(
  `commons/api/tags/?t4srch=${t4srch}`,
);

export const getCategoryTags = (categoryId) => api.get(
  `shop/api/filters/category-tags/?category_id=${categoryId}`,
);

export const getCategoryProviders = (categoryId) => api.get(
  `shop/api/filters/category-providers/?category_id=${categoryId}`,
);

export const getCategoryVariants = (categoryId) => api.get(
  `shop/api/filters/category-variants/?category_id=${categoryId}`,
  // commons/api/variants?cat_id
);

export const getCategoryVariantAttributes = (categoryId) => api.get(
  `shop/api/filters/category-variants-attributes/?category_id=${categoryId}`,
  // commons/api/variants?cat_id
);

export const getVariants = (categoryId, t4srch) => api.get(
  `commons/api/variants/?t4srch=${t4srch}`,
);

export const getVariantsAttrs = (categoryId, t4srch) => api.get(
  `commons/api/variants-attributes/?t4srch=${t4srch}`,
);

export const getFeatureProducts = () => api.get(
  'shop/api/products/is_featured/0/?4me=1&opt=1',
);

export const getProductById = (id) => api.get(
  `shop/api/products/${id}/`,
);

export const getRankingsProduct = (id, urlPagination) => api.get(
  urlPagination || `commons/api/ranking/shop/product/${id}/`,
);

export const getProductsByCategory = (categoryId, urlPagination) => api.get(
  urlPagination || `shop/api/products/category/${categoryId}/?products=all&opt=1`,
);

export const getCategoryFeaturesProducts = (categoryId, urlPagination) => api.get(
  urlPagination || `shop/api/products/category/${categoryId}/?products=all&opt=1&is_featured=1`,
);

export const searchProducts = (urlPagination, filterText, loginDetails, client_id, sale_pos) => {
  const url = urlPagination || `shop/api/products/?t4srch=${filterText}${client_id ? `&client_id=${client_id}` : ''}`;
  if (loginDetails) {
    return api.get(
      url,
      null, {
        headers: createHeaders(loginDetails),
      },

    );
  }
  return api.get(url);

};

export const getApiGeneral = () => api.get(
  'web/api/general/',
);

/**
 *
 * @param {*} itemData = {
 *     quantity: order,
 *     product_id: product.id
 * }
 * @param {*} loginDetails
 */
export const addToShopCart = (itemData, loginDetails) => api.post(
  'shop/api/cart-items/',
  itemData, {
    headers: createHeaders(loginDetails),
  },
);

export const removeItemShopCart = (id, loginDetails) => api.delete(
  `shop/api/cart-items/${id}/`,
  null, {
    headers: createHeaders(loginDetails),
  },
);

export const getShopCartItems = (loginDetails) => api.get(
  'shop/api/cart-items/',
  null, {
    headers: createHeaders(loginDetails),
  },
);

/**
 *
 * @param {*} itemData = = {
                "quantity": quantity, // obligatorio, de preferencia 1
                "product_id": product.id, // obligatorio, id del producto a agregar
                "wishlist_id": null // Puede ser null, id del wishlist donde se agrega el producto
            }
 * @param {*} loginDetails
 */
export const addToWhisList = (itemData, loginDetails) => api.post(
  'shop/api/wishlist-item/',
  itemData, {
    headers: createHeaders(loginDetails),
  },
);

export const getWishLists = (loginDetails) => api.get(
  'shop/api/wishlists/',
  null, {
    headers: createHeaders(loginDetails),
  },
);

export const removeFromWishlist = (productId, wishlistId, loginDetails) => api.delete(
  `shop/api/wishlist-item/${productId}/?wishlist_id=${wishlistId}`,
  null, {
    headers: createHeaders(loginDetails),
  },
);

export const getUserAddresses = (loginDetails) => api.get(
  'web/api/address/',
  null, {
    headers: createHeaders(loginDetails),
  },
);

export const getDeliveryRulesFor = (total) => api.get(
  `shop/api/client-delivery-cost/?order_total=${total}&delivery_type=delivery`,
);

export const createBuyOrder = (orderData, loginDetails) => {
  const loginkeys = loginDetails || {
    key: null,
    sessionid: null,
    csrftoken: null
  };
  return api.post(
    'shop/api/orders/?v1=1&api=pos&cover=1',
    orderData, {
      headers: createHeaders(loginkeys),
      timeout: 1200000,
    },
  );
};

export const createOrderPlanHired = (orderData, loginDetails) => {
  const loginkeys = loginDetails || {
    key: null,
    sessionid: null,
    csrftoken: null
  };
  return api.post(
    'shop/api/order-plans-hired/',
    orderData, {
      headers: createHeaders(loginkeys),
      timeout: 1200000,
    },
  );
};

export const sendRecoveryPassword = (email) => api.post(
  'web/api/recovery-request/', {
    email,
  },
);

export const getOrderHistory = (urlPagination, loginDetails, pageSize) => api.get(
  urlPagination || (pageSize ? `shop/api/orders/?v=1&cover=1&page_size=${pageSize}` : 'shop/api/orders/?v1=1&cover=1'),
  null, {
    headers: createHeaders(loginDetails),
  },
);

export const getOrderById = (orderId) => api.get(
  `shop/api/orders/?filter=all&filter_id=${orderId}&v1=1`,
);

// Descuentos **********************************
export const getDiscounts = () => api.get(
  'shop/api/discounts/',
);

export const getDiscountById = (id) => api.get(
  `shop/api/discounts/${id}/`,
);

export const getDiscountsLimitedTime = () => api.get(
  'shop/api/discounts/?type=limited-time',
);

export const getDiscountSamePrice = () => api.get(
  'shop/api/discounts/?type=same-price',
);

export const getProductDiscount = (discountId, urlPagination) => api.get(
  urlPagination || `shop/api/discount-products/?discount_id=${discountId}`,
);

export const getProductDiscountByCategory = (discountId, categoryId, urlPagination) => api.get(
  urlPagination || `shop/api/discount-products/?discount_id=${discountId}&category_id=${categoryId}`,
);

// Descuentos **********************************

// Bargains ************************************

export const getBargainProducts = (urlPagination) => api.get(
  urlPagination || 'shop/api/products/?bargains=1&opt=1',
);

export const createBargain = (productId, loginDetails) => api.post(
  'shop/api/bargains/', {
    product_id: productId,
  }, {
    headers: createHeaders(loginDetails),
  },
);

export const getBargainById = (bargainId, loginDetails) => api.get(
  `shop/api/bargains/${bargainId}/`,
  null, {
    headers: createHeaders(loginDetails),
  },
);

export const getMyBargains = (urlPagination, loginDetails) => api.get(
  urlPagination || 'shop/api/bargains/?my-bargains=True',
  null, {
    headers: createHeaders(loginDetails),
  },
);

export const addToBargainsSharedWithMe = (productId, bargainId, loginDetails) => api.post(
  'shop/api/bargains-registry/', {
    product: productId,
    bargain_id: bargainId,
  }, {
    headers: createHeaders(loginDetails),
  },
);

export const getBargainsSharedWithMe = (urlPagination, loginDetails) => api.get(
  urlPagination || 'shop/api/bargains-registry/?filter=my-registry',
  null, {
    headers: createHeaders(loginDetails),
  },
);

const getBargainRegistryByBargainId = (urlPagination, bargainId, loginDetails) => api.get(
  urlPagination || `shop/api/bargains-registry/?bargain_id=${bargainId}`,
  null, {
    headers: createHeaders(loginDetails),
  },
);
// *********************************************

// Compras compartidas ************************************
export const getMySharedShoppings = (urlPagination, loginDetails) => api.get(
  urlPagination || 'shop/api/orders/?filter=leader&v1=1',
  null, {
    headers: createHeaders(loginDetails),
  },
);

export const getJoinedShoppings = (urlPagination, loginDetails) => api.get(
  urlPagination || 'shop/api/orders/?filter=follower&v1=1',
  null, {
    headers: createHeaders(loginDetails),
  },
);
// *********************************************

//************************* */

export const getActivities = () => api.get(
  'commons/api/activities/',
);

export const getCountries = () => api.get(
  'commons/api/countries/',
);

export const getStatesByCountry = (countryId) => api.get(
  `commons/api/states/country/${countryId}/`,
);

export const getCitiesByState = (stateId) => api.get(
  `commons/api/cities/state/${stateId}/`,
);

//******************************************** */
/**
 * "name": "Baltazar Porras",
    "company": "Balt Azar C.A",
    "contact_phone": "+583408907667",
    "email": "balt-azar@gmail.com",
    "description": "---",
    "url": "balt-azar.com", // Opcional
    "activity_id": 12, // Opcional
    "country_id": 137, // Opcional
    "state_id": 586, // Opcional
    "city_id": 514 // Opcional
 * @param {*} data
 */
export const registerProvider = (data) => api.post(
  'web/api/register-provider/',
  data,
);

export const getProviders = (loginDetails) => api.get(
  'commons/api/providers/',
  null, {
    headers: createHeaders(loginDetails),
  },
);

export const getShopProviders = (filter = '') => api.get(
  `shop/api/providers/${filter}`, 'cat_id = XXX & cat_id = XXX & cat_id = XXX '
);

export const updateProviderStatus = (providerId, newStatusSlug, loginDetails) => api.patch(
  `commons/api/providers/${providerId}/`, {
    status_slug: newStatusSlug,
  }, {
    headers: createHeaders(loginDetails),
  },
);

export const getBoards = (loginDetails) => api.get(
  'kanban/api/boards/?filter=all-sites',
  null, {
    headers: createHeaders(loginDetails),
  },
);

export const getCollaborator = (loginDetails) => api.get('commons/api/collaborators/profile/0/', null, {
  headers: createHeaders(loginDetails),
});

export const getShopConfigs = (siteId) => api.get(
  `shop/api/app/${siteId}/`,
);

export const getMarketplaceMyCompanies = (urlPagination) => api.get(
  urlPagination || 'marketplaces/api/company/',
);

export const getClients = (urlPagination, loginDetails) => api.get(
  urlPagination || 'commons/api/clients/all/0/?pagination=1',
  // urlPagination ? urlPagination : `commons/api/clients/agent/all/?pagination=1`,
  null, {
    headers: createHeaders(loginDetails),
  },
);

export const getFrecuencyQuote = (data, loginDetails) => api.post(
  'shippings/api/frecuency-quote/',
  data, {
    headers: createHeaders(loginDetails),
  },
);

export const getDeliveryTypes = (loginDetails) => api.get(
  'shop/api/delivery-types/',
  null, {
    headers: createHeaders(loginDetails),
  },
);

export const searchClients = (urlPagination, text, loginDetails) => api.get(
  urlPagination || `commons/api/clients/agent/all/?pagination=1&term=${text}`,
  null, {
    headers: createHeaders(loginDetails),
  },
);

export const getClientById = (id, loginDetails) => api.get(
  `commons/api/clients/${id}/`,
  null, {
    headers: createHeaders(loginDetails),
  },
);

export const saveClient = (data, loginDetails) => api.post(
  'commons/api/clients/all/0/',
  data, {
    headers: createHeaders(loginDetails),
  },
);

export const editClient = (id, data, loginDetails) => api.patch(
  `commons/api/clients/${id}/`,
  data, {
    headers: createHeaders(loginDetails),
  },
);

export const addAdress = (loginDetails, addressData) => api.post(
  'web/api/address/',
  addressData, {
    headers: createHeaders(loginDetails),
  }, {
    withCredentials: true,
    crossDomain: true,
    dataType: 'json',
  },
);

export const editAddress = (loginDetails, addressId, addressData) => api.patch(
  `web/api/address/${addressId}/`,
  addressData, {
    headers: createHeaders(loginDetails),
  }, {
    withCredentials: true,
    crossDomain: true,
    dataType: 'json',
  },
);

export const getOffices = (loginDetails) => api.get(
  'commons/api/offices/all/0/', null, {
    headers: createHeaders(loginDetails),
  },
);

export const createGuides = (data, loginDetails) => api.post(
  'shippings/api/create-guides/',
  data, {
    headers: createHeaders(loginDetails),
    withCredentials: true,
  },
);

export const getServiceTypes = (loginDetails) => api.get(
  'shippings/api/services-types/',
  null, {
    headers: createHeaders(loginDetails),
  },
);

export const getPackageServiceConfig = (loginDetails) => api.get(
  'shippings/api/package-services-config/',
  null, {
    headers: createHeaders(loginDetails),
  },
);

/***** MARKETING */

export const getLandingInfo = (landingId, loginDetails) => api.get(
  `marketing/api/whatsapps-landings/${landingId}/`,
  null, {
    headers: createHeaders(loginDetails),
  },
);

export const updateLandingInfo = (landingId, data, loginDetails) => api.patch(
  `marketing/api/whatsapps-landings/${landingId}/`,
  data, {
    headers: createHeaders(loginDetails),
  },
);

export const getContactTypes = () => api.get(
  'commons/api/contact-type/',
);

export const getPlans = (loginDetails) => api.get(
  'shop/api/plans/',
  null, {
    headers: createHeaders(loginDetails),
  },
);

export const getPlanHired = (loginDetails) => api.get(
  'shop/api/plan-hired/',
  null, {
    headers: createHeaders(loginDetails),
  },
);

export const getCreditBalanceList = (loginDetails) => api.get(
  'credit/api/credit-balance/',
  null, {
    headers: createHeaders(loginDetails),
  },
);

export const getCreditBalanceById = (loginDetails, id) => api.get(
  `credit/api/credit-balance/?id=${id}`,
  null, {
    headers: createHeaders(loginDetails),
  },
);

export const getCreditOrderTransactions = (loginDetails, creditBalanceId) => api.get(
  `credit/api/credit-balance-transaction/?balance_sheet_id=${creditBalanceId}&otype=order-transactions`,
  null, {
    headers: createHeaders(loginDetails),
  },
);

export const getCreditPaymentTransactions = (loginDetails, creditBalanceId) => api.get(
  `credit/api/credit-balance-transaction/?balance_sheet_id=${creditBalanceId}&otype=payment-transactions`,
  null, {
    headers: createHeaders(loginDetails),
  },
);

export const getCreditOtherTransactions = (loginDetails, creditBalanceId) => api.get(
  `credit/api/credit-balance-transaction/?balance_sheet_id=${creditBalanceId}&otype=other-transactions`,
  null, {
    headers: createHeaders(loginDetails),
  },
);