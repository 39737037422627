import React from "react";
import { Link } from "react-router-dom";

//No terminado
export default function qahrmainnav(props) {
  const baseURL= props.url;
  return (
    <li>
      <Link to="/#" className="has-arrow waves-effect">
        <i style={{backgroundColor:'transparent'}}><span className="iconify" data-icon="fa-solid:users" data-inline="false"></span></i>
        <span>Recursos Humanos</span>
      </Link>
      <ul className="sub-menu" aria-expanded="false">
        <li>
          <a href={baseURL+"/commons/cms/collaborators/"}>
            <i className="fa fa-users" aria-hidden="true" style={{backgroundColor:'transparent'}}></i>
            <span className="text">Colaboradores</span>
          </a>
        </li>
        <li >
          <a href="{% url 'cms_paymethods_cards' %}">
            <i className="fa fa-credit-card" aria-hidden="true" style={{backgroundColor:'transparent'}}></i>
            <span className="text">Métodos de pago</span>
          </a>
        </li>
        <li >
          <a href={baseURL+"/commons/cms/collaborator-profile/"}>
            <i className="fa fa-user" aria-hidden="true" style={{backgroundColor:'transparent'}}></i>
            <span className="text">Mi Perfil</span>
          </a>
        </li>
      </ul>
    </li>
  );
}
