import React from 'react'
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import Row from 'reactstrap/lib/Row';
import UncontrolledTooltip from 'reactstrap/lib/UncontrolledTooltip';
import accounting from 'accounting'

import {getPlans, getPlanHired, getUserData} from '../../api/api'
import ShopPageCheckout from './ShopPageCheckout';
import ShopCart from '../shop/ShopCart'


class Plans extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            client: null,
            loading: true            
        }
    }

    componentDidMount() {
        console.log('Plans', this.props)
        this.setup()
    }

    setup() {
        this.fetchClient()
        this.fetchSitePlans()
    }

    fetchClient() {
        getUserData(this.props.login).then(result => {
            if (result.status === 200) {
                this.setState({client: result.data[0]})
            }
            this.setState({loading: false})
        })
    }

    fetchSitePlans() {
        getPlans(this.props.login).then(result => {        
            if(result.status === 200) {
                let activePlans = result.data.results.filter(it => it.active)
                this.setState({
                    allPlans: activePlans
                })
            }
        })

        getPlanHired(this.props.login).then(result => {        
            
        })

    }

    render() {
        const {
            allPlans,
            selectedPlan,
            client
        } = this.state

        let distributorSiteId = this.getDistributorSiteId()
        let sellerSiteId = this.getSellerSiteId()
        let plans = null
    
        if (allPlans) {
            plans = allPlans.map(plan => {
                let tooltipId = `BeneficionsTooltip-${plan.id}`
                return (
                    <Col style={styles.planContainer}>
                        <Col style={styles.box} onClick={() => this.setState({selectedPlan : plan})}>
                            <Row>
                                <Col xs={1}>
                                <input
                                        type="radio"                                                                                                            
                                        style={styles.checkbox}
                                        checked={selectedPlan ? (plan.id === selectedPlan.id) : false}
                                        onChange={(e) => {                                            
                                            if (e.target.checked){
                                                this.setState({selectedPlan : plan})
                                            }
                                        }}
                                    />
                                </Col>
                                <Col>
                                <span>{plan.name}</span>
                                </Col>
                                <Col sm={2} xs={4}>
                                <span>{accounting.formatMoney(plan.price_get)}</span>
                                </Col>
                            </Row>
                        </Col>
                        <div style={{display:'flex', justifyContent:"flex-end", color:'white'}}>
                            <Link 
                                to={{
                                    pathname:'/plans/details/', 
                                    state: {
                                        plan: plan,
                                        client: client,
                                        distributorSiteId: distributorSiteId,
                                        sellerSiteId: sellerSiteId
                                    }
                                }} 
                                style={{color:'white'}}>
                                <span href="#" id={tooltipId} >Beneficios</span>
                            </Link>
                            {/* <UncontrolledTooltip 
                                placement="right" 
                                target={tooltipId}
                                // trigger="click"
                                >
                                    <div dangerouslySetInnerHTML={{
                                        __html:plan.description ? plan.description.replaceAll('\n', '<br />'): ''}}/>                               
                            </UncontrolledTooltip> */}
                        </div>
                    </Col>
                )
            })
        }
        return (
            <div className="wrapper-page page-full" 
                style={{backgroundColor: '#FA2441', minHeight:"100vh"}}>
                    <ToastContainer autoClose={5000} hideProgressBar />
                    {
                        this.renderModalCheckout()
                    }
                    <Container>
                        <Row>
                            <Col>
                                <h2 style={{...styles.title, marginTop:30}}>Forma parte de</h2>
                                <h2 style={styles.title}>ElBuenComercio.mx</h2>
                                <div style={{marginTop:50}}>
                                    <h2 style={styles.title}>Nuestros Planes</h2>
                                    {
                                        plans
                                    }
                                </div>
                                <div style={{marginTop:50, marginBottom:50}}
                                    className="d-flex justify-content-center">
                                    <Button color="link" 
                                        style={{backgroundColor:'white', color:'black', padding:"20px 50px"}}
                                        onClick={() => this.onBuyPress()}
                                        >
                                        Comprar ahora
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Container>
            </div>
        )
    }

    renderModalCheckout() {
        const {
            showModalCheckout, 
            selectedPlan,
            client,
            loading
        } = this.state

        let distributorSiteId = this.getDistributorSiteId()
        let sellerSiteId = this.getSellerSiteId()

        if (showModalCheckout && selectedPlan && client) {            
            let shopCart = new ShopCart([
                {
                    product: selectedPlan,
                    quantity: 1,
                    price: selectedPlan.price_get,
                    total: selectedPlan.price_get
                }
            ])
            return (
                <Modal isOpen={showModalCheckout}  centered size="xl">
                    <ModalHeader toggle={() => this.setState({showModalCheckout: false})}>Realizar pago</ModalHeader>
                    <ShopPageCheckout 
                        cart={shopCart} 
                        user={client}
                        distributorSiteId={distributorSiteId}
                        sellerSiteId={sellerSiteId}
                        onCloseRequest={() => this.setState({showModalCheckout: false})}
                        />
                </Modal>
            )
        } else {
            if (!client && !loading) {
                return (
                    <div>
                        <span>Este usuario no tiene cliente para hacer la compra.</span>
                    </div>
                )
            }
        }
    }

    getDistributorSiteId() {
        const {match} = this.props
        if (match && match.params && match.params.wfsiteid) {
            return match.params.wfsiteid
        }
        return null
    }

    getSellerSiteId() {
        const {match} = this.props
        if (match && match.params && match.params.wfsellersiteid) {
            return match.params.wfsellersiteid
        }
        return null
    }


    onBuyPress() {
        // console.log('state', this.state)
        const {selectedPlan} = this.state        
        if (selectedPlan) {
            this.setState({showModalCheckout: true})
        } else {
            toast.info('Elije un plan')
        }
    }
}

const mapStateToProps = (state) => ({
    master: state.User.master,
    login: state.Login
});

export default connect(mapStateToProps, null)(Plans);


const styles = {
    planContainer: {
        marginBottom:40,
    },
    box: {
        backgroundColor: 'white',         
        borderRadius:10,
        padding:10
    },
    title: {
        color: 'white',
        textAlign:'center'
    }
}