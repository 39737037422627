export default class Address {

    address;

    constructor(address) {
        this.address = address
    }

    getStreet(){
        return this.address.street
    }

    getNumExt(){
        return this.address.numext
    }

    getNeighborhood(){
        return this.address.area
    }

    getZipCode(){
        return this.address.zip_code
    }

    getCityString() {
        const {city, city_text} = this.address
        return city ? city.name: city_text 
    }

    getStateString() {
        const {state, state_text} = this.address
        return state ? state.name: state_text 
    }

    getCountryString() {
        const {country, country_text} = this.address
        return country ? country.name: country_text 
    }

    getFullAddress(){
        const {street, numext, area, zip_code} = this.address
        let fullAdrress = `${street} ${numext ? 'No. '+ numext: ''}, 
                ${area}, 
                ${this.getCityString()}, 
                ${this.getStateString()}, 
                ${this.getCountryString()}, 
                ${zip_code}`
        return fullAdrress
    }

    isValid() {
        const {street, numext, area, zip_code } = this.address
        if(street && numext && zip_code && area
            && this.getCityString() && this.getStateString() && this.getCountryString()) {
            return true
        } else {
            return false
        }
    }

    getInvalidFieldsAdress() {
        let invalid = []    
        const {street, numext, area, zip_code } = this.address
        if (!street){
            invalid.push('Calle')
        }
        if (!numext){
            invalid.push('Número')
        }
        if (!area){
            invalid.push('Colonia')
        }
        if (!this.getCityString()) {
            invalid.push('Ciudad / Municipio')
        }
        if (!this.getStateString()){
            invalid.push('Estado')
        }
        if (!this.getCountryString()){
            invalid.push('País')
        }
        if (!zip_code){
            invalid.push('Código postal')
        }            
        
        return invalid.join(', ')
    }



}