import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect, Link } from 'react-router-dom';

import { loginSuccess } from '../../store/actions';
import { Alert, Button, Col, Container, Row, Spinner } from 'reactstrap';

class LoginWithURLSessionParams extends Component {

    constructor(props) {
        super(props)
        this.state = {

        }
    }
    /**
     * Redirect to login
     */
    componentDidMount = () => {
        // emit the event                
        const {location} = this.props
        if (location && location.state && location.state.login){
            const login = location.state.login
            this.props.loginSuccess(login);
            // setTimeout(() => {
            //     this.setState({redirect:location.state.path})                
            // }, 3000);
        } else {
            this.setState({redirect:'/login'})
        }
    };

    componentDidUpdate(prevProps) {        
        const {location} = this.props 
        const prevUser = prevProps.User
        const User = this.props.User
        if (prevUser && !prevProps.user && User && User.user) {
            this.setState({redirect:location.state.path})                
        } else {
            if (prevUser && prevUser.collaborator_user_error != User.collaborator_user_error) {
                this.setState({collaborator_user_error: User.collaborator_user_error})
            }
        }

    }

    render() {        
        const {general} = this.props
        const {redirect} = this.state
        if (redirect){
            return <Redirect to={redirect} />
        }
        return (
            <React.Fragment>
                {this.state.collaborator_user_error ? (
                    <Alert color="danger">{`Sesión inválida - No se pudo recuperar la información del usuario.`}</Alert>
                ) : null}
                <Container>
                    <Row>
                        <Col md={12} className="content-center-column" style={{minHeight:300}}>
                            {
                                general && (
                                    <img src={general.logo} style={{width:200, height:'auto'}} />
                                )
                            }
                            {
                                this.state.collaborator_user_error ? (
                                    <Button tag={Link} to={"/login"}>Ir al Login </Button>
                                ) 
                                : 
                                <Spinner style={{ width: '3rem', height: '3rem' }} />
                            }
                        </Col>
                    </Row>
                </Container>
            </React.Fragment>);
    }
}

const mapStateToProps= state => {
    return {
        login: state.Login,
        User: state.User,
        general: state.General.general        
    }
}

export default withRouter(
    connect(
        mapStateToProps,
        { loginSuccess }
    )(LoginWithURLSessionParams)
);
