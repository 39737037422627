import React, { Component } from 'react'

import { Link } from 'react-router-dom';
import { Row, Col, Card, CardBody, CardFooter } from 'reactstrap';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { connect } from 'react-redux';
import LandindEditor from './components/LandindEditor';

class Landing extends Component {

    constructor(props) {
        super(props)
        this.state = {     
            landingId: null      
        }
    }

    componentDidMount() {        
        const {match} = this.props
        if (match && match.params && match.params.landingId) {
            this.setState({
                landingId: match.params.landingId
            })
        }
    }

    render() {         
        const {landingId} = this.state
        return (
            <React.Fragment>
                <HelmetProvider>       
                    <Helmet>
                    <style type="text/css">{`

                        [v-cloak] > * { display:none; }
                        [v-cloak]::before { content: "Cargando..."; }

                        .center {
                            margin: auto;
                            width: 80%;
                            padding: 10px;
                            text-align: center;
                        }
                        h1 {
                            font-size: 100px;
                            margin-bottom: 20px;
                        }

                        .image_miwhats{
                            max-height:200px;
                            max-width: 100%;
                            width:auto;
                            #margin: auto;
                        }

                        .icon {
                            width: 30px;
                            height: 30px;
                            margin-right: 5px;
                        }
                        .landing_button {
                            background-color:#{{item.button_background_color}};
                            color:#{{item.button_text_color}};
                            cursor: pointer;
                            font-size: 2.5rem;
                            font-weight:bold;
                            border-radius: 90px;
                            border: none;
                            padding: 10px 15px;
                            transition: all 0.5s ease;
                            margin: 10px 0px;
                        }
                        a.landing_button, .landing_button a{
                            color:#{{item.button_text_color}};
                            text-decoration: none;
                        }
                        a.landing_button:hover, .landing_button a:hover{
                            color:#{{item.button_text_color}};
                            text-decoration: none;
                        }

                        .button-icon {
                            height: 3rem;
                            width: 3rem;
                        }

                        .text{
                            color:#{{item.text_color}};
                            font-size: 1.0rem;
                            margin:25px 0px;    
                        }

                        form.form-inline fieldset .v-provider {
                            display: inline-block;
                            vertical-align: top;
                        }

                        span.error {
                            color: red;
                            font-size: 0.9rem;
                        }

                        @media (min-width: 992px){
                            .button-icon {
                                height: 1.5rem;
                                width: 1.5rem;
                            }
                            .landing_button {
                                font-size:1rem;
                                font-weight:400;
                                margin: 20px 8px;
                                padding: 10px 15px;
                            }
                        }

                        .container {
                            max-width: 100%;
                        }

                        @media (min-width: 750px){
                            html, body, #root, #root>div {
                                height: 100%
                              }
                        }

                    `}</style>
                    </Helmet>   
                    {
                        landingId && (<LandindEditor landingId={landingId}/> )
                    }   
                </HelmetProvider>
            </React.Fragment>
        )
    }

}

const mapStateToProps = (state) => ({
    master: state.User.master
});

export default connect(mapStateToProps, null)(Landing);