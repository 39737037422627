import React from "react";
import { Link } from "react-router-dom";

export default function qapropertymainnav(props) {
  const baseURL= props.url;
  return (
    <li>
      <Link to="/#" className="has-arrow waves-effect">
        <i >
        <span className="iconify" data-icon="ant-design:home-filled" data-inline="false"></span>
        </i>
        <span>Módulo Propiedades</span>
      </Link>
      <ul className="sub-menu" aria-expanded="false">
        <li>
          <a href={baseURL+"/property/cms/"}>Dashboard</a>
        </li>
        <li>
          <Link to="/#" className="has-arrow waves-effect">
            <span> Mis oportunidades</span>
          </Link>
          <ul className="sub-menu" aria-expanded="false">
            <li>
              <a href={baseURL+"/property/cms/"}>
                Propiedades
              </a>
            </li>
            <li>
              <a href={baseURL+"/property/cms/"}>Clientes</a>
            </li>
          </ul>
        </li>        
        <li>
          <Link to="/#" className="has-arrow waves-effect">
            <span> Reportes</span>
          </Link>          
          <ul className="sub-menu" aria-expanded="false">
            <li>
              <a href={baseURL+"/property/reports-sales/"}>
                Cierres
              </a>
            </li>
            <li>
              <a href={baseURL+"/property/reports-agents/"}>
                Exclusivas de Asesores
              </a>
            </li>
          </ul>
        </li>
        <li>
          <Link to="/#" className="has-arrow waves-effect">
            <span> Propiedades</span>
          </Link>          
          <ul className="sub-menu" aria-expanded="false">
            <li>
              <a href={baseURL+"/property/cms/property/"}>
              Mis Propiedades
              </a>
            </li>
            <li>
              <a href={baseURL+"/property/cms/property-inactive/"}>
              Propiedades Inactivas
              </a>
            </li>
            <li>
              <a href={baseURL+"/property/cms/property-share/"}>
              Propiedades Compartidas
              </a>
            </li>
            <li>
              <a href={baseURL+"/property/cms/property-pixel-unnasigned/"}>
              Pixel Sin Asignar
              </a>
            </li>
            <li>
              <a href={baseURL+"/desconocida"}>
              Solicitudes de captaci&oacute;n
              </a>
            </li>
            <li>
              <a href={baseURL+"/property/cms/property-type/"}>
              Tipos de Propiedades
              </a>
            </li>
            <li>
              <a href={baseURL+"/property/cms/transaction-type/"}>
              Tipos de Transacciones
              </a>
            </li>
            <li>
              <a href={baseURL+"/property/cms/property-status/"}>
              Estatus de Propiedades
              </a>
            </li>
          </ul>
        </li>
        <li>
          <Link to="/#" className="has-arrow waves-effect">
            <span> Configuración</span>
          </Link>           
          <ul className="sub-menu" aria-expanded="false">
            <li>
              <a href={baseURL+"/property/cms/config/"}>
              Módulo Propiedades
              </a>
            </li>
           
          </ul>
        </li>
      </ul>
    </li>
  );
}
