import React from "react";
import { Link } from "react-router-dom";

export default function qalearnmainnav(props) {
  const baseURL= props.url;
  return (
    <li>
      <Link to="/#" className="has-arrow waves-effect">
        <i>
        <span className="iconify" data-icon="map:university" data-inline="false"></span>
        </i>
        <span>Módulo Cursos</span>
      </Link>
      <ul className="sub-menu" aria-expanded="false">
        <li>
          <a href={baseURL+"/learn/dashboard/"}>
            Dashboard
          </a>
        </li>
        <li>
          <a href={baseURL+"/learn/cms/courses/"}>
          Cursos
          </a>
        </li>
      </ul>
    </li>
  );
}
