import React from "react";
import { Link } from "react-router-dom";

export default function qainventorymainnav(props) {
  const baseURL= props.url;
  return (
    <li>
      <Link to="/#" className="has-arrow waves-effect">
        <i style={{backgroundColor:'transparent'}}><span className="iconify" data-icon="bx:bx-cube" data-inline="false"></span></i>
        <span>Inventario</span>
      </Link>
      <ul className="sub-menu" aria-expanded="false">
        <li><a href={baseURL+"/inventory/dashboard/"}><i className="fa fa-bar-chart" aria-hidden="true"></i><span className="text">Dashboard</span></a></li>
        <li><a href={baseURL+"/inventory/new-movement/"}><i className="icon ion-clipboard" aria-hidden="true"></i><span className="text">Capturar Movimientos</span></a></li>
        <li><a href={baseURL+"/inventory/movements/all/"}><i className="icon ion-arrow-swap" aria-hidden="true"></i><span className="text">Movimientos</span></a></li>
        <li><a href={baseURL+"/inventory/movements/all-pending/"}><i className="icon ion-arrow-swap" aria-hidden="true"></i><span className="text">Movimientos Pendientes</span></a></li>        
        <li><a href={baseURL+"/inventory/preinventory/"}><i className="icon ion-clipboard" aria-hidden="true"></i><span className="text">Preinventarios</span></a></li>
      </ul>
    </li>
  );
}
