import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import MetisMenu from "metismenujs";

import SimpleBar from "simplebar-react";

//Menu
import Qashopmainnav from "./Menu/qa-shop-main-nav";
import Qapropertymainnav from "./Menu/qa-property-main-nav";
import Qalearnmainnav from "./Menu/qa-learn-main-nav";
import Qaaffiliatemainnav from "./Menu/qa-affiliate-main-nav";
import Qainventorymainnav from "./Menu/qa-inventory-main-nav";
import Qacadimainnav from "./Menu/qa-cadi-main-nav";
import Qatransportmainnav from "./Menu/qa-transport-main-nav";
import Qacreditmainnav from "./Menu/qa-credit-main-nav";
import Qatravelmainnav from "./Menu/qa-travel-main-nav";
import Qaprolasmainnav from "./Menu/qa-prolas-main-nav";
import Qaiotmainnav from "./Menu/qa-iot-main-nav";
import Qaexposmainnav from "./Menu/qa-expos-main-nav";
import Qamktmainnav from "./Menu/qa-mkt-main-nav";
import Qahrmainnav from "./Menu/qa-hr-main-nav";
import Qaauditmainnav from "./Menu/qa-audit-main-nav";
import Qafinancemainnav from "./Menu/qa-finance-main-nav";
import Qamarketplacesmainnav from "./Menu/qa-marketplaces-main-nav";
import Qareportsmainnav from "./Menu/qa-reports-main-nav";
import Tutoriales from "./Menu/qa-tutoriales";
import Qacatalogo from "./Menu/qa-catalog-main-nav";
import Qawebmainnav from "./Menu/qa-web-main-nav";
import Qaofficemainnav from "./Menu/qa-office-main-nav";
import Qakanbanmainnav from "./Menu/qa-kanban-main-nav";
import Qacbssmainnav from "./Menu/qa-cbss-main-nav";
import { baseURL, baseHREF } from "../../api/api";

const SidebarContent = (props) => {
  const { slideProps } = props.props;
  const master = slideProps.master[0];
  /* console.log('-----------\n', slideProps, '-----------\n'); */
  if (master) {
    return (
      <div id="sidebar-menu">
        <ul className="metismenu list-unstyled" id="side-menu">
          <li className="menu-title">Inicio</li>

          <Qakanbanmainnav />

          {(master.permissions && master.permissions.User_GPS_General ||
            master.permissions && master.permissions.User_GPS_Admin ||
            master.permissions && master.permissions.User_GPS) ? (
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i style={{ backgroundColor: 'transparent' }}>
                    <span className="iconify" data-icon="mdi-crosshairs-gps"></span>
                  </i>
                  <span>GPS</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/iot-gps" className=" waves-effect">
                      <span>Mapa</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/iot-geocerca" className=" waves-effect">
                      <span>Geocercas</span>
                    </Link>
                  </li>

                  {(master.permissions &&master.permissions &&  master.permissions.User_GPS_General ||
                    master.permissions &&master.permissions &&  master.permissions.User_GPS_Admin) ? (
                      <li>
                        <Link to="/iot-devices" className=" waves-effect">
                          <span>Dispositivos</span>
                        </Link>
                      </li>
                    ) : ("")}
                </ul>
              </li>
            ) : (
              ""
            )}

          <li>
            <Link to="/#" className="has-arrow waves-effect">
              <i style={{ backgroundColor: 'transparent' }}>
                <span className="iconify" data-icon="mdi:bulletin-board"></span>
              </i>
              <span>Paquetería</span>
            </Link>
            <ul className="sub-menu" aria-expanded="false">
              <li>
                <Link to="/shop/admin/sale-delivery/" className=" waves-effect">
                  <span>Cotizador de Envío</span>
                </Link>
              </li>
              <li>
                <a href={baseHREF + '/shippings/cms/guides/'}>
                  Guías de Envío
                </a>
              </li>
            </ul>
          </li>

          {master.appointments || master.transport ? (
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i style={{ backgroundColor: 'transparent' }}>
                  <span
                    className="iconify"
                    data-icon="ant-design:calendar-outlined"
                    data-inline="false"
                  ></span>
                </i>
                <span>Calendario</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <a href={baseHREF + "/commons/cms/calendars/"}>
                    Mis Calendarios
                  </a>
                </li>
                <li>
                  <a href={baseHREF + "/commons/cms/events/all/"}>Mis Eventos</a>
                </li>
              </ul>
            </li>
          ) : (
              ""
            )}

          {/* {console.log("Master ", master)} */}

          {master.immo ? <Qapropertymainnav url={baseURL} /> : ""}
          {master.learn ? <Qalearnmainnav url={baseURL} /> : ""}
          {master.shop &&
            (master.permissions && master.permissions.User_Sales ||
              master.permissions && master.permissions.User_Sales_Admin ||
              master.permissions && master.permissions.User_Sales_General) ? (
              <Qashopmainnav url={baseURL} />
            ) : (
              ""
            )}
          {master.shop &&
            (master.permissions && master.permissions.User_Sales ||
              master.permissions && master.permissions.User_Sales_Admin ||
              master.permissions && master.permissions.User_Sales_General) ? (
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="fa fa-truck" style={{ backgroundColor: 'transparent' }}></i>
                  <span className="text">Logística</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <a href={baseHREF + "/shop/admin/routes/"}>
                      <span className="text">Rutas</span>
                    </a>
                  </li>
                  <li>
                    <a href={baseHREF + "/shop/admin/new-route/"}>
                      <span className="text">Crear Ruta</span>
                    </a>
                  </li>
                </ul>
              </li>
            ) : (
              ""
            )}
          {master.shop || master.inventory ? <Qacatalogo url={baseURL} /> : ""}
          {/* {master.affiliate ? <Qaaffiliatemainnav url={baseURL} /> : ""} */}
          {master.shop || master.inventory ? <Qainventorymainnav /> : ""}
          {master.cadi ? <Qacadimainnav url={baseURL} /> : ""}
          {master.transport ||
            master.permissions && master.permissions.User_Transport_Admin ||
            master.permissions && master.permissions.User_Transport_General ? (
              <Qatransportmainnav url={baseURL} />
            ) : (
              ""
            )}
          {master.credit ? <Qacreditmainnav url={baseURL} /> : ""}
          {((master.balance_sheet && master.marketplaces &&master.permissions &&  master.permissions.User_Credit_Admin) || true) && (
            <Qacbssmainnav />
          )}
          {/*<Qaasmainnav />*/}
          {master.travel ? <Qatravelmainnav url={baseURL} /> : ""}
          {master.prolas ? <Qaprolasmainnav url={baseURL} /> : ""}
          {master.iot ? <Qaiotmainnav url={baseURL} /> : ""}
          {master.expos ? <Qaexposmainnav url={baseURL} /> : ""}
          {master.crm ? (
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i style={{ backgroundColor: 'transparent' }}>
                  <span
                    className="iconify"
                    data-icon="fa-solid:user-alt"
                    data-inline="false"
                  ></span>
                </i>
                <span>Clientes</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <a href={baseHREF + "/web/crm/clients"}>Clientes</a>
                </li>
              </ul>
            </li>
          ) : (
              ""
            )}
          {master.cadi || master.marketing || master.whatsapp ? (
            <Qamktmainnav url={baseURL} />
          ) : (
              ""
            )}
          <Qahrmainnav url={baseURL} />
          {master.web ? <Qawebmainnav url={baseURL} /> : ""}
          {master.web ? (
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i style={{ backgroundColor: 'transparent' }}>
                  <span
                    className="iconify"
                    data-icon="bx:bxs-share-alt"
                    data-inline="false"
                  ></span>
                </i>
                <span>Redes sociales</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <a href={baseHREF + "/web/cms/"}>Contenidos</a>
                </li>
              </ul>
            </li>
          ) : (
              ""
            )}
          <Qaofficemainnav url={baseURL} />
          {master.transport || master.appointments ? (
            <Qaauditmainnav url={baseURL} />
          ) : (
              ""
            )}
          {master.finance ? <Qafinancemainnav url={baseURL} /> : ""}
          {master.marketplaces ||
            master.permissions && master.permissions.User_Sales_General ||
            master.shop ? (
              <Qamarketplacesmainnav url={baseURL} />
            ) : (
              ""
            )}
          {master.reports ? <Qareportsmainnav url={baseURL} /> : ""}
          {master.app ? (
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i style={{ backgroundColor: 'transparent' }}>
                  {" "}
                  <span
                    className="iconify"
                    data-icon="gridicons:phone"
                    data-inline="false"
                  ></span>
                </i>
                <span>App Móvil</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <a href={baseHREF + "/commons/admin/app/"}>App General</a>
                </li>
                <li>
                  <a href={baseHREF + "/commons/admin/app-slides/"}>App Slides</a>
                </li>
                <li>
                  <a href={baseHREF + "/shop/admin/app/"}>E-Shop</a>
                </li>
                <li>
                  <a href={baseHREF + "/commons/admin/app/"}>Notificaciones</a>
                </li>
              </ul>
            </li>
          ) : (
              ""
            )}
          {master.distributor ? (
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i style={{ backgroundColor: 'transparent' }}>
                  <span
                    className="iconify"
                    data-icon="bx:bx-paper-plane"
                    data-inline="false"
                  ></span>
                </i>
                <span>Planes</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <a href={baseHREF + "/commons/admin/app/"}>
                    Configuración Seccion de Planes
                  </a>
                </li>
                <li>
                  <a href={baseHREF + "commons/admin/app-slides/"}>
                    Plataformas de pago
                  </a>
                </li>
                <li>
                  <a href={baseHREF + "/shop/admin/app/"}>
                    Métodos para plataformas de pago
                  </a>
                </li>
                <li>
                  <a href={baseHREF + "/commons/admin/app/"}>
                    Credenciales en plataformas de pago
                  </a>
                </li>
              </ul>
            </li>
          ) : (
              ""
            )}
          {master.distributor ? (
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i style={{ backgroundColor: 'transparent' }}>
                  <span
                    className="iconify"
                    data-icon="bx:bx-paper-plane"
                    data-inline="false"
                  ></span>
                </i>
                <span>Distribuidor</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <a href={baseHREF + "/commons/admin/app/"}>Empresas/Sitio</a>
                </li>
                <li>
                  <a href={baseHREF + "/commons/admin/app-slides/"}>
                    Tipos de transacciones
                  </a>
                </li>
                <li>
                  <a href={baseHREF + "/web/cms/metrics/"}>Métricas</a>
                </li>
              </ul>
            </li>
          ) : (
              ""
            )}
          {master.shop ? (
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i style={{ backgroundColor: 'transparent' }}>
                  <span
                    className="iconify"
                    data-icon="whh:settingsandroid"
                    data-inline="false"
                  ></span>
                </i>
                <span>Configuración</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <a href={baseHREF + "/commons/admin/app/"}>Categorías</a>
                </li>
                <li>
                  <a href={baseHREF + "/commons/admin/app-slides/"}>Etiquetas</a>
                </li>
                <li>
                  <a href={baseHREF + "/shop/admin/app/"}>Servidor de E-Mail</a>
                </li>
                <li>
                  <a href={baseHREF + "/commons/admin/app/"}>Textos de E-Mail</a>
                </li>
                <li>
                  <a href={baseHREF + "/commons/admin/app/"}>Formularios</a>
                </li>
              </ul>
            </li>
          ) : (
              ""
            )}
          <Tutoriales url={baseURL} />
          {master.whatsapp ? (
            <li>
              <a href={baseHREF + "/web/logout/"}>
                <i className="fa fa-sign-out"></i>
                <span className="text">Cerrar Sesión</span>
              </a>
            </li>
          ) : (
              ""
            )}
        </ul>
      </div>
    );
  } else {
    return null
  }
};

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.initMenu();

  }

  componentDidUpdate(prevProps) {
    if (this.props.type !== prevProps.type) {
      this.initMenu();
    }
  }

  initMenu() {
    if (this.props.type !== "condensed" || this.props.isMobile) {
      new MetisMenu("#side-menu");

      var matchingMenuItem = null;
      var ul = document.getElementById("side-menu");
      var items = ul.getElementsByTagName("a");
      for (var i = 0; i < items.length; ++i) {
        if (this.props.location.pathname === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        this.activateParentDropdown(matchingMenuItem);
      }
    }
  }

  activateParentDropdown = (item) => {
    item.classList.add("mm-active");
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add("mm-active"); // li
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show");

        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add("mm-active");
          }
        }
      }
      return false;
    }
    return false;
  };

  render() {
    return (
      <React.Fragment>
        {this.props.type !== "condensed" ? (
          <SimpleBar style={{ maxHeight: "100%" }}>
            <SidebarContent props={this.props} />
          </SimpleBar>
        ) : (
            <SidebarContent props={this.props} />
          )}
      </React.Fragment>
    );
  }
}

export default withRouter(Sidebar);
