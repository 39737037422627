import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import Alert from 'react-bootstrap/Alert';
import { getCreditBalanceById, getCreditOrderTransactions, getCreditPaymentTransactions, getCreditOtherTransactions, baseURL } from '../../api/api';
import Table from '../../components/Table';

const locale = 'en-US';

const localeCurrencyStyle = {
  style: 'currency',
  currency: 'MXN',
};

const transactionTitles = [
  'Fecha',
  'Hora',
  'ID Operación',
  'Detalle Transacción',
  'SKU',
  'Unidades',
  'Precio Unitario',
  'Ingresos',
  'Egresos',
];

const AccountStatement = (props) => {
  const { id } = useParams();
  const { login } = props;
  const [statement, setStatement] = useState(null);
  const [orderTransactions, setOrderTransactions] = useState([]);
  const [paymentTransactions, setPaymentTransactions] = useState([]);
  const [otherTransactions, setOtherTransactions] = useState([]);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {

    getCreditBalanceById(login, id)
    .then((response) => {
      const { data } = response;

      if (data.count > 0) {

        setStatement(data.results[0]);
        const balanceId = data.results[0].id;

        getCreditOrderTransactions(login, balanceId)
        .then((response) => {
          const dataO = response.data;
          setOrderTransactions(dataO.results);
        });

        getCreditPaymentTransactions(login, balanceId)
        .then((response) => {
          const dataP = response.data;
          setPaymentTransactions(dataP.results);
        });

        getCreditOtherTransactions(login, balanceId)
        .then((response) => {
          const dataO = response.data;
          setOtherTransactions(dataO.results);
        });

      } else {
        setShowAlert(true);
      }
    });
  }, []);

  return (
    <div>
      <Row className='align-items-center'>
        <Col sm={6}>
          <div className='page-title-box'>
            <h4 className='font-size-18'>Estados de cuentas</h4>
            <ol className='breadcrumb mb-0'>
              <li className='breadcrumb-item'>
                <Link to='/account-statements'>Estados de cueta</Link>
              </li>
              <li className='breadcrumb-item'>
                <Link to={`/account-statements/${id}`}>{id}</Link>
              </li>
            </ol>
          </div>
        </Col>
        <Col sm='6'>
          <div className='float-right d-none d-md-block'>
            {/* <SettingMenu /> */}
          </div>
        </Col>
      </Row>
      <Alert show={showAlert} variant='danger'>
        <Alert.Heading>Estado de cuenta no encontrado</Alert.Heading>
        <p>
          No se encontro el estado de cuenta con el id #
          {id}
          , intente buscando otro.
        </p>
      </Alert>
      { statement && (
        <>
          <Row className='pb-5'>
            <Col
              xs={4}
              style={{
              backgroundColor: 'black',
              color: 'white',
            }}
            >
              <Container>
                <Row>
                  <Col className='mb-3'>
                    Contrato No:
                    {statement.provider.id}
                  </Col>
                </Row>
                <Row>
                  <Col className='mb-3'>
                    Nombre:
                    {statement.provider.name}
                  </Col>
                </Row>
                <Row>
                  <Col className='mb-3'>
                    Empresa:
                    {statement.provider.company}
                  </Col>
                </Row>
                <Row>
                  <Col className='mb-3'>
                    No. De cuenta:
                  </Col>
                </Row>
                <Row>
                  <Col className='mb-3'>
                    Banco:
                  </Col>
                </Row>
              </Container>
            </Col>
            <Col xs={4} className='offset-4'>
              <Container>
                <Row className='mb-4' style={{ backgroundColor: 'red' }}>
                  <Col className='text-center border border-dark' xs={6}>
                    Periodo de consulta
                  </Col>
                  <Col xs={3} className='p-0'>
                    <Container fluid>
                      <Row>
                        <Col className='text-center'>Deste</Col>
                      </Row>
                      <Row style={{ backgroundColor: 'white' }}>
                        <Col xs={6}>{new Date(statement.date_start).toLocaleDateString(locale)}</Col>
                      </Row>
                    </Container>
                  </Col>
                  <Col xs={3} className='p-0'>
                    <Container fluid>
                      <Row>
                        <Col className='text-center'>Hasta</Col>
                      </Row>
                      <Row style={{ backgroundColor: 'white' }}>
                        <Col>{new Date(statement.date_end).toLocaleDateString(locale)}</Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
                <Row className='text-center border border-dark' style={{ backgroundColor: 'red' }}>
                  <Col>M</Col>
                </Row>
              </Container>
            </Col>
          </Row>
          {orderTransactions.length && (
            <Table mainTitle='Movimientos aplicados' tableTitles={transactionTitles}>
              <>
                {orderTransactions.map((order) => {
                  const { transaction } = order;
                  const date = new Date(transaction.applied_at);
                  return (
                    <tr key={`otype-order-transactions-${transaction.id}`}>
                      <td>
                        {date.toLocaleDateString(locale, localeCurrencyStyle)}
                      </td>
                      <td>
                        {`${date.getHours()}:${date.getMinutes()}`}
                      </td>
                      <td>
                        {transaction.id}
                      </td>
                      <td>
                        <a href={`${baseURL}/shop/admin/dashboard/pedidos/detalle/${transaction.id}`}>
                          {order.description}
                        </a>
                      </td>
                      <td colSpan={3} />
                      <td colSpan={2}>
                        {Math.abs(transaction.total).toLocaleString(locale, localeCurrencyStyle)}
                      </td>
                    </tr>
                  );
                })}
                <tr className='bg-primary'>
                  <td colSpan={7} />
                  <td>
                    {Math.abs(statement.order_charge_balance).toLocaleString(locale, localeCurrencyStyle)}
                  </td>
                  <td>
                    {Math.abs(statement.order_payment_balance).toLocaleString(locale, localeCurrencyStyle)}
                  </td>
                </tr>
                <tr className='bg-info'>
                  <td colSpan={7} />
                  <td colSpan={2}>
                    {Math.abs(statement.order_balance_actual).toLocaleString(locale, localeCurrencyStyle)}
                  </td>
                </tr>
              </>
            </Table>
          )}
          {paymentTransactions.length && (
            <Table mainTitle='Pagos' tableTitles={transactionTitles}>
              <>
                {paymentTransactions.map((order) => {
                  const { transaction } = order;
                  const date = new Date(transaction.applied_at);
                  return (
                    <tr key={`otype-payment-transactions-${transaction.id}`}>
                      <td>
                        {date.toLocaleDateString(locale, localeCurrencyStyle)}
                      </td>
                      <td>
                        {`${date.getHours()}:${date.getMinutes()}`}
                      </td>
                      <td>
                        {transaction.id}
                      </td>
                      <td>
                        <a href={`${baseURL}/shop/admin/dashboard/pedidos/detalle/${transaction.id}`}>
                          {order.description}
                        </a>
                      </td>
                      <td colSpan={3} />
                      <td colSpan={2}>
                        {Math.abs(transaction.total).toLocaleString(locale, localeCurrencyStyle)}
                      </td>
                    </tr>
                  );
                })}
                <tr className='bg-info'>
                  <td colSpan={7} />
                  <td colSpan={2}>
                    {Math.abs(statement.payment_payment_balance).toLocaleString(locale, localeCurrencyStyle)}
                  </td>
                </tr>
              </>
            </Table>
          )}
          {otherTransactions.length && (
            <Table mainTitle='Otros' tableTitles={transactionTitles}>
              <>
                {otherTransactions.map((order) => {
                  const { transaction } = order;
                  const date = new Date(transaction.applied_at);
                  return (
                    <tr key={`otype-other-transactions-${transaction.id}`}>
                      <td>
                        {date.toLocaleDateString(locale, localeCurrencyStyle)}
                      </td>
                      <td>
                        {`${date.getHours()}:${date.getMinutes()}`}
                      </td>
                      <td>
                        {transaction.id}
                      </td>
                      <td>
                        <a href={`${baseURL}/shop/admin/dashboard/pedidos/detalle/${transaction.id}`}>
                          {order.description}
                        </a>
                      </td>
                      <td colSpan={3} />
                      <td colSpan={2}>
                        {Math.abs(transaction.total).toLocaleString(locale, localeCurrencyStyle)}
                      </td>
                    </tr>
                  );
                })}
                <tr className='bg-info'>
                  <td colSpan={7} />
                  <td colSpan={3}>
                    {Math.abs(statement.other_charge_balance).toLocaleString(locale, localeCurrencyStyle)}
                  </td>
                </tr>
              </>
            </Table>
          )}
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <h4 className='card-title mb-4'>Total</h4>
                  <Container fluid>
                    <Row>
                      <Col>Saldo</Col>
                      <Col className='text-right'>
                        $
                        {statement.total_balance.toLocaleString(locale)}
                      </Col>
                    </Row>
                  </Container>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

const mapStatetoProps = (state) => {
  return {
    login: state.Login,
    ...state.User,
  };
};

export default connect(mapStatetoProps, null)(AccountStatement);