import React, { Component } from "react";
import { Row, Col, Card, CardBody, Alert, Nav, NavLink, NavItem, CardText, TabContent, TabPane, Collapse, Button } from "reactstrap";
import classnames from "classnames";
import { Map, InfoWindow, Marker, GoogleApiWrapper, Polygon, Circle } from "google-maps-react";
import { connect } from "react-redux";
import openSocket from "socket.io-client";
import SweetAlert from 'react-bootstrap-sweetalert';
import moment from 'moment';
import axios from 'axios';
import Rect from './util/Rect'
import Container from "reactstrap/lib/Container";
import { isMobile, logInDebug } from '../../utils/Utils'
//import { Circle } from "react-google-maps";



require('moment/locale/es');

const LoadingContainer = props => <div>Loading...</div>;

const initialCenterMap = {
  lat: 20.03333,
  lng: -103.22222,
}

class GpsMap extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showingInfoWindow: false,
      activeMarker: null,
      selectedPlace: {},
      socketData: [],
      mapZoom: 7,
      activeTab1: 'active',
      devices: [],
      devicesOff: [],
      allDevices: [],
      deviceListOpen: false,
      showErrCreateAlert: false,
      isMobile: isMobile(),

      lat_geo: 22.7196,
      lng_geo: 75.8577,
      lat_2: 20.03333,
      lng_2: -103.22222,
    };

    this.backendNode = process.env.REACT_APP_NODE_BACKEND_URL;
    this.toggle1 = this.toggle1.bind(this);
    // this.onMarkerClick = this.onMarkerClick.bind(this);
  }

  onMarkerClick(device, marker) {
    logInDebug('onMarkerClick', device, marker)
    if (device.last_position) {
      if (this.mapRef) {
        this.mapRef.map.setCenter({
          lat: device.last_position.lat,
          lng: device.last_position.long
        }
        )
        this.mapRef.map.setZoom(18)
      }

      this.setState({
        showingInfoWindow: true,
        activeMarker: { device, marker }
      })

      if (this.state.isMobile) {
        this.setState({
          deviceListOpen: false
        })
      }
    }
  }

  toggle1(tab) {
    if (this.state.activeTab1 !== tab) {
      this.setState({
        activeTab1: tab
      });
    }
  }

  handleMovementStatus(isMoving) {
    let status;
    (isMoving) ? status = 'success' : status = 'warning';
    return status;
  }

  handleGetDevices() {
    //Evaluar como mandar token, sessionID, y key.
    axios.get(`http://${this.backendNode}/api/devices`)
      .then(res => {
        let devices, allDevices, devicesOff;
        allDevices = res.data.data;
        logInDebug('get all devices', allDevices)
        devices = allDevices.filter(device => device.is_active == true);
        devicesOff = allDevices.filter(device => device.is_active == false);
        this.setState({ devices, allDevices, devicesOff });
      })
  }

  toggleDeviceList() {
    let deviceListOpen = !this.state.deviceListOpen
    this.setState({ deviceListOpen })
  }

  componentDidMount() {




    //llamar funcion de node para validar el token, si si es valido continuo y si no lanzo un mensaje    
    var _csrftoken = this.props.login.csrftoken;
    var _key = this.props.login.key;
    var _sessionid = this.props.login.sessionid;

    const headers = {
      'Content-Type': 'application/json',
      'XCSRFToken': _csrftoken,
      'key': _key,
      'sesionId': _sessionid
    }
    //console.log(headers);
    axios.post(`http://${this.backendNode}/api/users/validation-auth`, null, {
      headers: headers
    })
      .then((response) => {
        if (response.status == 200) {
          this.handleGetDevices();
          // socket se a llamar si y solo si socketData tiene dispositivos
          console.log('Conectando al socket: ', process.env.REACT_APP_NODE_SOCKET_BACKEND_URL)
          var socket = openSocket(process.env.REACT_APP_NODE_SOCKET_BACKEND_URL);
          socket.on('connect', (data) => {
            console.log('socket.on CONNECT', data)
          })
          socket.on('disconnect', () => {
            console.log('Socket desconectado')
          })
          socket.on("msgToClient", data => {
            logInDebug('socket data', data);
            console.log('#');
            this.updateDevicesInfo(data)
          });
        } else {
          this.setState({ showErrCreateAlert: true });
        }
      })
      .catch((error) => {
        this.setState({ showErrCreateAlert: true });
      });

  }

  updateDevicesInfo(data) {
    const { devices } = this.state
    if (devices) {
      let updatedDevice = devices.find(it => it.imei === data.imei)
      if (updatedDevice) {
        this.moveMarker(updatedDevice, updatedDevice.last_position, data)
      }
    }
  }

  moveMarker(device, origin, target, current = 0) {
    //console.log('moveMarker', device, current)
    //Update orientation
    if (current === 0) {
      if (device && device.markerRef && device.markerRef.marker) {
        let auxDevice = {
          last_position: target
        }
        var icon = this.createIcon(auxDevice)
        device.markerRef.marker.setIcon(icon)
      }
    }

    if (current >= 10) {
      device.last_position = target
    } else {
      let rect = new Rect(
        { x: origin.long, y: origin.lat },
        { x: target.long, y: target.lat },
      )
      let module = rect.getModule()
      if (module > 0) {
        let steep = rect.getModule() / 10
        let vector = rect.getVectorUnitario()
        vector.x = (vector.x * steep * current)
        vector.y = (vector.y * steep * current)
        let currentPoint = {
          x: rect.punto1.x + vector.x,
          y: rect.punto1.y + vector.y,
        }
        device.last_position = {
          ...target,
          lat: currentPoint.y,
          long: currentPoint.x
        }

        if (device && device.markerRef && device.markerRef.marker) {
          device.markerRef.marker.setPosition({
            lat: device.last_position.lat,
            lng: device.last_position.long
          }
          )

          this.followIfisDeviceSelected(device)
        }

        setTimeout(() => {
          this.moveMarker(device, origin, target, current + 1)
        }, 100);
      }
    }

  }

  followIfisDeviceSelected(device) {
    const { activeMarker } = this.state
    if (activeMarker && activeMarker.device.imei === device.imei) {
      if (this.mapRef) {
        this.mapRef.map.setCenter({
          lat: device.last_position.lat,
          lng: device.last_position.long
        })
      }
    }
  }

  getImagefor(device) {
    //console.log('getImagefor', device)
    if (device && device.last_position && device.last_position.orientation_grade) {
      let grade = device.last_position.orientation_grade
      let range = 45 / 2
      if (grade >= 0 && grade < range) {
        return "/images/icons/car-0.png"
      } else if (grade >= 45 - range && grade < 45 + range) {
        return "/images/icons/car-45.png"
      } else if (grade >= 90 - range && grade < 90 + range) {
        return "/images/icons/car-90.png"
      } else if (grade >= 135 - range && grade < 135 + range) {
        return "/images/icons/car-135.png"
      } else if (grade >= 180 - range && grade < 180 + range) {
        return "/images/icons/car-180.png"
      } else if (grade >= 225 - range && grade < 225 + range) {
        return "/images/icons/car-225.png"
      } else if (grade >= 270 - range && grade < 270 + range) {
        return "/images/icons/car-270.png"
      } else if (grade >= 315 - range && grade < 315 + range) {
        return "/images/icons/car-315.png"
      } else {
        return "/images/icons/car-0.png"
      }
    } else {
      return "/images/icons/car-0.png"
    }

  }

  createIcon(device) {
    return {
      url: this.getImagefor(device),
      anchor: new window.google.maps.Point(25, 25),
      scaledSize: new window.google.maps.Size(25 * 2, 25 * 2)
    }
  }

  render() {

    const triangleCoords = [
      { lat: 25.774, lng: -80.190 },
      { lat: 18.466, lng: -66.118 },
      { lat: 32.321, lng: -64.757 },
      { lat: 25.774, lng: -80.190 }
    ];

    let poligono = (
      <Polygon
        paths={triangleCoords}
        strokeColor="#0000FF"
        strokeOpacity={0.8}
        strokeWeight={2}
        fillColor="#0000FF"
        fillOpacity={0.35} />)

    const coords = { lat: 25.774, lng: -80.190 };


    let circulo = (
      <Circle
        radius={12000}
        center={coords}
        onMouseover={() => console.log('mouseover')}
        onClick={() => console.log('click')}
        onMouseout={() => console.log('mouseout')}
        strokeColor='transparent'
        strokeOpacity={1}
        strokeWeight={5}
        fillColor='#FF0000'
        fillOpacity={0.2}
      />
    )


    const { devices, activeMarker } = this.state
    //console.log('render', this.state)

    let devicesMarkers = []
    let infoOfActiveMarker = null

    if (devices && devices.length > 0) {
      let devicesWithLastPosition = devices.filter(it => it.last_position)
      devicesMarkers = devicesWithLastPosition.map(device => {
        var icon = this.createIcon(device)
        return (
          <Marker
            ref={ref => device.markerRef = ref}
            title={device.imei}
            name={device.imei}
            position={{ lat: device.last_position.lat, lng: device.last_position.long }}
            onClick={(props, marker) => this.onMarkerClick(device, marker)}
            icon={icon}
          />
        )
      })
    }

    if (activeMarker) {
      let device = activeMarker.device
      let marker = activeMarker.marker
      if (marker) {
        infoOfActiveMarker = (
          <InfoWindow
            visible={this.state.showingInfoWindow}
            marker={marker}
            onClose={() => this.setState({ showingInfoWindow: false })
            }>
            <div>
              <span className="mb-1 d-block"><strong>Nombre:</strong> {device.name} </span>
              <span className="mb-1 d-block"><strong>IMEI:</strong> {device.imei} </span>
              <span className="mb-1 d-block"><strong>Estado:</strong> {(device.last_position.movement) ? 'En movimiento' : 'Estacionado'} </span>
              <span className="mb-1 d-block"><strong>Velocidad:</strong> {device.last_position.speed} km/h</span>
              <span className="mb-1 d-block"><strong>Bateria:</strong> {device.last_position.batery_status}%</span>
              <span className="mb-1 d-block"><strong>Latitud:</strong> {device.last_position.lat}</span>
              <span className="mb-1 d-block"><strong>Longitud:</strong> {device.last_position.long}</span>
              <span className="mb-1 d-block"><strong>Ultima actualización:</strong> {moment(device.last_position.date, "DDMMYY").format('ll')}</span>
              <span className="mb-1 d-block"><strong>Hora:</strong> {device.last_position.time}</span>
            </div>
          </InfoWindow>
        )
      }
    }

    return (
      <React.Fragment>
        <Container fluid style={{ padding: 0, position: 'relative' }}>
          <div className="float-device-button">
            <Button color="primary" onClick={() => this.toggleDeviceList()} style={{ marginBottom: '1rem' }}>Dispositivos</Button>
            {/* <Button color="primary" onClick={() => this.updateDevicesInfo({
              batery_status: 73,
              date: "150121",
              direction: "undefined",
              geoloc_at: "2021-01-15T06:11:16.000Z",
              imei: "745236214589654",
              lat: 20.3908239 + (Math.random()/100),
              long: -100.0576857,
              movement: false,
              name: "LT03 Culiacan",
              orientation: "South Latitude and South Longitude",
              orientation_grade: 215,
              speed: 0,
              speedgps: "0 Km/h",
              time: "06:11:16"
            })} style={{ marginBottom: '1rem' }}>Test</Button> */}

            <Collapse isOpen={this.state.deviceListOpen}>
              <Card>
                <CardBody>
                  <div className="mh-400 of-y-scroll">
                    <h5 className="mb-3">Dispositivos conectados</h5>
                    <Nav pills className="navtab-bg nav-justified mb-2">
                      <NavItem className="cursor-pointer">
                        <NavLink
                          className={classnames({
                            active: this.state.activeTab1 === "active"
                          })}
                          onClick={() => {
                            this.toggle1("active");
                          }}
                        >
                          Activos
                        </NavLink>
                      </NavItem>
                      <NavItem className="cursor-pointer">
                        <NavLink
                          className={classnames({
                            active: this.state.activeTab1 === "inactive"
                          })}
                          onClick={() => {
                            this.toggle1("inactive");
                          }}
                        >
                          Inactivos
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent activeTab={this.state.activeTab1}>
                      <TabPane tabId="active" className="p-3">
                        <Row>
                          <Col sm="12">
                            <CardText>
                              {this.state.devices.length <= 0 &&
                                <Alert color="secondary">
                                  Sin dispositivos activos en este momento
                              </Alert>
                              }
                              {this.state.devices.map(marker =>
                                <Alert color={this.handleMovementStatus(marker.last_position?.movement)}
                                  key={marker._id}
                                  className="cursor-pointer"
                                  onClick={() => this.onMarkerClick(marker)}>
                                  <span className="mb-1 d-block"><strong>Nombre:</strong> {marker.name}</span>
                                  <span className="mb-1 d-block"><strong>Imei:</strong> {marker.imei}</span>
                                  <span className="mb-1 d-block"><strong>Velocidad:</strong> {marker.last_position ? `${marker.last_position.speed} km/h` : 'desconocido'}</span>
                                  <span className="mb-1 d-block"><strong>Batería:</strong> {marker.last_position ? `${marker.last_position.batery_status} %` : 'desconocido'}</span>
                                </Alert>
                              )}
                            </CardText>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="inactive" className="p-3">
                        <Row>
                          <Col sm="12">
                            <CardText>
                              {this.state.devicesOff.length <= 0 &&
                                <Alert color="secondary">
                                  Sin dispositivos inactivos en este momento
                              </Alert>
                              }
                              {this.state.devicesOff.map(marker =>
                                <Alert color="dark" key={marker._id}>
                                  <strong>Imei:</strong> {marker.imei}
                                </Alert>
                              )}
                            </CardText>
                          </Col>
                        </Row>
                      </TabPane>
                    </TabContent>
                  </div>
                </CardBody>
              </Card>
            </Collapse>
          </div>
          {/* <div className="mt-4"> */}
          {/* <Row> */}
          {/* <Col xs={12} md={9}>
                <Card style={{ height: "550px"}}>
                  <CardBody > */}
          {/* <div
                      id="gmaps-markers"
                      className="gmaps"
                    > */}
          <div className="map-container">
            <Map
              google={this.props.google}
              ref={ref => this.mapRef = ref}
              // className="gmaps"
              style={{ width: "100%", height: "100%" }}
              zoom={this.state.mapZoom}
              initialCenter={{
                lat: initialCenterMap.lat,
                lng: initialCenterMap.lng,
              }}
              // mapType = "satellite"              
              mapTypeControl={true}
              zoomControl={true}
              onClick={() => activeMarker ? this.setState({ activeMarker: null }) : null}
              {...(activeMarker ? {
                center: {
                  lat: activeMarker.device.last_position.lat,
                  lng: activeMarker.device.last_position.long,
                }
              } : {})}

            >

              {
                devicesMarkers
              }

              {
                infoOfActiveMarker
              }
              
            </Map>

          </div>

          <SweetAlert
            show={this.state.showErrCreateAlert}
            warning
            title="Advertencia!"
            onConfirm={() => this.setState({ showErrCreateAlert: false })}
          >
            Su sesión no pudo ser validada, favor de salir y volver a acceder al sistema.
          </SweetAlert>
        </Container>




        {/* </div> */}
        {/* </CardBody>
              </Card>
            </Col> */}
        {/* <Col xs={12} md={3}>
              <Card className="of-y-scroll" style={{ height: "550px"}}>
                <CardBody>
                  <div>
                    <h5 className="mb-3">Dispositivos conectados</h5>

                    <Nav pills className="navtab-bg nav-justified mb-2">
                      <NavItem className="cursor-pointer">
                        <NavLink
                          className={classnames({
                            active: this.state.activeTab1 === "active"
                          })}
                          onClick={() => {
                            this.toggle1("active");
                          }}
                        >
                          Activos
                        </NavLink>
                      </NavItem>
                      <NavItem className="cursor-pointer">
                        <NavLink
                          className={classnames({
                            active: this.state.activeTab1 === "inactive"
                          })}
                          onClick={() => {
                            this.toggle1("inactive");
                          }}
                        >
                          Inactivos
                        </NavLink>
                      </NavItem>
                    </Nav>

                    <TabContent activeTab={this.state.activeTab1}>
                      <TabPane tabId="active" className="p-3">
                        <Row>
                          <Col sm="12">
                            <CardText>
                            {this.state.devices.length <= 0 &&
                              <Alert color="secondary">
                                Sin dispositivos activos en este momento
                              </Alert>
                            }
                            {this.state.devices.map( marker =>
                              <Alert color={this.handleMovementStatus(marker.last_position?.movement)}
                                     key={marker._id}
                                     className="cursor-pointer"
                                     onClick={() => this.onMarkerClick(marker)}>
                                <span className="mb-1 d-block"><strong>Nombre:</strong> {marker.name}</span>
                                <span className="mb-1 d-block"><strong>Imei:</strong> {marker.imei}</span>
                                <span className="mb-1 d-block"><strong>Velocidad:</strong> {marker.last_position ? `${marker.last_position.speed} km/h` : 'desconocido'}</span>
                                <span className="mb-1 d-block"><strong>Batería:</strong> {marker.last_position ? `${marker.last_position.batery_status} %` : 'desconocido'}</span>
                              </Alert>
                            )}
                            </CardText>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="inactive" className="p-3">
                        <Row>
                          <Col sm="12">
                            <CardText>
                            {this.state.devicesOff.length <= 0 &&
                              <Alert color="secondary">
                                Sin dispositivos inactivos en este momento
                              </Alert>
                            }
                            {this.state.devicesOff.map( marker => 
                              <Alert color="dark" key={marker._id}>
                                <strong>Imei:</strong> {marker.imei}
                              </Alert>
                            )}
                            </CardText>
                          </Col>
                        </Row>
                      </TabPane>
                    </TabContent>
                  </div>
                </CardBody>
              </Card>
            </Col> */}
        {/* </Row> */}
        {/* </div> */}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  login: state.Login
});

export default connect(
  mapStateToProps,
  {}
)(
  GoogleApiWrapper({
    apiKey: "AIzaSyALmiZ2_qsRRMM8UvWaSwot493jLImGrEw",
    LoadingContainer: LoadingContainer,
    v: "3"
  })(GpsMap)
);
