import React from "react";
import { Link } from "react-router-dom";

export default function qashopmainnav(props) {
  const baseURL= props.url;
  return (
    <li>
      <Link to="/#" className="has-arrow waves-effect">
      <i><span className="iconify" data-icon="entypo:help" data-inline="false"></span></i>
        <span>Tutoriales</span>
      </Link>
      <ul className="sub-menu" aria-expanded="false">
        {props.cadi ? (
          <li>
            <a href="https://www.cadiapp.com/tutoriales">Tutoriales Cadi</a>
          </li>
        ) : (
          ""
        )}
        {props.whatsapp ? (
          <li>
            <a href="https://wp.miwhats.com/tutoriales">Tutoriales MiWhats</a>
          </li>
        ) : (
          ""
        )}
        <li>
          <Link to="/#" className="has-arrow waves-effect">
            <i className="ti-shopping-cart"></i>
            <span>Primeros Pasos</span>
          </Link>
          <ul className="sub-menu" aria-expanded="false">
            <li>
              <a href="https://drive.google.com/open?id=1pBs4RH-yfoWAWcsSY2nTxiYqvPGHgccq4a09cN1aZ8k">
                Administración de Sucursales / Regiones
              </a>
            </li>
            <li>
              <a href="https://drive.google.com/open?id=1fAGzZJNTlsK7-B9U4cUsWjVRnGv1a3nLCDBbAv8_Fm8">
                Alta de Colaboradores
              </a>
            </li>
          </ul>
        </li>
        {props.appointments ? (
          <li>
            <Link to="/#" className="has-arrow waves-effect">
              <i className="ti-shopping-cart"></i>
              <span>Módulo Citas</span>
            </Link>
            <ul className="sub-menu" aria-expanded="false">
              <li>
                <a href="https://docs.google.com/document/d/1K5d3OQufem87fSiOs4pKj6ZGfjWlmhdZWJWXD1w3K-Q/edit?usp=sharing">
                  Tutorial de Citas
                </a>
              </li>
            </ul>
          </li>
        ) : (
          ""
        )}
         {props.transport ? (
          <li>
            <Link to="/#" className="has-arrow waves-effect">
              <i className="ti-shopping-cart"></i>
              <span>Módulo Transportes</span>
            </Link>
            <ul className="sub-menu" aria-expanded="false">
              <li>
                <a href="https://drive.google.com/open?id=1JIkFsp1yVsKiSbfp3aDAjhW3M6beO0cVwFTYVf6vo-4">
                Tutorial de Transportes
                </a>
              </li>
            </ul>
          </li>
        ) : (
          ""
        )}
        {props.shop ? (
          <li>
            <Link to="/#" className="has-arrow waves-effect">
              <i className="ti-shopping-cart"></i>
              <span>Módulo Ventas</span>
            </Link>
            <ul className="sub-menu" aria-expanded="false">
              <li>
                <a href="https://docs.google.com/document/d/1fcKqUyUzgY4l4EARCKzQLh0X6DneoifH3lZU7KxSYdA/edit?usp=sharing">
                Tutorial de Ventas
                </a>
              </li>
            </ul>
          </li>
        ) : (
          ""
        )}
        {props.bi ? (
          <li>
            <Link to="/#" className="has-arrow waves-effect">
              <i className="ti-shopping-cart"></i>
              <span>Módulo Ventas</span>
            </Link>
            <ul className="sub-menu" aria-expanded="false">
              <li>
                <a href="https://docs.google.com/document/d/1fcKqUyUzgY4l4EARCKzQLh0X6DneoifH3lZU7KxSYdA/edit?usp=sharing">
                Tutorial de Ventas
                </a>
              </li>
            </ul>
          </li>
        ) : (
          ""
        )}
        {props.credit ? (
          <li>
            <Link to="/#" className="has-arrow waves-effect">
              <i className="ti-shopping-cart"></i>
              <span>Módulo Crédito</span>
            </Link>
            <ul className="sub-menu" aria-expanded="false">
              <li>
                <a href="https://docs.google.com/document/d/1rXpHP_ODBgjo-4BwFACJDH4xdXJIRh5n1fF7mPRQ-f0/edit?usp=sharing">
                Tutorial de Créditos
                </a>
              </li>
            </ul>
          </li>
        ) : (
          ""
        )}
        {props.inventory ? (
          <li>
            <Link to="/#" className="has-arrow waves-effect">
              <i><span className="iconify" data-icon="bx:bx-cube" data-inline="false"></span></i>
              <span>Módulo Inventarios</span>
            </Link>
            <ul className="sub-menu" aria-expanded="false">
              <li>
                <a href="https://docs.google.com/document/d/1DTgD7sEVBOjYzQJEjIqqgygeXmKeiejesqKtTbj-U0I/edit?usp=sharing">
                Tutorial de Inventarios
                </a>
              </li>
            </ul>
          </li>
        ) : (
          ""
        )}
         {props.inventory || props.shop ? (
          <li>
            <Link to="/#" className="has-arrow waves-effect">
              <i className="ti-shopping-cart"></i>
              <span>Módulo Catálogo</span>
            </Link>
            <ul className="sub-menu" aria-expanded="false">
              <li>
                <a href="https://docs.google.com/document/d/1Ip9u0gTU1mR5Ueqci_Snyt1buXmBCVHBzbN_ANe-Mag/edit?usp=sharing">
                Tutorial de Catálogos
                </a>
              </li>
            </ul>
          </li>
        ) : (
          ""
        )}
        {props.email_hs ? (
          <li>
            <Link to="/#" className="has-arrow waves-effect">
              <i className="ti-shopping-cart"></i>
              <span>Config de Correos</span>
            </Link>
            <ul className="sub-menu" aria-expanded="false">
              <li>
                <a href={baseURL+"/web/help/email/android/"}>
                Android
                </a>
              </li>
              <li>
                <a href={baseURL+"/web/help/email/ios/"}>
                iOS
                </a>
              </li>
              <li>
                <a href={baseURL+"/web/help/email/outlook/"}>
                Outlook
                </a>
              </li>
              <li>
                <a href={baseURL+"/web/help/email/web/"}>
                Web
                </a>
              </li>
            </ul>
          </li>
        ) : (
          ""
        )}
          {props.inventory ? (
 
              <li>
                <a href={baseURL+"/web/help/email/web/commons/cms/tours/"}>
                Tours
                </a>
              </li>
          
        ) : (
          ""
        )}
      </ul>
    </li>
  );
}
