import { DragDropContext, Droppable } from "react-beautiful-dnd";
import React, { useState } from "react";
import reorder, { reorderQuoteMap } from "./reorder";

import Column from "./Column";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  taskboard: {
    overflowX: "auto",
    overflowY: "hidden",
    whiteSpace: "nowrap",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 56px)",
    },
    [theme.breakpoints.up("sm")]: {
      height: "calc(100vh - 64px)",
    },
  },
}));

const Taskboard = (props) => {
  const classes = useStyles();
  const {columns, onDragEnd, processing, readOnly} = props

  let loadIndicator = null
  if (processing) {
    loadIndicator = (      
      <div style={{position: "absolute", left:'50%', top:'25%', zIndex:10}}>
        <div className="spinner-border text-secondary m-1" role="status">
            <span className="sr-only">Loading...</span>
        </div>
      </div>
    )
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}> 
      {
        loadIndicator
      }     
      <Droppable droppableId="taskboard" type="COLUMN">
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className={classes.taskboard}
          >
            {columns.map((column, index) => (
              <Column
                key={column.id}
                draggableId={`${column.slug}`}
                index={index}
                title={column.name}
                tasks={column.tasks}
                type={props.type}
                isDropDisabled={readOnly}
              />
            ))}
          </div>
        )}
      </Droppable>      
    </DragDropContext>
  );
};

export default Taskboard;
