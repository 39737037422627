import React, { Component } from 'react'

import { Row, Col, Card, CardBody, CardFooter, FormGroup, Button, Label } from 'reactstrap';
import { AvForm, AvField } from "availity-reactstrap-validation";

export default class ClientSelecter extends Component {

    constructor(props) {
        super(props)
        this.state = {
            clientSelected: null,
            zipCode: '',
            frecuencyQuotes: null
        }
    }

    onClientChange(e) {
        console.log('onClientChange(e)', e.target.value)

        const {clients, onClientChange, onZipCodeChange} = this.props
        if (clients ){
            const clientSelected = clients.find(it => it.id == e.target.value)            
            this.setState({
                clientSelected,
                zipCode: clientSelected.address.zip_code ? clientSelected.address.zip_code : ''
            })
            if (onClientChange){
                onClientChange(clientSelected)
            }

            if (onZipCodeChange){
                onZipCodeChange(clientSelected.address.zip_code ? clientSelected.address.zip_code : '')
            }
        }
    }

    onZipCodeChange(zipCode) {
        const {onZipCodeChange} = this.props
        this.setState({
            zipCode
        }, () => onZipCodeChange(zipCode))
    }

    render() {
        const {title, clients} = this.props
        const {clientSelected, zipCode} = this.state
        let fullAdrress= ''
        if (clientSelected) {
            const address = clientSelected.address
            const {street, numext, zone_text, state_text, country_text, zip_code} = address
            fullAdrress = `${street} ${numext ? 'No. '+ numext: ''}, ${zone_text}, ${state_text}, ${country_text} ${zip_code}`
        }
        return (
            <Col lg={6}>
                <Card>
                    <CardBody>
                        <Label>{title}</Label>
                        <AvForm>                                          
                            <AvField type="select" name="select" label="Cliente:" helpMessage={title} onChange={(e) => this.onClientChange(e)}>
                                <option>Selecciona</option>
                                {
                                    clients.map(it => (
                                        <option value={it.id}>{it.name}</option>
                                    ))
                                }                                
                            </AvField>                                                  

                            <AvField
                                name="zipCode"
                                label="Codigo Postal:"                                    
                                type="number" 
                                value={zipCode}  
                                onChange={e => this.onZipCodeChange(e.target.value)}                     
                            />
                            <Label>Direccion:</Label>
                            <Label>{fullAdrress}</Label>
                        </AvForm>
                    </CardBody>
                </Card>
            </Col>
        )
    }
}