import React from "react";
import { Link } from "react-router-dom";

export default function qaauditmainnav(props) {
  const baseURL= props.url;
  return (
    <li>
      <Link to="/#" className="has-arrow waves-effect">
        <i style={{backgroundColor:'transparent'}}><span className="iconify" data-icon="ant-design:share-alt-outlined" data-inline="false"></span></i>
        <span>Encuestas / Checklists</span>
      </Link>
      <ul className="sub-menu" aria-expanded="false">
        <li>
          <a href={baseURL+"/hr/dashboard/"}>
          Dashboard
          </a>
        </li>
        <li>
          <a href={baseURL+"/audit/cms/surveys/"}>
          Formatos
          </a>
        </li>
        <li>
          <a href={baseURL+"/audit/cms/survey-responses/"}>
          Respuestas
          </a>
        </li>
        <li>
          <a href={baseURL+"/audit/cms/indicators/"}>
          Indicadores
          </a>
        </li>
        <li>
          <a href={baseURL+"/audit/cms/survey-reports-configs/"}>
          Dashboard Config
          </a>
        </li>
        <li>
          <a href={baseURL+"/audit/cms/campaigns/"}>
          Campañas
          </a>
        </li>
      </ul>
    </li>
  );
}
