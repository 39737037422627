import React, { useState, useEffect, useLayoutEffect } from 'react';
import { Row, Col, Card, CardBody, CardColumns, CardHeader, CardImg, CardTitle, CardSubtitle, CardFooter } from 'reactstrap';

import { Link, Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';

import SelectSearchList from './selectSearchList';
import CatVarAttrs from './CatVarAttrs';

import { getCategoryVariants, getVariants, getVariantsByParam, postCategoryVariants, deleteRelCatVariants, getCategoryVariantAttributes } from '../../../api/api';

const VariantAttrRender = ({
  selVariants,
  asVariants,
  loginDetails,
  catID,
  catVarAttrs
}) => {
  return (
    <>
      {
        selVariants.map((x, i) => {
          return (
            <Col key={x.name + i}>
              <Card>
                <span className="title mr-auto mt-3 mb-2 ml-4 font-size-14 font-weight-medium">
                  Variante: {x.name}
                </span>
                <CardBody className="px-4">
                  <CatVarAttrs
                    loginDetails={ loginDetails }
                    asVariants={ asVariants }
                    attrs={ x.variant_attribute_set }
                    variant={ x }
                    catID={ catID }
                    catAttrs={ catVarAttrs }
                  />
                </CardBody>
              </Card>
            </Col>
          )
        })
      }
    </>
  )
}

function getAndSetVariants(setter) {
  getVariants().then((res) => {
    if (res.data.pages == 1) setter(res.data.results)
    else if (res.data.pages > 1) setter(res.data.results)
    else setter([])
  }).catch((err) => {
    console.log('---------');
    console.log(err);
    console.log('---------');
  });
}

function getASVariants(categoryID, setter) {
  getCategoryVariants(categoryID).then((res) => {
    if (res.data && Array.isArray(res.data)) {
      // console.log(res.data, Array.isArray(res.data));
      let arr = []
      res.data.map(x => {
        if (x.variant && !arr.filter(y => y.id === x.variant.id)[ 0 ]) {
          arr.push({
            ...x.variant, relation: x.id
          })
        }
      })
      setter(arr)
    } else if (res.data && res.data.results) {
      // console.log(res.data.results);
      let arr = []
      res.data.results.map(x => {
        if (x && !arr.filter(y => y.id === x.id)[ 0 ]) {
          arr.push({
            ...x
          })
        }
      })
      setter(arr)
    } else if (!res.data || !res.data[ 0 ]) setter([])
  }).catch((err) => {
    console.log(err);
  });
}

const updateCatVariants = (
  categoryId = '123',
  variantIds = [],
  loginDetails = {},
  reply = true,
  asArr = [],
  setter = (e) => console.log(e)
) => new Promise(async (resolve, reject) => {
  let a = []
  if (variantIds && variantIds.length > 0) variantIds.map((x, i) => {
    if (!asArr.find(y => y.id === x.id)) a.push({
      category_id: categoryId,
      variant_id: x.id,
      reply_to_parents: x.reply,
      public: true,
      ...x
    })
  })
  /* console.log(a, variantIds); */
  if (loginDetails && a && a.length > 0) {
    await postCategoryVariants({
      categoryId, variantIds: a, loginDetails, reply
    }).then(resp => {
      console.log(resp);
      if (Array.isArray(resp.data)) {
        // console.log(resp.data);
        let arr = []
        resp.data.map(x => {
          if (x.variant && !arr.filter(y => y.id === x.variant.id)[ 0 ]) {
            if (x.id) arr.push({
              ...x.variant, relation: x.id
            })
          } else {
            let variant = a.find(h => h.order === x.order)
            arr.push({
              ...variant, relation: x.id
            })
          }
        })
        setter([...asArr, ...arr])
      } else if (!resp.data || !resp.data[ 0 ]) setter([])
    }).catch(err => {
      console.log(err);
      reject()
    })
    resolve()
  }
  else reject()
})

const delRelCatVariants = ({
  categoryId = '',
  variantId = 123,
  loginDetails = {},
  reply = true,
  setter = () => console.log('hi'),
}) => new Promise((resolve, reject) => {
  /* let a = []
  if (variantIds && variantIds.length > 0) variantIds.map((x) => {
    a.push(x.id)
  })
  console.log(a, a.join(','));
  console.log(loginDetails); */
  deleteRelCatVariants({
    categoryId, variantId, loginDetails, reply
  }).then((res) => {
    // console.log(res);
    if (!res) reject()
    else resolve()
  }).catch((err) => {
    console.log('---------');
    console.log(err);
    console.log('---------');
    reject()
  });
})

const searchByParam = (name, categoryId) => new Promise(async (resolve) => {
  resolve(await getVariantsByParam({
    term: name, categoryId
  }))
})

export default function CatVariants({ props = {}, bindState = {}}) {
  // console.log(props, bindState);
  const [ categoryID, setcategoryID ] = useState(bindState && bindState.categoryID ? bindState.categoryID : props.match.params && props.match.params.categoryId ? props.match.params.categoryId : undefined)
  const [Variants, setVariants] = useState([])
  const [ASVariants, setASVariants] = useState([])
  const [selVariants, setselVariants] = useState([])
  const [ deselVariants, setdeselVariants ] = useState([])
  const [ reply, setReply ] = useState(false)

  const [ catVarAttrs, setcatVarAttrs ] = useState([])

  const loginDetails = props.login

  useLayoutEffect(() => {
    // if (Variants.length == 0) getAndSetVariants(setVariants)
    if (categoryID) getASVariants(categoryID, setASVariants)
    if (categoryID) getCategoryVariantAttributes(categoryID).then((res) => {
      setcatVarAttrs(res.data)
    }).catch((err) => {
      console.log(err);
    });
  }, [categoryID])

  useEffect(() => {
    setcategoryID(bindState && bindState.categoryID ? bindState.categoryID : props.match.params && props.match.params.categoryId ? props.match.params.categoryId : undefined)
    // console.log(categoryID);
  }, [ props ])

  useEffect(() => {
    setdeselVariants([])
    if (ASVariants && ASVariants.length > 0) {
      let arr = []
      ASVariants.map((x, i) => {
        if (!selVariants.find(z => z.id === x.id)) {
          arr.push(x)
        }
      })
      setdeselVariants(arr)
    }
  }, [ selVariants ])

  useEffect(() => {
    if (ASVariants && ASVariants.length > 0 && selVariants !== ASVariants) {
      let arr = []
      ASVariants.map(x => {
        if (x.variant && !selVariants.find(z => z.id === x.variant.id)) {
          if (x.variant) arr.push(x.variant)
          else arr.push(x)
        }
      })
      setselVariants(arr)
      // console.log('sel vars setted');
    }
  }, [ASVariants])

  useEffect(() => {
    if (deselVariants && deselVariants.length > 0 && deselVariants !== ASVariants) deselVariants.map(z => {
      // console.log(z);
      if (z.id && !selVariants.find(k => k.id === z.id)) delRelCatVariants({
        categoryId: categoryID,
        variantId: z.relation ? z.relation : z.id,
        loginDetails,
        reply,
      }).then((res) => {
        setASVariants([...ASVariants.filter(e => e.id !== z.id)])
      }).catch((err) => {
        console.log(err);
      });
    })
  }, [ deselVariants ])

  // console.log(Variants, ASVariants, selVariants, deselVariants);

  return (
    <div className="container-fluid" id="cat_config_container" >
      <Row >
        <Col sm='12' md='12' lg='12' xl='12' >
          <Card>
            <CardHeader className="d-flex">
              <span className="title mr-auto ml-2">
                Variantes disponibles
              </span>
            </CardHeader>
            <CardBody>
              <div>
                <span className="py-2 mb-2 d-flex" style={ { flexFlow: 'row' } } >
                  Arrastrar para especificar el orden de aparici&oacute;n
                </span>

                <SelectSearchList
                  loginDetails={ loginDetails }
                  asArr={ ASVariants }
                  setasArr={ setASVariants }
                  deselArr={ deselVariants }
                  selArr={ selVariants }
                  setselArr={ setselVariants }
                  reply={ reply }
                  setReply={ setReply }
                  searchFunc={ searchByParam }
                  categoryId={ categoryID }
                  saveFunc={ updateCatVariants }
                  deleteFunc={ delRelCatVariants }
                  placeholder="Selecciona una Variante" />
              </div>
            </CardBody>
          </Card>
        </Col>
        <VariantAttrRender
          selVariants={ selVariants }
          asVariants={ ASVariants }
          catVarAttrs={ catVarAttrs }
          loginDetails={ loginDetails }
          catID={categoryID}
        />
      </Row>
    </div>
  )
}