import React from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';

const Table = ({ mainTitle, tableTitles, children }) => (
  <Row>
    <Col>
      <Card>
        <CardBody>
          {mainTitle && (
            <h4 className='card-title mb-4'>{mainTitle}</h4>
          )}
          <div className='table-responsive'>
            <table className='table table-hover table-centered table-nowrap mb-0'>
              <thead>
                <tr>
                  {tableTitles.map((title, index) => (
                    <th key={`table-title-${Math.random()}`} scope='col'>{title}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {children}
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>
    </Col>
  </Row>
);

export default Table;
