import React, { Component } from "react";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  FormGroup,
  Button,
  Label,
  CustomInput,
  Alert,
  Input,
  Spinner,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

import { connect } from "react-redux";
import { toast } from "react-toastify";
import accounting from "accounting";
import {
  getDeliveryTypes,
  getFrecuencyQuote,
  getPackageServiceConfig,
  getServiceTypes,
} from "../../../api/api";

const PACKAGE_SLUG = "package";
const ENVELOPE_SLUG = "envelope";

class CotizadorPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deliveryTypes: [],
      serviceTypes: [],
      packageServiceConfigs: [],
      ocurre: false,
      frecuencyQuotesAdditionals: [],
      procesing: false,
    };
  }

  componentDidMount() {
    this.fetchDeliveryTypes();
    this.fetchPackageServiceConfig();
    this.fetchServiceTypes();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.zipCodeSource !== prevProps.zipCodeSource ||
      this.props.zipCodeTarget !== prevProps.zipCodeTarget
    ) {
      this.clearFrecuencyQuotes();
    }
  }

  fetchDeliveryTypes() {
    const { login } = this.props;
    if (login) {
      getDeliveryTypes(login)
        .then((result) => {
          if (result.status == 200) {
            this.setState({ deliveryTypes: result.data });
          }
        })
        .catch((error) => console.error("Error", error));
    }
  }

  fetchPackageServiceConfig() {
    const { login } = this.props;
    if (login) {
      getPackageServiceConfig(login)
        .then((result) => {
          if (result.status == 200) {
            let packageServiceConfigs = result.data.filter(
              (it) => it.service.active
            );
            //packageServiceConfigs.sort((a,b) => (b.max_guides - b.generated_guides) - (a.max_guides - a.generated_guides))
            packageServiceConfigs.sort(
              (a, b) => b.available_guides - a.available_guides
            );
            this.setState({ packageServiceConfigs });
          }
        })
        .catch((error) => console.error("Error", error));
    }
  }

  fetchServiceTypes() {
    const { login } = this.props;
    if (login) {
      getServiceTypes(login)
        .then((result) => {
          if (result.status == 200) {
            this.setState({ serviceTypes: result.data });
          }
        })
        .catch((error) => console.error("Error", error));
    }
  }

  render() {
    //console.log("render", this.state);
    const {
      frecuencyQuotes,
      deliveryTypes,
      showPackageFields,
      frecuencyQuotesAdditionals,
    } = this.state;

    const cotizador = (
      <Col md={12}>
        <AvForm>
          <Row>
            <Col md="6">
              <AvField
                type="select"
                name="select"
                label="Tipo:"
                helpMessage="Tipo de paquete"
                onChange={(e) => this.onTypePackageSelected(e)}
              >
                <option>Selecciona</option>
                {deliveryTypes.map((it, index) => (
                  <option key={index} value={it.id}>
                    {it.name}
                  </option>
                ))}
              </AvField>
            </Col>
          </Row>
          {showPackageFields && (
            <Row>
              <Col md="3">
                <FormGroup>
                  <AvField
                    name="weight"
                    label="Peso (kg)"
                    type="number"
                    errorMessage="Introduzca un valor correcto - debe estar entre 1 y 70 kgs"
                    validate={{
                      required: { value: true },
                      pattern: {
                        value: "^[0-9]+$",
                        errorMessage: "Only Numbers",
                      },
                    }}
                    onChange={(e) =>
                      this.setState(
                        {
                          weight: e.target.value,
                        },
                        () => this.clearFrecuencyQuotes()
                      )
                    }
                  />
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <AvField
                    name="lenght"
                    label="Largo (cm)"
                    type="number"
                    errorMessage="Introduzca un valor correcto - debe estar entre 1 y 1000 cms"
                    validate={{
                      required: { value: true },
                      pattern: {
                        value: "^[0-9]+$",
                        errorMessage: "Only Numbers",
                      },
                    }}
                    onChange={(e) =>
                      this.setState(
                        {
                          length: e.target.value,
                        },
                        () => this.clearFrecuencyQuotes()
                      )
                    }
                  />
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <AvField
                    name="height"
                    label="Alto (cms)"
                    type="number"
                    errorMessage="Introduzca un valor correcto - debe estar entre 1 y 1000 cms"
                    validate={{
                      required: { value: true },
                      pattern: {
                        value: "^[0-9]+$",
                        errorMessage: "Only Numbers",
                      },
                    }}
                    onChange={(e) =>
                      this.setState(
                        {
                          height: e.target.value,
                        },
                        () => this.clearFrecuencyQuotes()
                      )
                    }
                  />
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <AvField
                    name="width"
                    label="Ancho (cm)"
                    type="number"
                    errorMessage="Introduzca un valor correcto - debe estar entre 1 y 1000 cms"
                    validate={{
                      required: { value: true },
                      pattern: {
                        value: "^[0-9]+$",
                        errorMessage: "Only Numbers",
                      },
                    }}
                    onChange={(e) =>
                      this.setState(
                        {
                          width: e.target.value,
                        },
                        () => this.clearFrecuencyQuotes()
                      )
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
          )}
        </AvForm>
      </Col>
    );

    let frecuencyQuotesResults = null;
    if (frecuencyQuotes && frecuencyQuotes.TipoServicio) {
      let caracteristicas_costoReexpedicion = `${
        frecuencyQuotes.CostoReexpedicion.toLowerCase() !== "no"
          ? `Reexpedición ($102.5 adicionales)`
          : ""
      }`;
      //  `Reexpedición (${frecuencyQuotes.CostoReexpedicion} adicionales)` : ''}`
      let caracteristicas_modalidadEntrega = `${
        frecuencyQuotes.ModalidadEntrega &&
        frecuencyQuotes.ModalidadEntrega.OcurreForzoso &&
        frecuencyQuotes.ModalidadEntrega.OcurreForzoso.toLowerCase() === "si"
          ? "Ocurre Forzoso"
          : ""
      }`;

      let quoteSelected = this.props.quoteSelected;
      let servicios = frecuencyQuotes.TipoServicio.TipoServicio;
      const quotes = servicios.map((it, index) => {
        return (
          <tr style={{ ...(it.disabled ? { color: "lightgray" } : {}) }}>
            <td>
              <CustomInput
                type="radio"
                id={`${index}`}
                name="customRadio"
                disabled={it.disabled}
                checked={quoteSelected && it === quoteSelected.TipoServicio}
                onChange={(e) =>
                  this.onQuoteSelected(e, {
                    TipoServicio: it,
                    TipoEnvio: frecuencyQuotes.TipoEnvio,
                  })
                }
              />
            </td>
            <td scope="row">{it.DescripcionServicio}</td>
            <td>
              {caracteristicas_costoReexpedicion}
              {caracteristicas_costoReexpedicion && <br />}
              {caracteristicas_modalidadEntrega}
            </td>
            <td>{/* accounting.formatMoney(it.TarifaBase) */} --</td>
            <td>{/* accounting.formatMoney(it.CCTarifaBase) */} -- </td>
            <td>{accounting.formatMoney(it.CargosExtra)}</td>
            <td>{/* accounting.formatMoney(it.CostoTotal * 1.16) */} -- </td>
          </tr>
        );
      });

      const quotesAdditionals = frecuencyQuotesAdditionals.map((it, index) => {
        return (
          <tr
            key={index}
            style={{
              ...(it.disabled ? { color: "lightgray" } : {}),
            }}
          >
            <td>
              <CustomInput
                type="radio"
                id={`${servicios.length + 1 + index}`}
                name="customRadio"
                disabled={it.disabled}
                checked={quoteSelected && it === quoteSelected.TipoServicio}
                onChange={(e) =>
                  this.onQuoteSelected(e, {
                    TipoServicio: it,
                    TipoEnvio: frecuencyQuotes.TipoEnvio,
                  })
                }
              />
            </td>
            <td scope="row">{it.wfPackageServiceType?.service?.name}</td>
            <td>
              {it.disabled && it.message}
              {caracteristicas_costoReexpedicion}
              {caracteristicas_costoReexpedicion && <br />}
              {caracteristicas_modalidadEntrega}
            </td>
            <td>{/* {accounting.formatMoney(it.TarifaBase)} */} -- </td>
            <td>{/* {accounting.formatMoney(it.CCTarifaBase)} */} -- </td>
            <td>{accounting.formatMoney(it.CargosExtra)}</td>
            <td>{/* {accounting.formatMoney(it.CostoTotal * 1.16)} */} -- </td>
          </tr>
        );
      });

      frecuencyQuotesResults = (
        <div className="table-responsive" style={{ marginTop: 10 }}>
          <table className="table table-striped mb-0">
            <thead>
              <tr>
                <th></th>
                <th>Nombre</th>
                <th>Características</th>
                <th>Tarifa base</th>
                <th>Cargo por combustible</th>
                <th>Cargo Extra</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {/* {quotes} */}
              {quotesAdditionals}
            </tbody>
          </table>
        </div>
      );
    } else if (frecuencyQuotes && frecuencyQuotes.MensajeError) {
      frecuencyQuotesResults = (
        <Alert color="warning">{frecuencyQuotes.MensajeError}</Alert>
      );
    }

    return (
      <React.Fragment>
        <Row>
          <Label>Datos del envio:</Label>
        </Row>
        <Row>{cotizador}</Row>
        <Row>
          <FormGroup className="mb-0">
            <div style={{ marginBottom: "8px" }}>
              {this.state.procesing ? (
                <Spinner size="sm" color="info" />
              ) : (
                <Button
                  type="submit"
                  color="info"
                  className="mr-1"
                  onClick={(e) => this.onCotizarPress(e)}
                >
                  Cotizar
                </Button>
              )}{" "}
              <Button
                type="reset"
                color="secondary"
                onClick={() => this.clearFrecuencyQuotes()}
              >
                Cancelar
              </Button>
            </div>
          </FormGroup>
        </Row>
        <Row>{frecuencyQuotesResults}</Row>
        <Row>
          <Col>
            <FormGroup check inline>
              <Label check>
                <Input
                  type="checkbox"
                  checked={this.state.ocurre}
                  disabled={this.state.disabledOcurre}
                  onChange={() => this.onOcurreChange()}
                />{" "}
                <strong>Ocurre</strong> (Deberá poner en destino la dirección de
                la Sucursal Estafeta que desea enviar el paquete)
              </Label>
            </FormGroup>
          </Col>
        </Row>
      </React.Fragment>
    );
  }

  onOcurreChange() {
    let ocurre = !this.state.ocurre;
    this.setState({ ocurre }, () => {
      if (this.props.onOcurreChange) {
        this.props.onOcurreChange(ocurre);
      }
    });
  }

  onSourceZipCodeChange(zip_code) {
    this.setState(
      {
        zipCodeSource: zip_code,
      },
      () => this.clearFrecuencyQuotes()
    );
  }

  onTargetZipCodeChange(zip_code) {
    this.setState(
      {
        zipCodeTarget: zip_code,
      },
      () => this.clearFrecuencyQuotes()
    );
  }

  onTypePackageSelected(e) {
    const typePackage = e.target.value;
    const { deliveryTypes } = this.state;
    const deliveryTypeSelected = deliveryTypes.find(
      (it) => it.id == typePackage
    );
    let showPackageFields = false;
    if (deliveryTypeSelected) {
      if (deliveryTypeSelected.slug === PACKAGE_SLUG) {
        showPackageFields = true;
      }
    }
    this.setState(
      {
        deliveryTypeSelected,
        showPackageFields,
      },
      () => this.clearFrecuencyQuotes()
    );
  }

  onCotizarPress(e) {
    e.preventDefault();
    const { login } = this.props;
    const {
      zipCodeSource,
      zipCodeTarget,
      deliveryTypeSelected,
      length,
      width,
      height,
      weight,
    } = this.state;
    const zipCodeSourceToSend = this.props.zipCodeSource
      ? this.props.zipCodeSource
      : zipCodeSource;
    const zipCodeTargetToSend = this.props.zipCodeTarget
      ? this.props.zipCodeTarget
      : zipCodeTarget;

    if (!zipCodeSourceToSend) {
      toast.warn("Falta el código postal de origen");
      return;
    }

    if (!zipCodeTargetToSend) {
      toast.warn("Falta el código postal destino");
      return;
    }

    if (!deliveryTypeSelected) {
      toast.warn("Faltan seleccionar el tipo de envio.");
      return;
    }

    let isPackage = deliveryTypeSelected.slug === PACKAGE_SLUG;
    if (isPackage && (!length || !width || !height || !weight)) {
      toast.warn("Faltan especificaciones del paquete.");
      return;
    }

    const data = {
      is_package: isPackage,
      pc_src: [zipCodeSourceToSend],
      pc_tgt: [zipCodeTargetToSend],
      length,
      width,
      height,
      weight,
    };

    ////console.log('Cotizar', data)
    this.setState({ procesing: true });
    getFrecuencyQuote(data, login)
      .then((result) => {
        console.log('freq', result)
        if (result.status === 200) {
          let response = result.data[0];

          if (response && response.Error !== "000") {
            ////console.log("frecuencia1", response);
            toast.warn(`${response.Error}: ${response.MensajeError}`);
            this.clearFrecuencyQuotes();
          } else {
            ////console.log("frecuencia2", response);
            this.setAndFilterFrecuencyQuotes(result.data[0]);
          }
        } else {
          ////console.log("frecuencia3");
          toast.error("Ocurrio un problema al cotizar el envio.");
          this.clearFrecuencyQuotes();
        }
        this.setState({ procesing: false });
      })
      .catch((error) => console.error("Error", error));
  }

  searchAditionalQuotes(type, data) {
    if (type) {
      const textSearchMinus = type.toLowerCase();
      const filterData = data.filter(
        (value) => value.DescripcionServicio.toLowerCase() === textSearchMinus
      );
      return filterData;
    }
    return false;
  }

  sleep(milliseconds) {
    var start = new Date().getTime();
    for (var i = 0; i < 1e7; i++) {
      if (new Date().getTime() - start > milliseconds) {
        break;
      }
    }
  }

  setAdditionalFrecuencyQuotes(servicios) {
    this.setState({ frecuencyQuotesAdditionals: [] });
    const { packageServiceConfigs, weight } = this.state;
    const exitAditional = this.searchAditionalQuotes("dia sig.", servicios);
    const exitAditional2 = this.searchAditionalQuotes("Terrestre", servicios);

    if (exitAditional) {
      let aditionl = exitAditional[0];
      let newAditional;
      if (aditionl?.wfPackageServiceType) {
        let { wfPackageServiceType, ...newAdi } = aditionl;
        newAditional = newAdi
      }else{
        newAditional = aditionl;
      }
      
      let otherQuotes = [];
      packageServiceConfigs.forEach((service) => {
        if (service.service.external_name.toLowerCase() === "dia sig.") {
          otherQuotes.push(service);
        }
      });

      //console.log("otherQuotes", otherQuotes);

      if (otherQuotes.length > 0) {
        otherQuotes.forEach((service) => {
          let newService = {
            ...newAditional,
            wfPackageServiceType: service,
            is_additional: true,
            message:
              "No se puede seleccionar este servicio, el peso del paquete excede el límite.",
          };

          this.setState({
            frecuencyQuotesAdditionals: [
              ...this.state.frecuencyQuotesAdditionals,
              newService,
            ],
          });
          //console.log("new", newService);
        });
      }
    }
    if (exitAditional2) {
      let aditionl2 = exitAditional2[0];
      let newAditional2;
      if(aditionl2?.wfPackageServiceType){
      let { wfPackageServiceType, ...newAdd } = aditionl2;
      newAditional2 = newAdd
      }else{
        newAditional2 = aditionl2;
      }
      let otherQuotes2 = [];
      packageServiceConfigs.forEach((service) => {
        if (service.service.external_name.toLowerCase() === "terrestre") {
          otherQuotes2.push(service);
        }
      });

      console.log("otherQuotes", newAditional2);

      if (otherQuotes2.length > 0) {
        otherQuotes2.forEach((service) => {
          let newService = {
            ...newAditional2,
            wfPackageServiceType: service,
            is_additional: true,
            message:
              "No se puede seleccionar este servicio, el peso del paquete excede el límite.",
          };
          this.setState({
            frecuencyQuotesAdditionals: [
              ...this.state.frecuencyQuotesAdditionals,
              newService,
            ],
          });
          //console.log("new2", newService);
        });
      }
    }
    this.statusChangeQuoutes();
  }

  statusChangeQuoutes() {
    const { frecuencyQuotesAdditionals, weight } = this.state;
    let services = [];

    frecuencyQuotesAdditionals.forEach((value) => {
      const active = value.wfPackageServiceType.service.active;
      const credit = value.wfPackageServiceType.service.credit;
      const limit = value.wfPackageServiceType.service.limit;

      if (active && credit) {
        value.disabled = false;
        //console.log('credito', value.disabled, credit );
      }
      if (active && !credit) {
        value.disabled = limit === 0 ? false : weight <= limit ? false : true;
        //console.log('!credito', value.disabled);
      }
      
      services.push(value);
    });
    this.setState({ frecuencyQuotesAdditionals: services });
  }

  setAndFilterFrecuencyQuotes(frecuencyQuotes) {
    const { packageServiceConfigs, serviceTypes } = this.state;
    //Filter valid config service
    //1º Filtra los tipos disponible en WF
    let servicios = frecuencyQuotes.TipoServicio.TipoServicio.filter(
      (it, index) => {
        let servicio = it.DescripcionServicio.toLowerCase();

        let tieneDisponibilidad = serviceTypes.find(
          (it) => it.external_name.toLowerCase() === servicio
        );
        return tieneDisponibilidad;
      }
    );

    //console.log("SERVICIOS FILTER 1", servicios);

    /* let exitAditional = this.searchAditionalQuotes("dia sig.", servicios);

    if (exitAditional) {
        //console.log(packageServiceConfigs)

        packageServiceConfigs.forEach(service => {
            if (service.service.external_name.toLowerCase() === "dia sig." && service.service.name !== "Dia Sig.") {
                let addservice = exitAditional[0];
                addservice.wfPackageServiceType = service
                servicios.push(addservice);
            }
        })

    } */

    //console.log("SERVICIOS FILTER 2", servicios);
    //2º Deshabilita segun disponibilidad
    servicios = servicios.filter((it, index) => {
      let servicio = it.DescripcionServicio.toLowerCase();
      let wfPackageServiceType = packageServiceConfigs.find(
        (it) =>
          it.service.external_name.toLowerCase() === servicio &&
          it.max_guides > it.generated_guides
      );
      it.disabled = !wfPackageServiceType;
      it.wfPackageServiceType = wfPackageServiceType;
      return true;
    });

    //console.log("SERVICIOS FILTER 3", servicios);

    frecuencyQuotes.TipoServicio.TipoServicio = servicios;

    //Check if ocurre
    if (
      frecuencyQuotes.ModalidadEntrega &&
      frecuencyQuotes.ModalidadEntrega.OcurreForzoso &&
      frecuencyQuotes.ModalidadEntrega.OcurreForzoso.toLowerCase() === "si"
    ) {
      this.setState(
        {
          ocurre: true,
          disabledOcurre: true,
        },
        () => {
          if (this.props.onOcurreChange) {
            this.props.onOcurreChange(true);
          }
        }
      );
    }

    this.setState(
      {
        frecuencyQuotes,
      },
      () => this.selectTerrestreQuote()
    );
    this.setAdditionalFrecuencyQuotes(servicios);
  }

  selectTerrestreQuote() {
    const { frecuencyQuotes } = this.state;
    ////console.log("is here", frecuencyQuotes);
    if (frecuencyQuotes) {
      let servicioTerrestre = frecuencyQuotes.TipoServicio.TipoServicio.find(
        (it, index) =>
          it.DescripcionServicio.toLowerCase() === "terrestre" && !it.disabled
      );
      if (servicioTerrestre) {
        this.onQuoteSelected(null, {
          TipoEnvio: frecuencyQuotes.TipoEnvio,
          TipoServicio: servicioTerrestre,
        });
      }
    }
  }

  onQuoteSelected(e, quote) {
    //console.log("onQuoteSelected", quote);
    this.props.onQuoteSelected(quote);
  }

  clearFrecuencyQuotes() {
    this.setState(
      {
        frecuencyQuotes: null,
        disabledOcurre: false,
      },
      () => this.props.onQuoteSelected(null)
    );
  }
}

const mapStateToProps = (state) => {
  return {
    login: state.Login,
  };
};

export default connect(mapStateToProps, null)(CotizadorPage);
