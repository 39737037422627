/* import { infoColor, warningColor } from '../../styleVariables'; */

import { Draggable } from 'react-beautiful-dnd';
import React from 'react';
import TaskList from './TaskList';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  wrapper: {
    width: '280px',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(1) / 2,
    paddingLeft: theme.spacing(1) / 2,
    boxSizing: 'border-box',
    display: 'inline-block',
    verticalAlign: 'top',
    height: '100%'
  },
  list: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '100%',
    whiteSpace: 'normal',
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    borderRadius: theme.shape.borderRadius
  },
  header: {
    position: 'relative',
    flexDirection: 'row',
    alignItems: 'center',
    letterSpacing: '0.02rem',
    padding: theme.spacing(1),
    margin: 0,
    fontWeight: 500,
    textTransform: 'uppercase',
    fontSize: '12px'
  },
  cards: {
    position: 'relative',
    padding: `0 ${theme.spacing(1)}px ${theme.spacing(1)}px`,
    boxSizing: 'border-box',
    height: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',
  }
}));

const Column = ({ title, draggableId, tasks, index, type, isDropDisabled }) => {
  const classes = useStyles();
  return (
    <Draggable draggableId={draggableId} index={index}>
      {(provided, snapshot) => (
        <div className={classes.wrapper} key={index}>
          <div className={classes.list}>
            <Typography className={classes.header}>{title}</Typography>
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              className={classes.cards}
            >
               <TaskList listId={draggableId} tasks={tasks} type={type} isDropDisabled={isDropDisabled}/>
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default Column;
