import React, { useState, useEffect, useLayoutEffect } from 'react';
import { CardTitle, Form, FormGroup, Button } from 'reactstrap';
import MultiSelectSort from './selectorMulti';

export default function SelectSeacrhList({
  asArr,
  setasArr,
  selArr,
  setselArr,
  deselArr,
  searchFunc,
  reply,
  setReply,
  categoryId,
  type,
  deleteFunc,
  saveFunc,
  loginDetails,
  placeholder,
  typoLimit = 3
}) {
  const idReply = `reply_to_parent-${type ? type : Math.round(Math.random() * 100)}`

  return (
    <Form onSubmit={async (e) => {
      e.preventDefault()
      await saveFunc(categoryId, selArr, loginDetails, reply, asArr, setasArr)
    }}>
      <FormGroup>
        <MultiSelectSort
          defaultArr={ asArr }
          values={ asArr }
          valueSet={ setselArr }
          searchFunc={ searchFunc }
          catId={ categoryId }
          removeFunc={ (e) => console.log(e) }
          placeholder={ placeholder }
          typoLimit={typoLimit || 3}
        />

        <div className="action-holder d-flex flex-flow" style={ { width: '100%', height: '56px', gap: '16px', justifyContent: 'flex-end', alignItems: 'center' } }>
          <div className="mr-auto ml-2 form-check form-check-inline" >
            <input id={idReply} type="checkbox" value={ reply } onChange={ (e) => setReply(e.target.value) } placeholder="Replicar a categorias padre" className="my-2 mx-2 text-primary form-check-input" />
            <label htmlFor={idReply} className="form-check-label" >
              Replicar a categor&iacute;as padre
            </label>
          </div>
          <Button onClick={ () => {
            // console.log(asArr)
            setasArr(asArr)
            setselArr(asArr)
          }} type="reset" disabled={asArr === selArr ? true : false}>
            Limpiar
          </Button>
          <Button type="submit" disabled={asArr === selArr ? true : false} >
            Guardar
          </Button>
        </div>
      </FormGroup>
    </Form>
  )
}