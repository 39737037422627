import React, { Component } from 'react'

import { Link } from 'react-router-dom';
import { Row, Col, Card, CardBody, CardFooter, Container, Button } from 'reactstrap';
import {ToastContainer} from 'react-toastify'

import { connect } from 'react-redux';
import { getLandingInfo, updateLandingInfo, getContactTypes} from '../../../../api/api';
import {FacebookSvg, FacebookMessengerSvg, PhoneSvg, WhatshappSvg} from '../../../../assets/ImageResources'
import { toast } from 'react-toastify';
import SweetAlert from 'react-bootstrap-sweetalert';
import Dropzone from 'react-dropzone';
import { Helmet } from 'react-helmet-async';

const FACEBOOK_MESSENGER = 'facebook-messenger'
const WHATSAPP_CALL = 'whatsapp-call'
const WHATSAPP = 'whatsapp'
const PHONE_ALT = 'phone-alt'



class LandingEditor extends Component {

    constructor(props) {
        super(props)
        this.state = { 
            landing: null,
            showTextEditor: false,
            editorText:'',
            showImageEditor: false,
            logoSelectedFiles:[] ,
            backgroundSelectedFiles:[] ,
            contactTypes: [],
            processing: false,
            delete_background_image: false,
            isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)        
        }
    }

    componentDidMount() {        
        const {landingId, login} = this.props
        if (landingId && login) {
            this.fetchLandingConfigs(landingId, login)        
        }
    }

    fetchLandingConfigs(landingId, login) {
        getLandingInfo(landingId, login).then(result =>{
            if (result.status === 200) {
                this.setState({
                    landing: result.data
                }, () => this.fetchContactTypes())
            } else {
                toast.warn('No se encontró la configuración.')
            }
        }).catch(error => console.error('Error', error))
    }

    fetchContactTypes() {
        const {landing} = this.state
        getContactTypes().then(result => {
            if (result.status === 200) {
                let contactTypes = result.data.filter(it => it.active)
                contactTypes.forEach(type =>{
                    const landingContact = landing.contacts.find(it => it.type.id === type.id)
                    if (landingContact) {
                        type.value = landingContact.contact
                        type.exist = true
                    }
                })
                this.setState({
                    contactTypes
                })
            }
        }).catch(error => console.error('Error', error))
    }

    formatColor(color) {
        return `#${color}`
    }

    renderEdition(action) {
        return (
            <div style={{marginTop:-5, marginLeft:5}}>
                <Button
                    color="secondary"                    
                    style={{padding:3, color:'#fff'}}
                    onClick={() => action ? action(): null}
                    >
                    <i className="mdi mdi-square-edit-outline"></i>                                        
                </Button>
            </div>
        )
    }

    renderEditor() {        
        const {showTextEditor, showImageEditor, editorSelectedFiles, editorField, showAddButtonEditor} = this.state
        if (showTextEditor && editorField) {
            return (
                <SweetAlert
                    showCancel
                    title="Editar Texto"
                    cancelBtnBsStyle="danger"
                    confirmBtnBsStyle="success"
                    onConfirm={() => {
                        this.updateStateLanding(editorField, this.state.editorText)                    
                        this.setState({
                            showTextEditor: false,
                            editorText: ''                            
                        })    
                    }
                    }
                    onCancel={() => this.setState({ showTextEditor: false })}
                >
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Coloca el nuevo texto aqui"                        
                        value={this.state.editorText}
                        onChange={(e) => {
                            this.setState({editorText:  e.target.value})                            
                        }}
                    />
                </SweetAlert>
            )
        } else if (showImageEditor && editorSelectedFiles){
            return (
                <SweetAlert
                    showCancel
                    title="Cambiar Imagen"
                    cancelBtnBsStyle="danger"
                    confirmBtnBsStyle="success"
                    onConfirm={() => {
                        this.setState({
                            showImageEditor: false                            
                        })                        
                    }
                    }
                    onCancel={() => this.setState({ showImageEditor: false })}
                >
                    <Dropzone
                        onDrop={acceptedFiles =>
                        this.handleAcceptedFiles(acceptedFiles, editorSelectedFiles, editorField)
                        }
                        accept="image/*"
                    >
                        {({ getRootProps, getInputProps }) => (
                        <div className="dropzone">
                            <div
                            className="dz-message needsclick"
                            {...getRootProps()}
                            >
                            <input {...getInputProps()} />
                            <h3>Drop files here or click to upload.</h3>
                            </div>
                        </div>
                        )}
                    </Dropzone>
                    {editorSelectedFiles.map((f, i) => {
                          return (
                            <Card
                              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                              key={i + "-file"}
                            >
                              <div className="p-2">
                                <Row className="align-items-center">
                                  <Col className="col-auto">
                                    <img
                                      data-dz-thumbnail=""
                                      height="80"
                                      className="avatar-sm rounded bg-light"
                                      alt={f.name}
                                      src={f.preview}
                                    />
                                  </Col>                                  
                                </Row>
                              </div>
                            </Card>
                          );
                        })}
                </SweetAlert>                
            )
        }else if (showAddButtonEditor){            
            return (
                <SweetAlert
                    showCancel
                    title="Modificar Botones"
                    cancelBtnBsStyle="danger"
                    confirmBtnBsStyle="success"
                    onConfirm={() => {
                        let contactTypes = this.state.contactTypes.filter(it => it.exist)
                        let invalidTypes = contactTypes.filter(it => !it.value)
                        if (invalidTypes[0]) {
                            invalidTypes.forEach(it => {
                                toast.warn(`Falta información de contacto: ${it.name}`)                                
                            })                            
                        }else {
                                const newContact = contactTypes.map(it =>{
                                    return ({
                                        contact: it.value,
                                        type: it
                                    })
                                })
                                const {landing} = this.state
                                landing.contacts = newContact
                                this.setState({
                                    showAddButtonEditor: false,
                                    landing 
                                }) 
                        }
                    }
                    }
                    onCancel={() => this.setState({ showAddButtonEditor: false })}
                >
                {
                    this.state.contactTypes.map(it => {
                        return (
                            <Row style={{marginBottom:'15px'}}>   
                                <Row style={{margin:0, marginBottom:'4px'}}>
                                    <input
                                        type="checkbox"                                                                                                            
                                        style={styles.checkbox}
                                        checked={it.exist}
                                        onChange={(e) => {                                            
                                            it.exist = e.target.checked
                                            this.setState({})
                                        }}
                                    />
                                    <span>{it.name}</span>
                                </Row>                             
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder={it.description}
                                    value={it.value}
                                    onChange={(e) => {
                                        it.value = e.target.value
                                        this.setState({})
                                    }}
                                />
                            </Row>
                        )
                    })
                }
                </SweetAlert>                
            )
        }
    }

    handleAcceptedFiles = (files, editorSelectedFiles, field='logo') => {
        files.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            formattedSize: this.formatBytes(file.size),
            getAsfile: () => file
          })
        );
    
        const {landing} = this.state
        if (files[0]) {
            editorSelectedFiles.length = 0
            editorSelectedFiles.push(...files)
            
            landing[field] = files[0].preview
            this.setState({                 
                landing
            });
        } else {
            editorSelectedFiles.length = 0
            this.setState({ 
            });
        }
      };
    
    /**
   * Formats the size
   */
    formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    };


    addButton() {        
        const {contactTypes, landing} = this.state
        contactTypes.forEach(type =>{
            const landingContact = landing.contacts.find(it => it.type.id === type.id)
            if (landingContact) {
                type.value = landingContact.contact
            }
        })
        this.setState({
            showAddButtonEditor: true,
            contactTypes
        })
    }


    render() {              
        const {landing, delete_background_image} = this.state
        const {general} = this.props
        let title = null
        if (general && landing) {
            title = `${general.page_title} | ${landing.slug}`            
        } else {            
        }

        const editor = this.renderEditor()
        if (landing) {
            let styleBackgroundImage = {
                backgroundImage: `url(${landing.background_image})`,
                backgroundPosition:'center',
                backgroundRepeat:'no-repeat',
                backgroundSize: 'auto 100%'
            }
            if (delete_background_image) {
                styleBackgroundImage = {}
            }
            return (
                <React.Fragment>
                    {
                        title && (
                            <Helmet>
                                <title>{title}</title>
                            </Helmet>
                        )
                    }

                    <div className="wrapper-page page-full"
                        style={{                        
                            backgroundColor: this.formatColor(landing.background_color),
                            color: this.formatColor(landing.text_color),
                            fill: this.formatColor(landing.button_text_color),
                            ...styleBackgroundImage
                        }}>
                        <Container style={{                        
                        }}>

                            <ToastContainer autoClose={5000} hideProgressBar />

                            {
                                editor
                            }

                            <Row style={{display:'flex', color: 'white', backgroundColor:'black'}}>
                                {/* <Col style={{padding:0}}>
                                    <div style={{display:'flex', color: 'white', backgroundColor:'black'}}> */}
                                        <div style={{...styles.options, backgroundColor:'gray'}}>Modo Edición</div>
                                        <div style={{...styles.options}}>
                                            Fondo
                                            <input
                                                style={{padding:1, margin:'3px', width:30}}
                                                className="form-control"
                                                type="color"
                                                defaultValue={this.formatColor(landing.background_color)}
                                                id="example-color-input"
                                                onChange={(e) => {                                                                                                
                                                        this.updateStateLanding('background_color', e.target.value.slice(1))
                                                    }
                                                }
                                            />
                                        </div>
                                        <div style={{...styles.options}}>
                                            Imagen Fondo
                                            <Button 
                                                color='secondary'
                                                style={{padding:1, margin:'3px'}}
                                                onClick={(e) => this.setState({
                                                    showImageEditor: true, 
                                                    editorSelectedFiles: this.state.backgroundSelectedFiles,
                                                    editorField: 'background_image'
                                                })}
                                            >
                                                Adjuntar archivo
                                            </Button>
                                        </div>
                                        <div style={{...styles.options}}>
                                            Eliminar Imagen Fondo
                                            <input
                                                type="checkbox"                                                                                                            
                                                style={styles.checkbox}
                                                checked={this.state.delete_background_image}
                                                onChange={(e) => {                                                                                                
                                                    this.setState({delete_background_image: !this.state.delete_background_image})
                                                }}
                                            />
                                        </div>
                                        <div style={{...styles.options}}>
                                            Color de Texto
                                            <input
                                                style={{padding:1, margin:'3px', width:30}}
                                                className="form-control"
                                                type="color"
                                                defaultValue={this.formatColor(landing.text_color)}
                                                id="example-color-input"
                                                onChange={(e) => {                                                                                                
                                                        this.updateStateLanding('text_color', e.target.value.slice(1))
                                                    }
                                                }
                                            />
                                        </div>
                                        <div style={{...styles.options}}>Botones:</div>     
                                        <div style={{...styles.options}}>
                                            Fondo
                                            <input
                                                style={{padding:1, margin:'3px', width:30}}
                                                className="form-control"
                                                type="color"
                                                defaultValue={this.formatColor(landing.button_background_color)}
                                                id="example-color-input"
                                                onChange={(e) => {                                                                                                
                                                        this.updateStateLanding('button_background_color', e.target.value.slice(1))
                                                    }
                                                }
                                            />
                                        </div>                           
                                        <div style={{...styles.options}}>
                                            Color de Texto
                                            <input
                                                style={{padding:1, margin:'3px', width:30}}
                                                className="form-control"
                                                type="color"
                                                defaultValue={this.formatColor(landing.button_text_color)}
                                                id="example-color-input"
                                                onChange={(e) => {                                                                                                
                                                        this.updateStateLanding('button_text_color', e.target.value.slice(1))
                                                    }
                                                }
                                            />
                                        </div>

                                        <div style={{...styles.options, flex:1, justifyContent:'flex-end'}}>
                                            {
                                                this.state.processing ? 
                                                    <div className="spinner-border text-secondary m-1" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                    :
                                                    <Button 
                                                        color='success'
                                                        onClick={(e) => this.sendUpdatesLanding()}
                                                    >
                                                        Hecho
                                                    </Button>
                                            }
                                            <Button 
                                                style = {{marginLeft:'20px'}}
                                                onClick={(e) => this.redirectToLandigPage(landing)}
                                            >
                                                Cancelar
                                            </Button>
                                        </div>                                
                                    {/* </div>
                                </Col> */}
                            </Row>
                            <Row style={{paddingTop:20, paddingBottom:20}}>
                                <Col md={6}>
                                    <Row style={{justifyContent:'center'}}>
                                        <Col style={{display:'flex', justifyContent:'center'}}>
                                            <img                                
                                                alt="veltrix"
                                                src={landing.logo}
                                                data-holder-rendered="true"
                                                class="image_miwhats"
                                                />   
                                            {this.renderEdition(() => this.setState({
                                                showImageEditor: true, 
                                                editorSelectedFiles: this.state.logoSelectedFiles,
                                                editorField: 'logo'
                                                }))}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col style={{display:'flex', justifyContent:'center', padding:'20px'}}>                                        
                                            <div class="text">{landing.text}<br /></div>
                                            {this.renderEdition(() => this.setState({
                                                showTextEditor: true, 
                                                editorText: landing.text,
                                                editorField: 'text'
                                                }))}
                                        </Col>
                                    </Row>                            
                                </Col>
                                <Col md={6}>
                                    <Row style={{justifyContent:'center'}}>                                                                              
                                        <div class="text">Comunícate con nosotros vía<br /></div>                              
                                    </Row>
                                    <Row style={{justifyContent:'center'}}>                                
                                    {
                                        landing.contacts.map((contact, index) => {    
                                            let href = ''
                                            let textButton = ''
                                            let icon = null
                                            if (contact.type.slug === WHATSAPP_CALL ) {
                                                if (this.state.isMobile) {                                                
                                                    href = `whatsapp://send?phone=${contact.contact}${contact.message_get ? `&text=${contact.message_get}`:''}`
                                                } else {
                                                    href = `https://wa.me/${contact.contact}${contact.message_get?`&text=${contact.message_get}`:''}`
                                                }
                                                textButton= 'Whatsapp Call'                                            
                                                icon = <WhatshappSvg class="button-icon" />
                                            } else if (contact.type.slug === WHATSAPP ) {
                                                if (this.state.isMobile) {                                                
                                                    href = `whatsapp://send?phone=${contact.contact}${contact.message_get ? `&text=${contact.message_get}`:''}`
                                                } else {
                                                    href = `https://wa.me/${contact.contact}${contact.message_get?`&text=${contact.message_get}`:''}`
                                                }
                                                textButton= 'Whatsapp'
                                                icon = <WhatshappSvg class="button-icon" />                                            
                                            } else if (contact.type.slug === PHONE_ALT ) {
                                                href = `tel:${contact.contact}`
                                                textButton= 'LLamada'
                                                icon = <PhoneSvg class="button-icon" />                                            
                                            } else if (contact.type.slug === FACEBOOK_MESSENGER ) {
                                                href = `https://m.me/${contact.contact}`
                                                textButton= 'Inbox'                                            
                                                icon = <FacebookMessengerSvg class="button-icon" />
                                            } 
                                            
                                            return (                                          
                                                    <a key = {index}
                                                        style={{
                                                            // ...styles.button,                                                                                       
                                                            backgroundColor: this.formatColor(landing.button_background_color),
                                                            color: this.formatColor(landing.button_text_color),
                                                            fill: this.formatColor(landing.button_text_color)
                                                            // width: '120px'
                                                        }}
                                                        class="landing_button col-11 col-md-3 text-center"
                                                        href={href}
                                                        target="_blank"                                            
                                                        >
                                                            {icon}                                            
                                                        <span>
                                                            {' ' + textButton}
                                                        </span>
                                                    </a>                                        
                                            )
                                        })
                                    }
                                        
                                        <Button 
                                                onClick={(e) => this.addButton()}
                                                style={{...styles.button}}
                                            >
                                            Modificar Botones
                                        </Button>
                                    </Row>
                                    <Row style={{justifyContent:'center'}}>
                                        <Col>
                                            <Row style={{justifyContent:'center'}}>
                                                <label style={{fontSize:'1rem'}}>Catálogo Whatsapp Business</label>
                                            </Row>
                                            <Row style={{justifyContent:'center'}}>
                                                <input 
                                                    type='text'
                                                    value={landing.whatsapp_catalog}
                                                    onChange={(e) => {                                                                                                
                                                        this.updateStateLanding('whatsapp_catalog', e.target.value)
                                                    }
                                                }
                                                >
                                                </input>
                                                {/* {this.renderEdition(() => this.setState({
                                                        showTextEditor: true, 
                                                        editorText: landing.whatsapp_catalog,
                                                        editorField: 'whatsapp_catalog'
                                                        }))} */}
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row style={{justifyContent:'center', marginTop:'20px'}}>
                                        <label style={{fontSize:'1rem'}}>Contactarme más tarde</label>
                                        <input
                                                type="checkbox"                                                                                                            
                                                style={styles.checkbox}
                                                checked={landing.contact_me}
                                                onChange={(e) => {                                                
                                                    landing.contact_me = e.target.checked
                                                    this.setState({})
                                                }}
                                            />
                                    </Row>                                
                                </Col>
                            </Row>                    
                        </Container>
                    </div>
                </React.Fragment>
            )
        } else {
            return (
                <Container>

                </Container>
            )
        }
    }

    updateStateLanding(field, value) {
        const { landing } = this.state
        landing[field] = value
        this.setState({ landing })
    }

    async sendUpdatesLanding() {
        const {login} = this.props
        const {landing, logoSelectedFiles, backgroundSelectedFiles, delete_background_image} = this.state
        let updateLogo = false
        let uploadLogoSucess = true
        if (landing) {
            this.setState({processing: true})
            const contacts_objs = landing.contacts.map(it => ({
                contact: it.contact,
                type_id: it.type.id,
            }))
            let landingToSend = {
                ...landing,
                contacts_objs: contacts_objs
            }
            delete landingToSend.logo
            delete landingToSend.background_image
            
            if (delete_background_image) {
                landingToSend = {
                    ...landingToSend,
                    background_image: null
                }
            }

            if (logoSelectedFiles[0] || (backgroundSelectedFiles[0] && !delete_background_image)) {
                updateLogo= true
                const data = new FormData();                                
                if (logoSelectedFiles[0]) {
                    const image = logoSelectedFiles[0]
                    data.append("logo", image)
                }
                if (backgroundSelectedFiles[0]) {
                    const image = backgroundSelectedFiles[0]
                    data.append("background_image", image)
                }
                try {
                    const result = await updateLandingInfo(landing.id, data, login)                    
                    if (result.status === 200) {
                        uploadLogoSucess = true
                    } else {
                        uploadLogoSucess = false
                        if (result.status === 400) {
                            toast.warn(JSON.stringify(result.data))
                        }
                    }               
                }catch(e) {
                    uploadLogoSucess = false
                }
            } 

            updateLandingInfo(landing.id, landingToSend, login).then(result => {
                if (result.status === 200) {
                    let landingResult= result.data
                    this.setState({
                        landing: landingResult
                    })
                    if (updateLogo) {
                        if (uploadLogoSucess) {
                            toast.success('Cambios aplicados correctamente')
                            this.redirectToLandigPage(landingResult)
                        } else {
                            this.showLogoMessage(updateLogo, uploadLogoSucess)
                        }
                    } else {
                        toast.success('Cambios aplicados correctamente')
                        this.redirectToLandigPage(landingResult)
                    }
                } else {
                    toast.warn('No se pudieron aplicar los cambios.')
                    if (result.status === 400) {
                        toast.warn(JSON.stringify(result.data))
                    }
                    this.showLogoMessage(updateLogo, uploadLogoSucess)
                }
                this.setState({processing: false})
            }).catch(error => console.error('Error', error))
        }
    }

    showLogoMessage(updateLogo, uploadLogoSucess) {
        if (updateLogo) {
            if (uploadLogoSucess) {
                toast.success('El logo fue actualizado correctamente')
            } else {
                toast.warn('El logo no se pudo actualizar.')
            }
        }
    }

    redirectToLandigPage(landingResult) {
        if (landingResult && landingResult.domain) {
            let url = `${landingResult.domain.name}/${landingResult.url}/`            
            window.location.href = url
            
            // let handle = window.open(url, "_blank");
            // handle.blur()
            // window.focus()
        }
    }


}

const styles = {
    options: {
        display: 'flex',
        padding:'5px',
        alignItems: 'center'
    },

    button: {
        borderRadius:'90px',
        border:'none', 
        fontSize:'1rem', 
        fontWeight:'400', 
        marginTop:'20px', 
        marginBottom:'20px', 
        marginLeft:'8px', 
        marginRight:'8px', 
        paddingTop: '10px',
        paddingBottom: '10px',
        paddingRight: '15px',                                            
        paddingLeft: '15px',
    },

    checkbox: {
        width:'18px', 
        height:'18px', 
        marginLeft:'3px',
        marginRight:'3px'
    }
}

const mapStateToProps = (state) => ({    
    general: state.General.general,
    login: state.Login
});

export default connect(mapStateToProps, null)(LandingEditor);