import React, { Component } from 'react'

import { Link } from 'react-router-dom';
import { Row, Col, Card, CardBody, CardFooter } from 'reactstrap';

import user2 from "../../assets/images/users/user-2.jpg";
import user3 from "../../assets/images/users/user-3.jpg";
import { getMarketplaceMyCompanies } from '../../api/api';
import Paginator from '../../components/Paginator';

export default class MyCompanies extends Component {

    constructor(props) {
        super(props)
        this.state = {
            pages: [],
            currentPage: 0
        }
    }

    componentDidMount(){
        this.fetchMyCompanies(0)
    }

    fetchMyCompanies(currentPage, nextUrl) {
        getMarketplaceMyCompanies(nextUrl).then(result =>{
            if(result.status == 200){
                const {pages} = this.state
                pages[currentPage] = result.data
                this.setState({
                    pages
                })
            }
        }).catch(error => console.error('Error', error))
    }

    onNextClick() {
        const { pages, currentPage } = this.state
        const nextPage = pages[currentPage + 1]        
        if (nextPage) {
            this.setState({
                currentPage: currentPage + 1
            })
        } else {
            const page = pages[currentPage]        
            if (page && page.next) {            
                this.setState({
                    currentPage: currentPage + 1
                }, this.fetchMyCompanies(currentPage + 1, page.next))
            }
        }
    }

    onPreviusClick() {
        const { pages, currentPage } = this.state
        if (currentPage > 0) {
            this.setState({
                currentPage: currentPage - 1
            })            
        }
    }

    onPageClick(pageSelected) {
        const { pages } = this.state        
        if (pages[pageSelected]) {
            this.setState({
                currentPage: pageSelected
            })            
        }
    }

    renderCompanies() {
        const { pages, currentPage } = this.state
        const page = pages[currentPage]        
        if (page) {
            const results = page.results
            return results.map(it => {
                return (
                    <tr>
                        <th scope="row">{it.id}</th>
                        <td>
                            <div>
                                <img
                                    src={it.logo}
                                    alt=""
                                    className="avatar-xs rounded-circle mr-2"
                                />{" "}
                              {it.name}
                            </div>
                        </td>
                        <td>{it.contact_name}</td>                                                
                        <td>
                            {
                                it.has_invited ?
                                    <span className="badge badge-success">Invitada</span>
                                    :
                                    <span className="badge badge-warning">-</span>
                            }
                        </td>                        
                        <td>
                            {
                                it.is_partner ?
                                    <span className="badge badge-success">Partner</span>
                                    :
                                    <span className="badge badge-warning">-</span>
                            }
                        </td>                        
                        <td>
                            {
                                it.active ?
                                    <span className="badge badge-success">Activa</span>
                                    :
                                    <span className="badge badge-warning">Inactiva</span>
                            }
                        </td>                        
                    </tr>                    
                )
            })
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="container-fluid">
                    <Row className="align-items-center">
                        <Col sm={6}>
                            <div className="page-title-box">
                                <h4 className="font-size-18">Mis Empresas</h4>
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item active">
                                        Marketplace / Mis Empresas
                                    </li>
                                </ol>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={8}>
                            <Card>
                                <CardBody>
                                    <h4 className="card-title mb-4">Mis Empresas</h4>
                                    <div className="table-responsive">
                                        <table className="table table-hover table-centered table-nowrap mb-0">
                                            <thead>
                                                <tr style={{fontWeight:'bold'}}>
                                                    <th scope="col">(#) Id</th>
                                                    <th scope="col">Empresa</th>
                                                    <th scope="col">Contacto</th>
                                                    <th scope="col">Invitada</th>
                                                    <th scope="col">Es Partner</th>
                                                    <th scope="col" colSpan="2">
                                                        Activa
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.renderCompanies()
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    <div style={{display:'flex', justifyContent:'flex-end'}}>
                                        <Paginator 
                                            onPreviusClick = {() => this.onPreviusClick()}
                                            onNextClick = {() => this.onNextClick()}
                                            onPageClick = {(pageSelected) => this.onPageClick(pageSelected)}
                                            currentPage = {this.state.currentPage + 1}
                                            totalPages = {this.state.pages.length}
                                            />                                        
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        )
    }

}