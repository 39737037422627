import React from "react";
import { Link } from "react-router-dom";

export default function qacatalogmainnav(props) {
  const baseURL= props.url;
  return (
    <li>
      <Link to="/#" className="has-arrow waves-effect">
        <i className="icon ion-ios-keypad" style={{backgroundColor:'transparent'}}></i>
        <span> Catálogo</span>
      </Link>
      <ul className="sub-menu" aria-expanded="false">
        <li>
          <a href={baseURL+"/shop/admin/category/all/"}>
            Categorías
          </a>
        </li>
        <li>
          <a href={baseURL+"/shop/cms/products/all/"}>
            Productos General
          </a>
        </li>
        <li>
          <a href={baseURL+"/shop/cms/products-export-import/"}>
            Exportar / Importar
          </a>
        </li>
        <li>
          <a href={baseURL+"/shop/cms/plans/all/"}>Planes</a>
        </li>
        <li>
          <a href={baseURL+"/inventory/cms/product-office/"}>
            Productos x Oficinas
          </a>
        </li>
        <li>
          <a href={baseURL+"/inventory/cms/product-status/"}>
            Estatus de Productos
          </a>
        </li>
        <li>
          <a href={baseURL+"/shop/cms/campaigns/"}>
            Campañas
          </a>
        </li>
      </ul>
    </li>
  );
}
