export const Types = {
  GET_MENU_REQUEST: "get_menu_request",
  GET_MENU_SUCCESS: "get_menu_success",  
  MENU_ERROR: "menu_error",
  CARGANDO: "cargando",

  GET_COLLABORATOR_REQUEST: "get_collaborator_request",
  COLLABORATOR_SUCCESS: "collaborator_success",
  COLLABORATOR_ERROR: "collaborator_error",
  CLEAR_USER_DATA: 'clear_user_data'
};


export const getMenu = (sessionid, token, csrftoken) => ({
  type: Types.GET_MENU_REQUEST,
  payload: {
    sessionid,
    token,
    csrftoken,
  },
});

export const getMenuSuccess = ({ master }) => ({
  type: Types.GET_MENU_SUCCESS,
  payload: {
    master,
  },
});

export const menuError = ({ error }) => ({
  type: Types.MENU_ERROR,
  payload: {
    error,
  },
});

export const cargando = () => ({
  type: Types.CARGANDO,
});

export const clearUserData = () => ({
  type: Types.CLEAR_USER_DATA,
});

export const setUserData = (userData) => ({
  type: Types.COLLABORATOR_SUCCESS,
  payload: {
    userData
  },
});

export const setCollaboratorError = () => ({
  type: Types.COLLABORATOR_ERROR,
  payload: {
  },
});

export const setCollaboratorRequest = () => ({
  type: Types.GET_COLLABORATOR_REQUEST,
  payload: {
  },
});
