import React, { Component } from 'react'

import { Row, Col, Card, CardBody, CardFooter, FormGroup, Button, Label, Input, Container } from 'reactstrap';
import { AvForm, AvField } from "availity-reactstrap-validation";
import Autocomplete from 'react-google-autocomplete';
import { saveClient, addAdress, editClient, getClientById, editAddress, getStatesByCountry, getCitiesByState } from '../../../api/api';
import Map from '../../../components/map/Map'
import { toast } from 'react-toastify';

export default class NewClient extends Component {

    constructor(props) {
        super(props)
        this.state = {
            name: '',
            last_name: '',
            email: '',
            tel_mobile: '',
            tel_home: '',
            tel_office: '',

            nameAdreess: 'Dirección',
            atype_id: 1,
            zip_code: '',
            street: '',
            numext: '',
            zone_text: '',
            area: '',
            state_text: '',
            city_text: '',
            country_text: 'México',

            //map zoom
            zoom: 16,
            states: [],
            cities: []
        }
    }

    componentDidMount() {
        this.initWithClient(this.props.editing)

    }

    initWithClient(editingClient) {
        if (editingClient) {
            getClientById(editingClient.id, this.props.login).then(result => {
                if (result.status == 200) {
                    const fullDetails = result.data
                    this.setState({
                        name: fullDetails.name,
                        last_name: fullDetails.last_name,
                        email: fullDetails.email,
                        tel_mobile: fullDetails.tel_mobile,
                        tel_home: fullDetails.tel_home,
                        tel_office: fullDetails.tel_office,
                    })
                } else {
                    this.setState({
                        name: editingClient.name,
                        last_name: editingClient.last_name,
                        email: editingClient.email,
                    })
                }

                this.setState({
                    //adress
                    street: editingClient.address.street,
                    numext: editingClient.address.numext,
                    zone_text: editingClient.address.zone_text,
                    area: editingClient.address.area,
                    city_text: editingClient.address.city_text,
                    state_text: editingClient.address.state_text,
                    country_text: editingClient.address.country_text,
                    zip_code: editingClient.address.zip_code,
                    lat: editingClient.address.lat,
                    lon: editingClient.address.lon,
                }, () => this.findStreetObjects())
            }).catch(error => console.error('Error', error))
        } else {
            this.findStreetObjects()
        }
    }

    onAddressChange = (address) => {
        if (address && address.address_components && address.address_components.length > 0) {
            this.setState({
                street: this.getAddressInfo(address, "route"),
                numext: this.getAddressInfo(address, "street_number"),
                zone_text: this.getAddressInfo(address, "sublocality"),
                area: this.getAddressInfo(address, "sublocality"),
                city_text: this.getAddressInfo(address, "locality"),
                state_text: this.getAddressInfo(address, "administrative_area_level_1"),
                country_text: this.getAddressInfo(address, "country"),
                zip_code: this.getAddressInfo(address, "postal_code"),
                lat: address.geometry.location.lat instanceof Function ? address.geometry.location.lat() : address.geometry.location.lat,
                lon: address.geometry.location.lng instanceof Function ? address.geometry.location.lng() : address.geometry.location.lng
            }, () => this.findStreetObjects())
        }

    }

    findStreetObjects() {
        const { country_text, state_text, city_text } = this.state
        const { countries } = this.props
        if (country_text && countries) {
            const country = countries.find(it => it.name.toLowerCase().includes(country_text.toLocaleLowerCase()))
            if (country) {
                this.setState({ country })
                this.findStateByCountry(country)
            }
        }
    }

    findStateByCountry(country) {
        const { state_text } = this.state
        getStatesByCountry(country.id).then(result => {
            if (result.status === 200) {
                const states = result.data
                this.setState({ states })
                let state = states.find(it => it.name.toLowerCase().includes(state_text.toLocaleLowerCase()))
                if (!state) {
                    state = states[0]
                }
                this.setState({
                    state
                })
                this.findCityByState(state)
            }
        })
    }

    findCityByState(state) {
        const { city_text } = this.state
        if (state) {
            getCitiesByState(state.id).then(result => {
                if (result.status === 200) {
                    const cities = result.data
                    this.setState({ cities })
                    const city = cities.find(it => it.name && city_text && it.name.toLowerCase().includes(city_text.toLocaleLowerCase()))
                    if (city) {
                        this.setState({
                            city
                        })
                    } else {
                        this.setState({
                            city: cities[0]
                        })
                        if (cities[0]) {
                            this.setState({
                                city_text: cities[0].name
                            })
                        }
                    }
                }
            })
        } else {
            this.setState({
                cities: [],
                city: null
            })
        }
    }

    getAddressInfo(address, typeInfo) {
        for (let i = 0; i < address.address_components.length; i++) {
            const element = address.address_components[i];
            for (let j = 0; j < element.types.length; j++) {
                const elementType = element.types[j];
                if (elementType === typeInfo) {
                    return element.long_name
                }
            }
        }
        return ''
    }

    getGoogleMapsApiKey() {
        const { master } = this.props
        if (master) {
            return master.google_maps_api
        }
        return ''
    }

    onZoomChange() {
        if (this.googleMapRef) {
            //console.log('onZoomChange', this.googleMapRef.getZoom())
            this.setState({ zoom: this.googleMapRef.getZoom() })
        }
    }

    renderMap() {
        const googleMapKey = this.getGoogleMapsApiKey()
        //console.log('RendeMap', this.googleMapRef)            
        if (googleMapKey && this.state.lat && this.state.lon) {
            const center = {
                lat: Number(this.state.lat),
                lng: Number(this.state.lon),
            }
            return (
                <Map
                    googleMapApiKey={googleMapKey}
                    center={center}
                    height='400px'
                    zoom={this.state.zoom}
                    hideAddressFields={true}
                    onMapMounted={ref => this.googleMapRef = ref}
                    onAddressChange={this.onAddressChange}
                    onZoomChange={() => this.onZoomChange()}
                />
            )
        }
    }

    render() {
        //console.log('render', this.state)    
        const { country, state, city, street, numint, state_text, numext, area, zip_code, zone_text, city_text, country_text, lat, lon } = this.state
        const { name, last_name, email, tel_mobile, tel_home, tel_office } = this.state
        const { editing } = this.props
        let countrySelectedId = country ? country.id : 0
        let stateSelectedId = state ? state.id : 0
        let citySelectedId = city ? city.id : 0

        return (
            <Container>
                <AvForm onValidSubmit={() => this.createClient()}>
                    <Row>
                        <Col md="4">
                            <AvField
                                name="name"
                                label="Nombre"
                                errorMessage="Este campo es requerido"
                                required
                                value={name}
                                onChange={(e) => this.setState({
                                    name: e.target.value
                                })}
                            />
                        </Col>
                        <Col md="4">
                            <AvField
                                name="last_name"
                                label="Apellidos"
                                errorMessage="Este campo es requerido"
                                required
                                value={last_name}
                                onChange={(e) => this.setState({
                                    last_name: e.target.value
                                })}
                            />
                        </Col>
                        <Col md="4">
                            <AvField
                                name="email"
                                label="Correo Electronico"
                                errorMessage="Este campo es requerido"
                                value={email}
                                onChange={(e) => this.setState({
                                    email: e.target.value
                                })}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md="4">
                            <AvField name="tel_mobile" label="Celular"
                                type="text"
                                errorMessage="Inválido"
                                validate={{
                                    required: { value: true },
                                }}
                                maxLength={30}
                                value={tel_mobile}
                                onChange={(e) => this.setState({
                                    tel_mobile: e.target.value
                                })} />
                        </Col>
                        <Col md="4">
                            <AvField name="tel_home" label="Teléfono de Casa"
                                type="text"
                                errorMessage="Inválido"
                                maxLength={30}
                                value={tel_home}
                                onChange={(e) => this.setState({
                                    tel_home: e.target.value
                                })} />
                        </Col>
                        <Col md="4">
                            <AvField name="tel_office" label="Teléfono de Trabajo"
                                type="text"
                                errorMessage="Inválido"
                                maxLength={30}
                                value={tel_office}
                                onChange={(e) => this.setState({
                                    tel_office: e.target.value
                                })} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="checkout-company-name">
                                    Buscar Direción
                                </label>
                                <Autocomplete
                                    style={{ width: '100%' }}
                                    onPlaceSelected={this.onAddressChange}
                                    types={'address'}
                                    componentRestrictions={{ country: 'mx' }}
                                />
                                {
                                    this.renderMap()
                                }
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="4">
                            <AvField name="zip_code"
                                label="Código Postal"
                                type="number"
                                errorMessage="Solo numeros"
                                validate={{
                                    required: { value: true },
                                    pattern: {
                                        value: "^[0-9]+$",
                                        errorMessage: "Solo numeros"
                                    }
                                }}
                                value={this.state.zip_code}
                                onChange={(e) => this.setState({ zip_code: e.target.value })}
                            />
                        </Col>

                        <Col md="4">
                            <AvField
                                name="street"
                                label="Calle"
                                errorMessage="Este campo es requerido"
                                required
                                value={this.state.street}
                                onChange={(e) => this.setState({ street: e.target.value })}
                            />
                        </Col>

                        <Col md="4">
                            <AvField
                                name="street_number"
                                label="Número"
                                errorMessage="Este campo es requerido"
                                validate={{
                                    required: { value: true },
                                    maxLength: { value: 30, errorMessage: "Máximo 30 carácteres." }
                                }}
                                value={this.state.numext}
                                onChange={(e) => this.setState({ numext: e.target.value })}
                            />
                        </Col>
                        <Col md="4">
                            <AvField
                                name="area"
                                label="Colonia"
                                errorMessage="Este campo es requerido"
                                required
                                value={this.state.area}
                                onChange={(e) => this.setState({ area: e.target.value })}
                            />
                        </Col>
                        <Col md="4">
                            <AvField
                                type="select"
                                name="country_select"
                                id="country_select_id"
                                label="País"
                                required
                                onChange={(e) => this.onCountryChange(e)}
                                value={countrySelectedId}
                            >
                                {
                                    this.props.countries && this.props.countries.map(country => (
                                        <option value={country.id} >{country.name}</option>
                                    ))
                                }
                            </AvField>
                        </Col>
                        <Col md="4">
                            <AvField
                                type="select"
                                name="state_select"
                                id="state_select_id"
                                label="Estado"
                                required
                                onChange={(e) => this.onStateChange(e)}
                                value={stateSelectedId}
                            >
                                {
                                    this.state.states.map(state => (
                                        <option value={state.id} >{state.name}</option>
                                    ))
                                }
                            </AvField>
                        </Col>
                        <Col md="4">
                            <AvField
                                type="select"
                                name="city_select"
                                id="city_select_id"
                                label="Ciudad / Municipio"
                                onChange={(e) => this.onCityChange(e)}
                                value={citySelectedId}
                            >
                                {
                                    this.state.cities.map(city => (
                                        <option value={city.id} >{city.name}</option>
                                    ))
                                }
                            </AvField>
                        </Col>

                        <Col md="4">
                            <AvField
                                name="city_text"
                                label="Ciudad / Municipio"
                                errorMessage="Este campo es requerido"
                                required
                                value={this.state.city_text}
                                onChange={(e) => this.setState({ city_text: e.target.value })}
                            />
                        </Col>

                        {/* <Col md="4">
                                <AvField
                                    name="area"
                                    label="Municipio"                        
                                    errorMessage="Este campo es requerido"
                                    required
                                    value={this.state.area}
                                    onChange={(e) => this.setState({area: e.target.value}) }
                                />
                            </Col>
                            <Col md="4">
                                <AvField
                                    name="state_text"
                                    label="Ciudad/Estado"                        
                                    errorMessage="Este campo es requerido"
                                    required
                                    value={this.state.state_text}
                                    onChange={(e) => this.setState({state_text: e.target.value}) }
                                />
                            </Col>
                            
                            <Col md="4">
                                <AvField
                                    name="country_text"
                                    label="País"                        
                                    errorMessage="Este campo es requerido"
                                    required
                                    value={this.state.country_text}
                                    onChange={(e) => this.setState({country_text: e.target.value}) }
                                />
                            </Col> */}

                    </Row>
                    <Row>
                        <Col style={{ marginTop: '8px', marginBottom: '8px' }}>
                            <Button
                                color="info"
                                className="btn btn-info waves-effect waves-light"
                            >
                                {editing ?
                                    <i className="mdi mdi-account-edit-outline mr-2"></i>
                                    :
                                    <i className="mdi mdi-account-plus-outline mr-2"></i>
                                }
                                {editing ?
                                    'Guardar'
                                    :
                                    'Crear'
                                }

                            </Button>
                        </Col>
                    </Row>
                </AvForm>
            </Container>
        )
    }

    onCountryChange(e) {
        const { countries } = this.props
        if (countries) {
            const country = countries.find(it => it.id == e.target.value)
            if (country) {
                this.setState({
                    country,
                    country_text: country.name
                }, () => this.findStateByCountry(country))
            }
        }
    }

    onStateChange(e) {
        const { states } = this.state
        if (states) {
            const state = states.find(it => it.id == e.target.value)
            if (state) {
                this.setState({
                    state,
                    state_text: state.name
                }, () => this.findCityByState(state))
            } else {
                this.setState({
                    state: null,
                    state_text: ''
                }, () => this.findCityByState(''))
            }
        }
    }

    onCityChange(e) {
        const { cities } = this.state
        if (cities) {
            const city = cities.find(it => it.id == e.target.value)
            if (city) {
                this.setState({
                    city,
                    city_text: city.name
                })
            } else {
                this.setState({
                    city: null,
                    city_text: ''
                })
            }
        }
    }


    createClient() {
        console.log('State', this.state)
        const { nameAdreess, atype_id, street, numint, state_text, numext, area, zip_code, zone_text, city_text, country_text, lat, lon } = this.state
        const { country, state, city } = this.state
        const { login, editing, onUpdateSuccess, onCreateSuccess } = this.props
        const data = {
            ...this.state
        }
        let addressData = {
            // client_id: client.id, //added after
            name: nameAdreess,
            atype_id,
            street,
            numext,
            numint,
            area,
            zip_code,
            zone_text,
            city_text,
            state_text,
            country_text,
            lat,
            lon,
            ...(country ? { country_id: country.id } : {}),
            ...(state ? { state_id: state.id } : {}),
            ...(city ? { city_id: city.id } : {}),
        }

        if (editing) {
            editClient(editing.id, data, login).then(result => {
                if (result.status === 200 || result.status === 201) {
                    const clientEdited = result.data
                    if (editing.address.id) {
                        editAddress(login, editing.address.id, addressData).then(result => {
                            if (result.status == 200) {
                                clientEdited.address = result.data
                                toast.success('Información actualizada correctamente.')
                                if (onUpdateSuccess) {
                                    onUpdateSuccess(clientEdited)
                                }
                            } else {
                                toast.warn('No se pudo actualizar la información de dirección.')
                                if (onUpdateSuccess) {
                                    onUpdateSuccess(clientEdited)
                                }
                            }
                        })
                    } else {
                        addressData = {
                            ...addressData,
                            client_id: clientEdited.id
                        }
                        addAdress(login, addressData).then(result => {
                            if (result.status == 200) {
                                clientEdited.address = result.data
                                toast.success('Información actualizada correctamente.')
                                if (onUpdateSuccess) {
                                    onUpdateSuccess(clientEdited)
                                }
                            } else {
                                toast.warn('No se pudo actualizar la información de dirección.')
                                if (onUpdateSuccess) {
                                    onUpdateSuccess(clientEdited)
                                }
                            }
                        }).catch(error => console.error('Error', error))
                    }
                }
            }).catch(error => console.error('Error', error))
        } else {
            saveClient(data, login).then(result => {
                if (result.status == 201) {
                    const client = result.data
                    addressData = {
                        ...addressData,
                        client_id: client.id
                    }
                    addAdress(login, addressData).then(result => {
                        if (result.status == 200) {
                            toast.success('Cliente creado correctamente.')
                            if (onCreateSuccess) {
                                client.address = result.data
                                onCreateSuccess(client)
                            }
                        } else {
                            toast.warning('El cliente se creo pero no se pudo guardar la dirección.')
                            if (onCreateSuccess) {
                                onCreateSuccess(client)
                            }
                        }
                    }).catch(error => console.error('Error', error))
                } else {
                    toast.error('No se pudo crear el cliente.')
                    if (result.status == 400) {
                        toast.error(JSON.stringify(result.data))
                    }
                }
            }).catch(error => console.error('Error', error))
        }



    }

}