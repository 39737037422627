import React from "react";
import { Link } from "react-router-dom";

const SidebarContent = (props) => {
  const baseURL= props.url;
  return (
    <ul className="sub-menu" aria-expanded="false">
      <li>
        <a href={baseURL+"/reports/cms/dashboard/"}>
          Dashboard
        </a>
      </li>
      <li>
        <a href={baseURL+"/reports/cms/alert-users/"}>
          Alertas de Usarios
        </a>
      </li>
      <li>
        <a href={baseURL+"/reports/cms/alert-users-custom/"}>
          Alertas de Usarios - Horarios
        </a>
      </li>
    </ul>
  );
};

export default function qreportsmainnav(props) {
  return (
    <li>
      <Link to="/#" className="has-arrow waves-effect">
        <i ><span className="iconify" data-icon="bx:bxs-report" data-inline="false"></span></i>
        <span>Reportes</span>
      </Link>
        {/* {props.permissions.User_Cadi_Admin || props.permissions.User_Cadi_General ? <SidebarContent url={props}/> : ''} */}
        <SidebarContent url={props}/> 
    </li>
  );
}


