import React, { Component } from "react";
import { Row, Col, Button, Input, Card, CardBody, Modal, ModalFooter, FormGroup, Form, Label, Alert } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Select from "react-select";
import Switch from "react-switch";
import { Link } from "react-router-dom";
import axios from 'axios';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Map, InfoWindow, Marker, GoogleApiWrapper, Polygon, Circle, SearchBox } from "google-maps-react";
// Custom Scrollbar
// Sweet alerts
import SweetAlert from 'react-bootstrap-sweetalert';
import Autocomplete from 'react-google-autocomplete';


const otypeOptions = [
  {
    label: "Tipo de Dispositivo",
    options: [
      { label: "Radial", value: "Radial" },
      { label: "Poligonal", value: "Poligonal" }
    ]
  }
];

const LoadingContainer = props => <div>Loading...</div>;

const initialCenterMap = {
  lat: 19.45426676270879,
  lng: -99.13530593750001
}

class GeocercaView extends Component {

  constructor(props) {
    super(props);
    this.state = {
      modal_center: false,
      modal_map: false,
      otypeGroup: null,
      geocercaName: null,
      allGeoCercas: [],
      devices: [],
      mapZoom: 14,
      activeMarker: null,
      coords: { lat: 19.45426676270879, lng: -99.13530593750001 },
      showDeleteAlert: false,
      id_d: 0,
      is_edition: false,
      radius: 1000,
      GeofenceStatus: 'Activos',
      showDisableAlert: false,
      switchNew: false,
      message_alert: "",
      showAlert: false,
      showErrAlert: false,
      title_modal: "Agregar nuevo Dispositivo"
    };
    this.backendNode = process.env.REACT_APP_NODE_BACKEND_URL;
    this.tog_center = this.tog_center.bind(this);
    this.updateRadius = this.updateRadius.bind(this);


    this.autocompleteInput = React.createRef();
    this.autocomplete = null;
    this.handlePlaceChanged = this.handlePlaceChanged.bind(this);

  }

  removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  tog_center() {
    //console.log(this.state.Edition);
    this.setState(prevState => ({
      modal_center: !prevState.modal_center
    }));
    this.removeBodyCss();
  }

  tog_map() {
    //console.log(this.state.Edition);
    this.setState(prevState => ({
      modal_map: !prevState.modal_map
    }));
    this.removeBodyCss();
  }

  handleChangeName = event => {
    this.setState({ geocercaName: event.target.value });
  }

  handleSubmit = event => {
    event.preventDefault();
    var _usr_id = this.props.User.user.id;
    //const { name, site_id, active, _delete, _in, out, created_by, created_at, otype } = data;
    const headers = {
      'Content-Type': 'application/json'
    }
    console.log(this.state.is_edition);
    const data = {
      name: this.state.geocercaName,
      site_id: Number(this.props.User.user.site.id),
      active: this.state.switchNew,
      delete: false,
      in: false,
      out: false,
      created_by: _usr_id,
      created_at: false,
      otype: this.state.otypeGroup.value,
      coords: this.state.coords,
      radius: this.state.radius
    };
    if (this.state.is_edition == true) {
      console.log('EDITAR');
      this.setState({ is_edition: false });
      axios.put(
        `http://${this.backendNode}/api/geocerca/update/${this.state.id_d}`,
        { data },
        { headers }
      )
        .then(res => {
          this.setState({ message_alert: "La geocerca se ha actualizado exitosamente." })
          this.setState({ showAlert: true });
          console.log(res);
          console.log(res.data);
          this.componentDidMount()
        }).catch(err => {
          console.log('error al enviar data', err);
          this.setState({ message_alert: "No pudimos actualizar la geocerca, intenta nuevamente." });
          this.setState({ showErrAlert: true });
        });


    } else {
      console.log('data post', data);
      axios.post(
        `http://${this.backendNode}/api/geocerca/create`,
        { data },
        { headers }
      )
        .then(re => {
          console.log(re);
          console.log(re.data);
          this.setState({ message_alert: "La geocerca se ha creado exitosamente." })
          this.setState({ showAlert: true });
          this.componentDidMount();
        }).catch(err => {
          this.setState({ message_alert: "No pudimos agregar la geocerca, intenta nuevamente." });
          this.setState({ showErrAlert: true });
          console.log('error al enviar data', err);
        });
    }

    this.setState({ otypeGroup: 0, switchNew: false, geocercaName: '', title_modal: 'Agregar Geocerca' });
  }

  componentDidMount() {

    //  var searchBox = new this.props.google.maps.places.SearchBox(input)

    // var input = document.getElementById('pac-input');
    // var searchBox = new google.maps.places.SearchBox(input);

    axios.get(`http://${this.backendNode}/api/geocerca`)
      .then(res => {
        console.log(res);
        //let id_usr = this.props.User.user.id;
        let all;
        all = res.data.data;
        let newdevices = all.filter(device => device.active == true);

        this.setState({ allGeoCercas: all });
        this.setState({ devices: newdevices });
        console.log(this.state.devices);

      });
  }

  handleOtypeGroup = otypeGroup => {
    this.setState({ otypeGroup });
  }

  onMarkerDragEnd = (coord) => {
    const { latLng } = coord;
    const _lat = latLng.lat();
    const _lng = latLng.lng();
    let new_coords = { lat: _lat, lng: _lng }
    this.setState({ coords: new_coords });
    console.log(new_coords);
  };

  handleShowAlertDelete = event => {
    console.log(event.target.getAttribute('data-item'));
    var id = event.target.getAttribute('data-item');
    this.setState(prevState => ({
      showDeleteAlert: true,
      id_d: id
    }));
  }

  handleDelete(id) {
    this.setState({ showDeleteAlert: false });
    console.log(this.state.id_d);
    axios.put(`http://${this.backendNode}/api/geocerca/update-geoference-delete/${this.state.id_d}`,
      { delete: true, active: false })
      .then(res => {
        console.log(res);
        //this.setState({ message_alert: "El dispositivo fue eliminado exitosamente." });
        // this.setState({ showCreateAlert: true });
        this.componentDidMount();
      });
  }

  handleShowEdition = event => {
    console.log(event.target.getAttribute('data-item'));
    this.setState({ title_modal: 'Actualizar Geocerca' });
    let id = event.target.getAttribute('data-item');
    this.setState({ id_d: id });
    let geocercas = this.state.allGeoCercas.filter(device => device._id == id);
    let _name = geocercas[0].name;
    this.setState({ geocercaName: _name });
    let _coords = geocercas[0].coords;
    this.setState({ coords: _coords });
    let _otype = geocercas[0].otype;
    let _active = geocercas[0].active;
    this.setValueTypeDevice(_otype);
    this.setState({ is_edition: true, switchNew: _active });
    this.tog_center();

    // let _imei = device[0].imei;

    // let _sim = device[0].sim_card;
    // let _otype = device[0].otype;
    // let _model = device[0].model;
    // //console.log(device[0]);
    // this.setState({ imei: _imei });

    // this.setState({ sim: _sim });
    // this.setValueModelDevice(_model);



  }

  setValueTypeDevice = value => {
    this.setState(prevState => ({
      otypeGroup: {
        label: value,
        ...prevState.otypeGroup,
        value
      }
    }));
  };

  updateRadius() {
    if (this.circleRef) {
      this.setState({
        radius: this.circleRef.circle.radius,
      }, () => {
        console.log(this.circleRef.circle.radius);
        console.log(this.circleRef.circle);
        //	this.updateZone();
      });
    }
  }

  handlePlaceChanged() {
    const place = this.autocomplete.getPlace();
    this.props.onPlaceLoaded(place);
    console.log(place);
  }

  fetchPlaces(mapProps, map) {
    const { google } = mapProps;
    const service = new google.maps.places.PlacesService(map);
    console.log(google);
  }

  handleInputChange = () => {
    this.setState({
      query: this.search.value
    });
  };

  handleDeviceFilter(status) {
    this.setState({ GeofenceStatus: status });
    console.log(status)
    let devices = [];
    if (status == 'Activos') {
      devices = this.state.allGeoCercas.filter(device => device.active == true)
    } else if (status == 'Inactivos') {
      devices = this.state.allGeoCercas.filter(device => device.active == false);
    } else {
      devices = this.state.allGeoCercas
    }
    this.setState({ devices });
  }

  handleEventDisable(active_, _id) {
    console.log(_id);
    this.setState({ id_d: _id });
    if (active_ == true) {
      this.setState({ showDisableAlert: true });
    } else {
      this.handleDisable(_id, active_);
    }

  }

  handleDisable(id, status) {
    console.log("ID: ", id);
    console.log(this.state.id_d);
    const newStatus = !status;
    console.log(newStatus);
    this.setState({ showDisableAlert: false })
    axios.put(`http://${this.backendNode}/api/geocerca/update-geoference-active/${this.state.id_d}`,
      { active: newStatus })
      .then(res => {
        console.log(res);
        this.componentDidMount();
        //this.setState({ showDisableAxiosAlert: false })
      });
  }

  onAddressChange = (address) => {
    console.log(address);
    if (address && address.address_components && address.address_components.length > 0) {

      let _lat = address.geometry.location.lat instanceof Function ? address.geometry.location.lat() : address.geometry.location.lat
      let _lon = address.geometry.location.lng instanceof Function ? address.geometry.location.lng() : address.geometry.location.lng
      //console.log(lat, lon);

      let _coords = { lat: _lat, lng: _lon };

      this.setState({ coords: _coords });

      if (this.mapRef) {

        this.mapRef.map.setCenter({
          lat: _lat,
          lng: _lon
        });

        this.mapRef.map.setZoom(16)

      }


      // this.setState({

      //     street: this.getAddressInfo(address, "route"),
      //     numext: this.getAddressInfo(address, "street_number"),
      //     zone_text: this.getAddressInfo(address, "sublocality"),
      //     area: this.getAddressInfo(address, "sublocality"),
      //     city_text: this.getAddressInfo(address, "locality"),
      //     state_text: this.getAddressInfo(address, "administrative_area_level_1"),
      //     country_text: this.getAddressInfo(address, "country"),
      //     zip_code: this.getAddressInfo(address, "postal_code"),
      //     lat: address.geometry.location.lat instanceof Function ? address.geometry.location.lat() : address.geometry.location.lat,
      //     lon: address.geometry.location.lng instanceof Function ? address.geometry.location.lng() : address.geometry.location.lng
      // }, () => this.findStreetObjects())


    }

  }

  render() {

    const { otypeGroup, activeMarker } = this.state;
    const markerCenter = true;
    const showInfoWindow = true;

    const Offsymbol = props => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            fontSize: 12,
            color: "#fff",
            paddingRight: 2
          }}
        >
        </div>
      );
    };

    const OnSymbol = props => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            fontSize: 12,
            color: "#fff",
            paddingRight: 2
          }}
        >
        </div>
      );
    };

    return (
      <React.Fragment>
        <div className="container mt-4">

          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Listado de Geocercas {this.state.GeofenceStatus}</h4>
                  <div style={{ position: 'absolute', top: '15px', right: '25px' }}>

                    <button className="btn btn-info mr-2"
                      onClick={() => this.handleDeviceFilter('Activos')}>
                      Dispositivos Activos
                    </button>

                    <button className="btn btn-secondary mr-2"
                      onClick={() => this.handleDeviceFilter('Inactivos')}>
                      Dispositivos Inactivos
                    </button>


                    <i className="fas fa-plus-circle text-primary"
                      style={{ fontSize: '18px', cursor: 'pointer' }}
                      onClick={this.tog_center}
                      data-toggle="modal"
                      data-item='0'
                      data-target=".bs-example-modal-center">
                    </i>

                  </div>

                  {this.state.devices.length == 0 &&
                    <div className="m-2">
                      <Alert color="secondary">
                        No hay dispositivos
                        </Alert>
                    </div>
                  }
                  {this.state.devices.length > 0 &&
                    <div className="table-responsive">
                      <table className="table table-hover table-centered table-nowrap mb-0">
                        <thead>
                          <tr>
                            {/* <th scope="col">(#) Id</th> */}
                            <th scope="col"> <b>Nombre </b> </th>
                            <th scope="col">Tipo</th>
                            <th scope="col">in</th>
                            <th scope="col">out</th>
                            <th scope="col">Estatus</th>
                            {/* <th scope="col">coordenadas</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.devices.map(device => {
                            return (
                              <tr key={device._id}>
                                {/* <th scope="row">#14256</th> */}
                                <td>{device.name}</td>
                                <td>{device.otype}</td>

                                <td>
                                  <Switch
                                    key={device._id}
                                    uncheckedIcon={<Offsymbol />}
                                    checkedIcon={<OnSymbol />}
                                    onColor="#02a499"
                                    checked={device.in}
                                  />

                                  {/* <Input type="checkbox" value={device.in} /> */}

                                </td>



                                <td>
                                  <Switch
                                    key={device._id}
                                    uncheckedIcon={<Offsymbol />}
                                    checkedIcon={<OnSymbol />}
                                    onColor="#02a499"
                                    checked={device.out}
                                  />
                                </td>



                                <td>
                                  <Switch
                                    key={device._id}
                                    uncheckedIcon={<Offsymbol />}
                                    checkedIcon={<OnSymbol />}
                                    onColor="#02a499"
                                    onChange={() => this.handleEventDisable(device.active, device._id)}
                                    checked={device.active}
                                  />
                                  <SweetAlert
                                    key={device._id + 1}
                                    show={this.state.showDisableAlert}
                                    warning
                                    title="Quieres desactivar el dispositivo?"
                                    onConfirm={() => this.handleDisable(device._id, device.active)}
                                    onCancel={() => this.setState({ showDisableAlert: false })}
                                  > No podrás realizar seguimiento una vez desactivado.
                                </SweetAlert>
                                </td>


                                {/* <td>
                                  Latitud: {device.coords.lat}
                                  <br></br>
                                  Longitud: {device.coords.lng}
                                </td> */}


                                <td>
                                  <div>
                                    <i class="fa fa-edit"
                                      onClick={this.handleShowEdition}
                                      data-item={device._id}
                                      style={{ fontSize: '18px', cursor: 'pointer' }}></i>
                                  </div>
                                </td>

                                <td>
                                  <i
                                    class="fas fa-trash-alt"
                                    data-item={device._id}
                                    style={{ fontSize: '18px', cursor: 'pointer' }}
                                    onClick={this.handleShowAlertDelete}>
                                  </i>

                                  <SweetAlert
                                    key={device._id + 1}
                                    show={this.state.showDeleteAlert}
                                    warning
                                    title="¿Desea eliminar el dispositivo?"
                                    text="SweetAlert in React"
                                    showCancel
                                    confirmBtnText="SI"
                                    cancelBtnText="NO"
                                    confirmBtnBsStyle="danger"
                                    cancelBtnBsStyle="primary"
                                    onConfirm={() => this.handleDelete(device._id)}
                                    onCancel={() => this.setState({ showDeleteAlert: false })}>
                                    Una vez eliminado no podrá recuperarlo.</SweetAlert>
                                </td>

                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Modal
            isOpen={this.state.modal_center}
            toggle={this.tog_center} >
            <div className="modal-header">
              <h5 className="modal-title mt-0"> {this.state.title_modal}</h5>
              <button
                type="button"
                onClick={() => this.setState({ modal_center: false, is_edition: false })}
                className="close"
                data-dismiss="modal"
                aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <form onSubmit={this.handleSubmit}>
                        <AvForm>
                          <AvField
                            name="GeocercaName"
                            label="Nombre"
                            placeholder="Nombre de la geocerca"
                            type="text"
                            value={this.state.geocercaName}
                            errorMessage="Este campo es requerido"
                            validate={{ required: { value: true } }}
                            onChange={this.handleChangeName} />

                          <FormGroup className="mb-0">

                            <Label>Tipo de geocerca</Label>

                            <Select
                              value={otypeGroup}
                              onChange={this.handleOtypeGroup}
                              options={otypeOptions}
                            />

                            <Switch
                              className="my-2"
                              uncheckedIcon={<Offsymbol />}
                              checkedIcon={<OnSymbol />}
                              onColor="#02a499"
                              onChange={() =>
                                this.setState({ switchNew: !this.state.switchNew })
                              }
                              checked={this.state.switchNew}
                            />


                            <br></br>
                            <Button
                              color="primary"
                              className="mr-1"
                              onClick={() =>
                                this.setState({ modal_map: true })
                              }>
                              Definir geocerca
                            </Button>

                            <br></br>
                            <br></br>
                            {/* <Label>
                              Coodenadas: <br></br>
                              Latitud: {this.state.coords.lat}
                              <br></br>
                              Longitud: {this.state.coords.lng}
                            </Label> */}
                            <div className="ml-auto">
                              <Button type="reset"
                                color="secondary"
                                onClick={() =>
                                  this.setState({ modal_center: false, is_edition: false, otypeGroup: 0, switchNew: false, geocercaName: '', title_modal: 'Agregar Geocerca' })
                                }
                                data-dismiss="modal"
                                aria-label="Close">
                                Cancel
                            </Button>
                              {" "}
                              <Button type="submit"
                                color="primary"
                                className="mr-1"
                                onClick={() =>
                                  this.setState({ modal_center: false })
                                }> Submit </Button>
                            </div>
                          </FormGroup>
                        </AvForm>
                      </form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Modal>

          <Modal size="lg"
            style={{ maxWidth: '1000px', width: '80%' }}
            isOpen={this.state.modal_map} toggle={this.tog_map} >
            <div className="modal-header">
              <h5 className="modal-title mt-0">MAPA</h5>
              <button
                type="button"
                onClick={() => this.setState({ modal_map: false })}
                className="close"
                data-dismiss="modal"
                aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div style={{ height: '600px' }} className="modal-body">
              {/* Latitud: {this.state.coords.lat} <br></br>
              Longitud: {this.state.coords.lng}
              <br></br>
              Radio: {this.state.radius} */}
              {/* <input id="pac-input" ref={this.autocompleteInput} id="autocomplete" placeholder="Enter your address"
                type="text"></input> */}

              <div className="form-group">
                <label htmlFor="checkout-company-name">
                  Buscar Direción
                </label>
                <Autocomplete
                  style={{ width: '100%' }}
                  onPlaceSelected={this.onAddressChange}
                  types={'address'}
                  componentRestrictions={{ country: 'mx' }}
                />
              </div>

              <div className="map-container">
                <Map style={{ height: "500px" }}
                  google={this.props.google}
                  ref={ref => this.mapRef = ref}
                  // className="gmaps"

                  zoom={this.state.mapZoom}
                  initialCenter={{
                    lat: this.state.coords.lat,
                    lng: this.state.coords.lng,
                  }}
                  // mapType = "satellite"              
                  mapTypeControl={true}
                  zoomControl={true}
                >
                  <Circle
                    ref={(ref) => this.circleRef = ref}
                    radius={this.state.radius}
                    center={this.state.coords}
                    draggable={false}
                    // onMouseover={() => console.log('mouseover')}
                    // onClick={() => console.log('click')}
                    // onMouseout={() => console.log('mouseout')}
                    // onDragend={(t, map, coord) => this.onMarkerDragEnd(coord)}
                    //onDragend={() => console.log('mouseout')}
                    strokeColor='transparent'
                    strokeOpacity={0}
                    strokeWeight={5}
                    editable={true}
                    fillColor='#FF0000'
                    fillOpacity={0.2}
                    radius_changed={() => { this.updateRadius() }}
                  />

                  <Marker
                    title="Location"
                    id={1}
                    position={this.state.coords}
                    draggable={true}
                    //onDragend={(props, marker) => this.onMarkerClick(props, marker)}
                    onDragend={(t, map, coord) => this.onMarkerDragEnd(coord)}>
                    <InfoWindow visible={showInfoWindow}>
                      <div>
                        <p>Click on the map or drag the marker to select location where the incident occurred</p>
                      </div>
                    </InfoWindow>
                  </Marker>
                </Map>
              </div>

            </div>

            <ModalFooter>

              <Button color="secondary" onClick={() => this.setState({ modal_map: false })} >Cancel</Button>{' '}
              <Button color="primary" onClick={() => this.setState({ modal_map: false })} >ACEPTAR</Button>

            </ModalFooter>

          </Modal>

          {/* ALERT  */}
          <SweetAlert
            show={this.state.showAlert}
            success
            title="Listo!"
            onConfirm={() => this.setState({ showAlert: false })} >
            {this.state.message_alert}
          </SweetAlert>

          <SweetAlert
            show={this.state.showErrAlert}
            danger
            title="Error!"
            onConfirm={() => this.setState({ showErrAlert: false, modal_center: true })}>
            {this.state.message_alert}
          </SweetAlert>

        </div>
      </React.Fragment>
    )
  }

}

const mapStateToProps = (state) => ({
  login: state.Login,
  User: state.User
});

//export default GeocercaView;

// export default withRouter(
//   connect(
//     mapStateToProps,
//     {}
//   )( GoogleApiWrapper({
//       apiKey: "AIzaSyALmiZ2_qsRRMM8UvWaSwot493jLImGrEw",
//       LoadingContainer: LoadingContainer,
//       v: "3"
//     })(GeocercaView)
//   )

export default connect(
  mapStateToProps,
  {}
)(
  GoogleApiWrapper({
    apiKey: "AIzaSyALmiZ2_qsRRMM8UvWaSwot493jLImGrEw",
    LoadingContainer: LoadingContainer,
    v: "3"
  })(GeocercaView)
);
