export default class Order {

    products;

    constructor(products) {
        this.products = products
    }

    setProducts(products){
        this.products = products
    }

    getTotal() {
        return this.products.reduce((total, product) => total + (product.price_rules.price * product.quantity), 0)
    }

    getSubTotal() {
        return this.getTotal() / 1.16
    }
    
    getIVA() {
        return this.getSubTotal() * 0.16
    }

    listAsOrderItems() {        
            const items = this.products.map(product => {
                let orderItem = {
                    "product_id": product.id,
                    "quantity": product.quantity,
                    "price": product.price_rules.price,
                    "comment": ""
                }                
                return orderItem
            })        
            return items
    }

}