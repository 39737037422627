import React, { useState, useEffect, useLayoutEffect } from 'react';
import { Row, Col, Card, CardBody, CardColumns, CardHeader, CardImg, CardTitle, CardSubtitle, CardFooter } from 'reactstrap';

import SelectSearchList from './selectSearchList';

import { Link, Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';

import { getCategoryTags, getTags, postCategoryTags, deleteRelCatTags, getTagsByParam } from '../../../api/api';

function getAndSetTags(setter) {
  getTags().then((res) => {
    if (Array.isArray(res.data)) setter(res.data)
    else setter([])
  }).catch((err) => {
    console.log('---------');
    console.log(err);
    console.log('---------');
  });
}

function getASTags(categoryID, setter) {
  getCategoryTags(categoryID).then((res) => {
    if (Array.isArray(res.data)) {
      // console.log(res.data);
      let arr = []
      res.data.map(x => {
        if (x.tags && !arr.filter(y => y.id === x.tags.id)[ 0 ]) {
          arr.push({
            ...x.tags, relation: x.id
          })
        }
      })
      setter(arr)
    } else if (!res.data || !res.data[ 0 ]) setter([])
  }).catch((err) => {
    console.log(err);
  });
}

const updateCatTags = (
  categoryId = '123',
  tagIds = [],
  loginDetails = {},
  reply = true,
  asArr = [],
  setter = (e) => console.log(e)
) => new Promise(async (resolve, reject) => {
  let a = []
  if (tagIds && tagIds.length > 0) tagIds.map((x, i) => {
    if (!asArr.find(y => y.id === x.id)) a.push({ category_id: categoryId, relation_id: x.id, reply_to_parents: x.reply, order: i, public: true })
  })
  console.log(a);
  if (loginDetails && a && a.length > 0) {
    await postCategoryTags({
      categoryId, tagIds: a, loginDetails, reply
    }).then(resp => {
      if (Array.isArray(resp.data)) {
        // console.log(resp.data);
        let arr = []
        resp.data.map(x => {
          if (x.tags && !arr.filter(y => y.id === x.tags.id)[ 0 ]) {
            arr.push({
              ...x.tags, relation: x.id, order: x.order
            })
          }
        })
        setter([...asArr, ...arr])
      } else if (!resp.data || !resp.data[ 0 ]) setter([])
    }).catch(err => {
      console.log(err);
    })
    resolve()
  }
  else reject()
})

const delRelCatTags = ({
  categoryId = '',
  tagId = [],
  loginDetails = {},
  reply = true,
  setter = () => console.log('hi'),
}) => new Promise((resolve, reject) => {
  /* let a = []
  if (tagIds && tagIds.length > 0) tagIds.map((x) => {
    a.push(x.id)
  })
  console.log(a, a.join(','));
  console.log(loginDetails); */
  deleteRelCatTags({
    categoryId, tagId, loginDetails, reply
  }).then((res) => {
    // console.log(res);
    if (!res) reject()
    else resolve()
  }).catch((err) => {
    console.log('---------');
    console.log(err);
    console.log('---------');
    reject()
  });
})

const searchByParam = (name, categoryId) => new Promise(async (resolve) => {
  resolve(await getTagsByParam({
    term: name, categoryId
  }))
})

export default function CatTags({ props = {}, bindState = {}}) {
  // console.log(props, bindState);
  const [ categoryID, setcategoryID ] = useState(bindState && bindState.categoryID ? bindState.categoryID : props.match.params && props.match.params.categoryId ? props.match.params.categoryId : undefined)
  const [Tags, setTags] = useState([])
  const [ASTags, setASTags] = useState([])
  const [selTags, setselTags] = useState([])
  const [deselTags, setdeselTags] = useState([])
  const [ reply, setReply ] = useState(false)

  const loginDetails = props.login

  useLayoutEffect(() => {
    // if (Tags && Tags.length == 0) getAndSetTags(setTags)
    if (categoryID) getASTags(categoryID, setASTags)
  }, [categoryID])

  useEffect(() => {
    setcategoryID(bindState && bindState.categoryID ? bindState.categoryID : props.match.params && props.match.params.categoryId ? props.match.params.categoryId : undefined)
    // console.log(categoryID);
  }, [ props ])

  useEffect(() => {
    setdeselTags([])
    if (ASTags && ASTags.length > 0) {
      let arr = []
      ASTags.map((x, i) => {
        if (!selTags.find(z => z.id === x.id)) {
          arr.push(x)
        }
      })
      setdeselTags(arr)
    }
  }, [ selTags ])

  useEffect(() => {
    if (ASTags && ASTags.length > 0 && selTags !== ASTags) {
      let arr = []
      ASTags.map(x => {
        if (!selTags.find(z => z.id === x.id)) {
          arr.push(x)
        }
      })
      setselTags(arr)
    }
  }, [ASTags])

  useEffect(() => {
    if (deselTags && deselTags.length > 0 && deselTags !== ASTags) deselTags.map(z => {
      // console.log(z);
      if (z.id && !selTags.find(k => k.id === z.id)) delRelCatTags({
        categoryId: categoryID,
        tagId: z.relation,
        loginDetails,
        reply,
      }).then((res) => {
        setASTags([...ASTags.filter(e => e.id !== z.id)])
      }).catch((err) => {
        console.log(err);
      });
    })
  }, [ deselTags ])

  // console.log(Tags, ASTags, selTags, deselTags);

  return (
    <div className="container-fluid" id="cat_config_container" >
      <Row >
        <Col sm='12' md='12' lg='12' xl='12' >
          <Card>
            <CardHeader className="d-flex">
              <span className="title mr-auto ml-2">
                Etiquetas disponibles
              </span>
            </CardHeader>
            <CardBody>
              <div>
                <span className="d-flex py-2 mb-2">
                  Arrastrar para especificar el orden de aparici&oacute;n
                </span>

                <SelectSearchList
                  loginDetails={ loginDetails }
                  asArr={ ASTags }
                  setasArr={ setASTags }
                  deselArr={ deselTags }
                  selArr={ selTags }
                  setselArr={ setselTags }
                  reply={ reply }
                  setReply={ setReply }
                  searchFunc={ searchByParam }
                  categoryId={ categoryID }
                  saveFunc={ updateCatTags }
                  deleteFunc={ delRelCatTags }
                  placeholder="Selecciona una etiqueta" />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}