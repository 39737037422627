import React from "react";
import { Link } from "react-router-dom";

export default function qakanbanmainnav(props) {
  const baseURL= props.url;
  return (
    <li>
      <Link to="/#" className="has-arrow waves-effect">
        <i style={{ backgroundColor: 'transparent' }}>
          <span className="iconify" data-icon="mdi:bulletin-board"></span>
        </i>
        <span>Kanban</span>
      </Link>
      <ul className="sub-menu" aria-expanded="false">
        <li>
          <Link to="/board/tablero-de-proveedores" className=" waves-effect">
            <span>Provedores</span>
          </Link>
        </li>
        <li>
          <Link to="/board/ordenes-de-compra/" className=" waves-effect">
            <span>Ordenes</span>
          </Link>
        </li>
      </ul>
    </li>
  );
}
