/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';

const qaasmainnav = () => { // Account statements
  return (
    <li>
      <Link to='/#' className='has-arrow waves-effect'>
        <i
          style={{ backgroundColor: 'transparent' }}
          className='icon ion-cube'
          aria-hidden='true'
        >
          <span className='iconify' data-icon='mdi:bulletin-board' />
        </i>
        <span className='text'>Estados de Cuenta</span>
      </Link>
      <ul className='sub-menu' aria-expanded='false'>
        <li>
          <Link to='/credit/sellers/balance-sheet' className=' waves-effect'>
            <i className='fa fa-bar-chart' aria-hidden='true' />
            <span className='text'>Config. Módulo</span>
          </Link>
          <Link to='/credit/sellers/balance-sheet' className=' waves-effect'>
            <i className='fa fa-bar-chart' aria-hidden='true' />
            <span className='text'>Config. Etapas</span>
          </Link>
        </li>
      </ul>
    </li>
  );
};
/*
<li class="has-submenu {{balance_sheet_seller_active}}">
    <a href="#" class="submenu-toggle"><i class="icon ion-cube" aria-hidden="true"></i><span className="text">Estados de Cuenta</span></a>
    <ul class="list-unstyled sub-menu collapse">
        <!--<li><a href="/credit/sellers/dashboard/"><i class="fa fa-bar-chart" aria-hidden="true"></i><span className="text">Dashboard</span></a></li>-->
        <!--<li><a href="/credit/sellers/balance-sheet/"><i class="fa fa-bar-chart" aria-hidden="true"></i><span className="text">Historial</span></a></li>-->
        <!--<li><a href="/credit/sellers/clarifications/"><i class="icon ion-clipboard" aria-hidden="true"></i><span className="text">Aclaraciones</span></a></li>-->
        <!--<li><a href="/sellers/credit-transactions/"><i class="icon ion-clipboard" aria-hidden="true"></i><span className="text">Transacciones</span></a></li>-->
        <li><a href="/credit/sellers/credit-config/1/"><i class="icon ion-clipboard" aria-hidden="true"></i><span className="text">Config. Módulo</span></a></li>
        <li><a href="/credit/sellers/credit-config-stages/1/"><i class="icon ion-clipboard" aria-hidden="true"></i><span className="text">Config. Etapas</span></a></li>
        <!-- Links que se utilizarán para React -->
        <!--<li>
          <a href="{{front_url}}/credit/sellers/balance-sheet/{{user_sessionid}}/{{user_token}}/{{user_ctoken}}">
          <i class="fa fa-bar-chart" aria-hidden="true"></i><span className="text">Historial</span></a></li>-->
        <!--<li>
          <a href="{{front_url}}/credit/sellers/clarifications/{{user_sessionid}}/{{user_token}}/{{user_ctoken}}">
          <i class="icon ion-clipboard" aria-hidden="true"></i><span className="text">Aclaraciones</span></a></li>-->
        <!--<li>
          <a href="{{front_url}}/credit/sellers/credit-transactions/{{user_sessionid}}/{{user_token}}/{{user_ctoken}}">
          <i class="icon ion-clipboard" aria-hidden="true"></i><span className="text">Aclaraciones</span></a></li>-->
    </ul>
</li> */

export default qaasmainnav;
