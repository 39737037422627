import React from "react";
import { Link } from "react-router-dom";

export default function qatransportmainnav(props) {
  const baseURL= props.url;
  return (
    <li>
      <Link to="/#" className="has-arrow waves-effect">
        <i>
          <span
            className="iconify"
            data-icon="whh:shipping"
            data-inline="false"
          ></span>
        </i>
        <span>Módulo Transportes</span>
      </Link>
      <ul className="sub-menu" aria-expanded="false">
        <li>
          <a href={baseURL+"//transport/dashboard/"}>
            <i className="fa fa-bar-chart" aria-hidden="true"></i>{" "}
            <span className="text">Dashboard</span>
          </a>
        </li>
        <li>
          <a href={baseURL+"//transport/gas-consumption/all/"}>
            <i className="ion-waterdrop" aria-hidden="true"></i>
            <span className="text">Consumos</span>
          </a>
        </li>
        <li>
          <a href={baseURL+"//transport/cms/incidents/all/"}>
            <i className="ion-alert-circled" aria-hidden="true"></i>
            <span className="text">Incidencias</span>
          </a>
        </li>
      </ul>
    </li>
  );
}
