import { takeEvery, takeLatest, take, call, fork, put} from 'redux-saga/effects';
import * as actions from './actions';
import * as loginActions from '../auth/login/actionTypes';
// import * as api from '../../api/webFramework_api';
import * as api2 from '../../api/api';

function* getMenu(action) {
    try {
        const loginDetails = action.payload
        const result = yield call(api2.getMasterVars, loginDetails);
        if (result.status == 200) {
            yield put(actions.getMenuSuccess({
                master: result.data
            }));
        } else {
            yield put(actions.menuError({
                error: 'No se pudieron recuperar las configuraciones generales.'
            }))
        }        
    } catch (e) {
        yield put(actions.menuError({
            error: 'Ha ocurrido un error al llamar el api /commons/api/master-vars/'
        }));
    }
}

function* getCollaborator(action) {    
    const loginDetails = action.payload
    yield put(actions.setCollaboratorRequest())
    const result = yield call(api2.getCollaborator, loginDetails)
    if (result.ok && result.data[0]) {
        yield put(actions.setUserData({...result.data[0], isCollaborator: true}))
    } else {
        yield put(actions.setCollaboratorError())
        yield getClient(action)
    }

}

function* getClient(action) {    
    const loginDetails = action.payload
    const result = yield call(api2.getUserData, loginDetails)
    if (result.ok) {
        yield put(actions.setUserData({...result.data[0], isCollaborator: false}))
    } else {

    }
}

function* clearUserData(action) {    
    yield put(actions.clearUserData())    
}


function* watchGetMenuRequest() {
    yield takeEvery(actions.Types.GET_MENU_REQUEST, getMenu);    
}
function* watchGetMenuRequestInLogin() {    
    yield takeEvery(loginActions.LOGIN_SUCCESS, getMenu);
}

function* watchGetCollaborator() {
    yield takeEvery(loginActions.LOGIN_SUCCESS, getCollaborator);
}

function* watchLogout() {    
    yield takeEvery(loginActions.LOGOUT_USER_SUCCESS, clearUserData);
}


// function* getProvider(action) {
//     try {
//         const result = yield call(api.getProvider,{
//             id: action.payload.id,
//             sessionid: action.payload.sessionid,
//             token: action.payload.token,
//             csrftoken: action.payload.csrftoken
//         });
        
//         yield put(actions.getProviderSuccess({
//             provider: result.data
//         }));
//     } catch (e) {
//         yield put(actions.menuError({
//             error: 'Ha ocurrido un error al llamar el api /commons/api/providers/?v=id/'
//         }));
//     }
// }

// function* watchGetProviderRequest() {
//     yield takeEvery(actions.Types.GET_PROVIDER_REQUEST, getProvider);
// }

// function* getOrders(action) {
//     try {
//         const result = yield call(api.getOrders,{
//             id: action.payload.id,
//             sessionid: action.payload.sessionid,
//             token: action.payload.token,
//             csrftoken: action.payload.csrftoken
//         });
        
//         yield put(actions.getOrdersSuccess({
//             orders: result.data
//         }));
//     } catch (e) {
//         yield put(actions.menuError({
//             error: 'Ha ocurrido un error al llamar el api /commons/api/providers/?v=id/'
//         }));
//     }
// }

// function* watchGetOrdersRequest() {
//     yield takeEvery(actions.Types.GET_ORDERS_REQUEST, getOrders);
// }

// function* getBoard (action) {
//     try {
//         const result = yield call(api.getBoard,{
//             filter: action.payload.filter,
//             site_id: action.payload.site_id,
//             module_id: action.payload.module_id,
//             sessionid: action.payload.sessionid,
//             token: action.payload.token,
//             csrftoken: action.payload.csrftoken
//         });
        
//         yield put(actions.getBoardSuccess({
//             board: result.data
//         }));
//     } catch (e) {
//         yield put(actions.menuError({
//             error: 'Ha ocurrido un error al llamar el api /kanban/api/boards/?filter=&site_id=&module_id="'
//         }));
//     }
// }

// function* watchGetBoardRequest() {
//     yield takeEvery(actions.Types.GET_BOARD_REQUEST, getBoard);
// }

// function* getShopConfig(action) {
//     try {
//         const result = yield call(api2.getShopConfig,{
//             id: action.payload.id,
//         });
        
//         yield put(actions.getShopConfigSuccess({
//             shop_config: result.data
//         }));
//     } catch (e) {
//         yield put(actions.menuError({
//             error: 'Ha ocurrido un error al llamar el api /commons/api/master-vars/'
//         }));
//     }
// }

// function* watchGetShopConfigRequest() {
//     yield takeEvery(actions.Types.GET_SHOP_CONFIG_REQUEST, getShopConfig);
// }


// function* updateStatus (action) {
//     try {
//         const result = yield call(api.updateStatus,{
//             id: action.payload.id,
//             status: action.payload.status,
//             sessionid: action.payload.sessionid,
//             token: action.payload.token,
//             csrftoken: action.payload.csrftoken
//         });

//     } catch (e) {
//         yield put(actions.menuError({
//             error: 'Ha ocurrido un error al llamar el api /kanban/api/boards/?filter=&site_id=&module_id="'
//         }));
//     }
// }

// function* watchUpdateStatusRequest() {
//     yield takeEvery(actions.Types.UPDATE_STATUS_REQUEST, updateStatus);
// }

const usersSagas = [
    fork(watchGetMenuRequest),
    fork(watchGetMenuRequestInLogin),
    fork(watchGetCollaborator),
    fork(watchLogout)
    // fork(watchGetShopConfigRequest),
    // fork(watchGetProviderRequest),
    // fork(watchGetBoardRequest),
    // fork(watchUpdateStatusRequest),
    // fork(watchGetOrdersRequest)
];

export default usersSagas;