import React from "react";
import { Link } from "react-router-dom";

export default function qamarketplacesmainnav(props) {
  const baseURL= props.url;
  return (
    <li>
      <Link to="/#" className="has-arrow waves-effect">
        <i style={{backgroundColor:'transparent'}}>
        <span className="iconify" data-icon="ion-ios-cloud-outline" data-inline="false"></span>
        </i>
        <span> Marketplaces</span>
      </Link>
      <ul className="sub-menu" aria-expanded="false">
        <li>
          <a href={"/marketplace/my-companies"}>
            <span className="text">Mis Empresas</span>
          </a>
        </li>
        <li>
          <a href={"/marketplace/companies-web"}>
            <span className="text">Red de empresas</span>
          </a>
        </li>
      </ul>      
    </li>
  );
}
