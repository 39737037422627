export default class Rect {
    punto1
    punto2
    m
    b

    constructor(punto1, punto2) {
        this.punto1 = punto1
        this.punto2 = punto2
        this.calc()
    }

    calc() {
        this.m = (this.punto2.y - this.punto1.y) / 
                (this.punto2.x - this.punto1.x)
        this.b = this.punto2.y - (this.punto2.x * this.m)
    }

    getPointForX(x) {
        let y = (this.m * x) + this.b
        return {x, y}
    }

    getPointForY(y) {        
        let x = (y - this.b) / this.m
        return {x, y}
    }

    /**
     * El modulo es la distancia entre los dos puntos
     */
    getModule() {        
        let modulo = Math.sqrt(
            Math.pow(this.punto2.x - this.punto1.x, 2) 
            + Math.pow(this.punto2.y - this.punto1.y, 2) )
        return modulo
    }

    getVectorUnitario() {        
        let module = this.getModule()
        let punto2MinusPunto1 = {
            x: this.punto2.x - this.punto1.x,
            y: this.punto2.y - this.punto1.y
        }
        return {
            x: punto2MinusPunto1.x / module,
            y: punto2MinusPunto1.y / module
        }
    }
}