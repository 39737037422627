import React from "react";
import { Route, Redirect } from "react-router-dom";

//AUTH related methods
import { getFirebaseBackend } from "../helpers/authUtils";

const AppRoute = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  loginDetails,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      /* const fireBaseBackend = getFirebaseBackend();

      if (isAuthProtected && !fireBaseBackend.getAuthenticatedUser()) {
        return (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        );
      } */
            
      // console.log('Route props', props)
      // console.log('Route isAuthProtected', isAuthProtected)
      // console.log('Route LoginDetails', loginDetails)
      if (isAuthProtected) {
        let rediretToLogin= true
        //Sesión de React
        if (loginDetails && loginDetails.key && loginDetails.sessionid && loginDetails.csrftoken){
          rediretToLogin = false
        } 

        //Sesion por parametros        
        const {match} = props
        if (match && match.params){
          const {csrftoken, sessionid, key} = match.params            

          if (csrftoken && sessionid && key){
            const path = match.url.substring(0, match.url.indexOf(sessionid))
            return (
              <Redirect
                to={{ pathname: "/login-auth-session", state: { 
                  from: props.location,
                  path,
                  login: {
                    csrftoken,
                    sessionid,
                    key
                  }
                } }}
              />
            );
          }
        }
        
        if (rediretToLogin) {
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );
        }
      }

      return (
        <Layout>
          <Component {...props} />
        </Layout>
      );
    }}
  />
);

export default AppRoute;
