import React, { Component } from 'react'

import { Row, Col, Card, CardBody, CardFooter, FormGroup, Button, Label, Input, Modal } from 'reactstrap';
import { AvForm, AvField } from "availity-reactstrap-validation";
import { searchClients } from '../../../api/api';
import NewClient from './NewClient';
import Address from '../objects/Address';

export default class ClientSearch extends Component {

    constructor(props) {
        super(props)
        this.state = {
            query: '',
            clientSelected: null,
            zipCode: '',
            clients: [],
            showNewClientModal: false
        }
    }

    onClientChange(e) {        
        const {onClientChange, onZipCodeChange} = this.props
        const {clients} = this.state
        if (clients ){
            const clientSelected = clients.find(it => it.id == e.target.value)            
            let zipCode = clientSelected.address && clientSelected.address.zip_code ? clientSelected.address.zip_code : '' 
            this.setState({
                clientSelected,
                zipCode
            })
            if (onClientChange){
                onClientChange(clientSelected)
            }

            if (onZipCodeChange){
                onZipCodeChange(zipCode)
            }
        }
    }

    onZipCodeChange(zipCode) {
        const {onZipCodeChange} = this.props
        this.setState({
            zipCode
        }, () => onZipCodeChange(zipCode))
    }

    toggleNewClientModal() {
        this.setState(prevState => ({
            showNewClientModal: !prevState.showNewClientModal,
            editClient: false            
        }));        
      }

    toggleEditClientModal() {
        this.setState(prevState => ({
            showNewClientModal: !prevState.showNewClientModal,
            editClient: true
        }));        
      }
    
    onUpdateSuccess(client) {
        console.log('cliente', client)
        const {clients} = this.state
        const index = clients.findIndex(it => it.id == client.id)
        if (index >= 0) {
            clients[index] = client
        }
        this.setState(prevState => ({
            showNewClientModal: !prevState.showNewClientModal,
            clients
        }), () => this.onClientChange({target:{value: client.id}}));        
    }

    onCreateSuccess(client) {
        console.log('cliente', client)
        const {clients} = this.state
        clients.push(client)        
        this.setState(prevState => ({
            showNewClientModal: !prevState.showNewClientModal,
            clients
        }), () => this.onClientChange({target:{value: client.id}}));        
    }

    render() {        
        const {title} = this.props
        const {clientSelected, zipCode, query, clients, searching} = this.state
        let fullAdrress= ''
        let editButton = null
        if (clientSelected) {
            const address = clientSelected.address ? clientSelected.address : {}
            let addressObject = new Address(address)            
            fullAdrress = addressObject.getFullAddress()

            editButton = (
                <Row>
                    <Col style={{display:'flex', alignItems:'center', margin:'8px'}}>           
                        <h6>{clientSelected.get_full_name ? clientSelected.get_full_name : `${clientSelected.name} ${clientSelected.last_name}`}</h6>
                    </Col>
                    <Col sm={4} style={{marginTop:'8px', marginBottom:'8px'}}>
                        <Button
                            color="info"
                            className="btn btn-info waves-effect waves-light"
                            onClick={() => this.toggleEditClientModal()}
                            >
                            <i className="mdi mdi-account-edit-outline mr-2"></i>
                            Editar
                        </Button>     
                    </Col>
                </Row>                
            )
        }

        let clientsSelecter = null
        if (searching) {
            clientsSelecter = (
                <div style={{display:'flex', flex:1, justifyContent:'center'}}>
                    <div className="spinner-grow text-info m-1" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            )
        } else if (clients && clients.length > 0) {
            const clientOptions = clients.map((client, index) => (
                <option key={index} value={client.id} >{client.get_full_name}</option>
            ))
            clientsSelecter = (
                <div style={{marginTop:'8px', marginBottom:'8px'}}>
                    <Input type="select" name="selectMulti" id="exampleSelectMulti" multiple 
                        onChange={(e) => this.onClientChange(e)}>
                        {clientOptions}
                    </Input>

                    {
                        editButton

                    }                    
                </div>
            )
        }

        const newClientModal = (
            <Modal
                className="modal-lg"
                isOpen={this.state.showNewClientModal}
                toggle={() => this.toggleNewClientModal()}                
                >  
                <div className="modal-header">
                    <h5
                    className="modal-title mt-0"
                    id="myLargeModalLabel"
                    >
                    {this.state.editClient ? 'Editar':'Nuevo cliente'}
                    </h5>
                    <button
                    onClick={() =>
                        this.toggleNewClientModal()
                    }
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    >
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <NewClient 
                        login={this.props.login} 
                        master={this.props.master} 
                        countries={this.props.countries}
                        editing= {this.state.editClient ? clientSelected: null}
                        onUpdateSuccess={(client) => this.onUpdateSuccess(client)}
                        onCreateSuccess={(client) => this.onCreateSuccess(client)}
                        />                                          
                </div>
            </Modal>
        )

        return (
            <Col lg={6}>
                {newClientModal}
                <Card>
                    <CardBody>
                        <Label>{title}</Label>                        
                            <Row>
                                <Col>                                    
                                    <input
                                        className="form-control"
                                        type="search"                                
                                        placeholder= 'Buscar Clientes'
                                        id="search-input-clients"
                                        value={query}
                                        autoComplete="off"
                                        onChange={e => this.onSearchSubmit(e)}                                
                                    />   
                                </Col>
                                <Col sm={4} style={{marginTop:'8px', marginBottom:'8px'}}>
                                    <Button
                                        color="info"
                                        className="btn btn-info waves-effect waves-light"
                                        onClick={() => this.toggleNewClientModal()}
                                        >
                                        <i className="mdi mdi-account-plus-outline mr-2"></i>
                                        Agregar
                                    </Button>   
                                </Col>
                            </Row>
                            {
                                clientsSelecter
                            }                                             
                            <Label>Codigo Postal:</Label>
                            <input
                                className="form-control"
                                type="zipCode"
                                value={zipCode}
                                id="zipCode"
                                disabled
                            />
                            <Label>Direccion:</Label>
                            <Label>{fullAdrress}</Label>
                            {/* <input
                                className="form-control"
                                type="address"
                                value={fullAdrress}
                                id="zipCode"
                                disabled
                            />                             */}
                    </CardBody>
                </Card>
            </Col>
        )
    }


    onSearchSubmit(e) {        
        const query = e.target.value
        this.setState({
            query,
            clientSelected: null,
            zipCode:''
        })
        if (this.searchTimeout) {
            clearTimeout(this.searchTimeout)
        }
        this.searchTimeout = setTimeout(() => {
            this.setState({searching: true})
            searchClients(null, query, this.props.login).then(result => {
                if (result.status == 200) {
                    const clients = [...result.data.results]                    
                    this.setState({
                        clients
                    })
                }
                this.setState({searching: false})
            }).catch(error => console.error('Error', error))            
        }, 1000);
    }

}