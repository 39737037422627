import React from 'react'
import { Component } from 'react';
import { connect } from 'react-redux';
import { getBoards } from '../../api/api';

import ProvidersBoard from "../../pages/Kanban/providerBoard"
import OrdersBoard from '../../pages/Kanban/ordersBoard'


class AdminBoards extends Component {

    constructor(props) {
        super(props)
        this.state = {}
    }

    componentDidMount() {
        // const {match} = this.props
        // const {slugBoard} =  match.params
        // if (slugBoard) {
        //     this.selectBoardIfExist(slugBoard)
        // }
    }

    // selectBoardIfExist(slugBoard) {
    //     const {login} = this.props
    //     if (login && login.key) {
    //         getBoards(login).then(result => {
    //             if (result.status === 200) {
                    
    //             }
    //         })

    //     }
    // }

    render() {        
        const {slugBoard} =  this.props.match.params
        switch (slugBoard) {
            case 'tablero-de-proveedores':                
                return <ProvidersBoard />        
            case 'ordenes-de-compra':                
                return <OrdersBoard />        
            default:
                break;
        }
        return (
            <div className='text-center mt-2'>
                No exite el tablero
            </div>
        )
    }
}

const mapStatetoProps = (state) => {
    return { 
      login: state.Login,
      ...state.User,
    };
  };
  
  export default connect(mapStatetoProps)(AdminBoards);