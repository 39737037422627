import React, { Component } from 'react'

import { Link, Redirect } from 'react-router-dom';
import { Row, Col, Card, CardBody, CardFooter } from 'reactstrap';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import MainPOS from './components/MainPOS';
import { connect } from 'react-redux';
import { getDeliveryTypes, getPackageServiceConfig } from '../../api/api';
import { toast } from 'react-toastify';

class POS extends Component {

    constructor(props) {
        super(props)
        this.state = {  
            redirect: null,
            showNoGuides: false         
        }
    }

    componentDidMount() {
        this.checkSession()
        this.checkPackageServiceConfigs()
    }

    checkSession() {
        const {login} = this.props     
        if (login) {
            getDeliveryTypes(login).then(result => {
                if (result.status == 403) {
                    //Logout 
                    toast.info('Su sesión caducó.')
                    this.setState({redirect: "/logout"})                    
                }    
            }).catch(error => console.error('Error', error))
        } else {
            this.setState({redirect: "/logout"})
        }
    }

    checkPackageServiceConfigs() {
        const {login} = this.props     
        if (login) {
            getPackageServiceConfig(login).then(result => {
                if (result.status == 200) {
                    let packageServiceConfigs= result.data
                    let service =  packageServiceConfigs.find(it => (it.available_guides > 0))                    
                    this.setState({showNoGuides: service ? false : true})                    
                }    
            }).catch(error => console.error('Error', error))
        }
    }

    render() {
        const {redirect, showNoGuides} = this.state
        if (redirect) {
            return (
                <Redirect to={redirect} />
            )
        }
        const {master} = this.props
        let googleMapKey = null
        let shipping = false
        if (master && master[0]) {
            googleMapKey = master[0].google_maps_api
            shipping = master[0].shipping
        }
        return (
            <React.Fragment>
                <HelmetProvider>                    
                    <Helmet>                        
                        <script type="text/javascript" src={`https://maps.googleapis.com/maps/api/js?key=${googleMapKey}&libraries=places`}></script>                    
                    </Helmet>                        
                    <div className="container-fluid">
                        <Row className="align-items-center">
                            <Col sm={6}>
                                <div className="page-title-box">
                                    <h4 className="font-size-18">Punto de Venta</h4>
                                    <ol className="breadcrumb mb-0">
                                        <li className="breadcrumb-item active">
                                            Ventas / Punto de Venta
                                        </li>
                                    </ol>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        {
                                            shipping && !showNoGuides?
                                            <MainPOS />
                                            :
                                            <Col>
                                            {
                                                showNoGuides ? 
                                                <span> Guías agotadas, consultar con Administrador</span>
                                                :
                                                <span> No tiene permiso para ingresar a esta área.</span>
                                            }
                                            </Col>
                                        }
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </HelmetProvider>
            </React.Fragment>
        )
    }

}

const mapStateToProps = (state) => ({
    master: state.User.master,
    login: state.Login
});

export default connect(mapStateToProps, null)(POS);