import React, { forwardRef } from 'react'
import AsyncSelect, { components } from 'react-select';
import {
  SortableContainer,
  SortableElement,
  sortableHandle,
} from 'react-sortable-hoc';

const promiseOptions = (inputValue = '', searchFunc, catId, typoLimit = 3) =>
  new Promise(async(resolve, reject) => {
    if (inputValue && inputValue.length >= typoLimit) {
      let res = await searchFunc(inputValue, catId).catch(err => {
        console.log(err)
        reject(false)
      })
      // console.log(res);
      resolve(res);
    } else reject(false)
  });

function arrayMove(array, from, to) {
  array = array.slice();
  array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
  return array;
}

const SortableMultiValue = SortableElement(props => {
  // this prevents the menu from being opened/closed when the user clicks
  // on a value to begin dragging it. ideally, detecting a click (instead of
  // a drag) would still focus the control and toggle the menu, but that
  // requires some magic with refs that are out of scope for this example
  const onMouseDown = e => {
    e.preventDefault();
    e.stopPropagation();
  };
  const innerProps = { ...props.innerProps, onMouseDown };
  return <components.MultiValue {...props} innerProps={innerProps} />;
});

const SortableMultiValueLabel = sortableHandle(props => (
  <components.MultiValueLabel {...props} />
));

const SortableSelect = SortableContainer(AsyncSelect);

const filterVal = (inputVal, filterArr) => {
  return [...filterArr].filter(x => x.name == inputVal)[0]
}

const loadOptions = (inputValue, callback) => {
  /* console.log(inputValue, callback); */
  callback(filterVal(inputValue))
}

export default function MultiSelectSort({
  defaultArr,
  values,
  valueSet,
  searchFunc,
  catId,
  removeFunc,
  placeholder,
  typoLimit = 3
}) {
  const [ selected, setSelected ] = React.useState(values);
  const [ search, setsearch ] = React.useState('')
  const [ optionsArr, setoptionsArr ] = React.useState([])

/*   console.log(search, optionsArr, selected, values); */

  React.useEffect(() => {
    if (selected !== values) setSelected(values)
  }, [ values ])

  React.useEffect(() => {
    const a = setTimeout(async () => {
      if (search && search.length > 2) {
        // console.log('typoLimit: ', typoLimit);
        promiseOptions(search, searchFunc, catId, typoLimit || 3)
          .then((res) => {
            // console.log(res);
            if (res && res.data && Array.isArray(res.data) && !res.data.results) {
              setoptionsArr(res.data)
            } else if (res && res.data && Array.isArray(res.data.results)) {
              setoptionsArr(res.data.results)
            } else setoptionsArr([])
          }).catch((err) => {
            console.log(err);
          });
      }
    }, 750);
    return () => {
      clearTimeout(a)
    }
  }, [ search ])

  React.useEffect(() => {
    let a = []
    if (selected && selected.length >= 0) selected.map((x, i) => {
      a.push({...x, order: i})
    })
    valueSet(a)
  }, [ selected ])

  const handleChange = (e, a) => {
    /* console.log(e, a); */
    setsearch(e)
  }

  const onChange = selectedOptions => {
    setSelected(selectedOptions)
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newValue = arrayMove(selected, oldIndex, newIndex);
    setSelected(newValue);
    /* console.log(
      'Values sorted:',
      newValue.map(i => i.value)
    ); */
  };

  return (
    <SortableSelect
      useDragHandle
      // react-sortable-hoc props:
      axis="xy"
      onSortEnd={onSortEnd}
      distance={4}
      // small fix for https://github.com/clauderic/react-sortable-hoc/pull/352:
      getHelperDimensions={({ node }) => node.getBoundingClientRect()}
      // react-select props:
      isMulti={ true }
      placeholder={placeholder}
      cacheOptions
      defaultOptions={ selected }
      defaultArr={ defaultArr }
      options={ optionsArr }
      value={ selected }
      onInputChange={ handleChange }
      onChange={ onChange }
      getOptionValue={opt => opt.id}
      getOptionLabel={opt => opt.name}
      components={{
        MultiValue: SortableMultiValue,
        MultiValueLabel: SortableMultiValueLabel,
      }}
      closeMenuOnSelect={false}
    />
  );
}