import React , {Component} from 'react'
import { Link } from 'react-router-dom'

export default class Paginator extends Component {

    onPreviusClick = (e) => {
        e.preventDefault()
        const {onPreviusClick, onNextClick, currentPage} = this.props
        if (onPreviusClick){
            onPreviusClick()
        }
    }

    onNextClick = (e) => {
        e.preventDefault()
        const {onNextClick, currentPage} = this.props
        if (onNextClick){
            onNextClick()
        }
    }

    onPageClick(e, pageSelected) {
        console.log('onPageClick')
        e.preventDefault()
        const {onPageClick} = this.props
        if (onPageClick){
            onPageClick(pageSelected)
        }
    }

    renderPreviusPages(currentPage, totalPages) {
        const pages=[]
        for (let i = Math.max(currentPage - 4, 0); i < currentPage - 1; i++) {
            pages.push(
                <li className="page-item">
                    <Link className="page-link" to="#" onClick={(e) => this.onPageClick(e, i)}>{i+1}</Link>
                </li>
            )
        }  
        return pages      
    }

    renderNextPages(currentPage, totalPages) {
        const pages=[]        
        for (let i = Math.max(currentPage, 0); i < currentPage + 3 && i < totalPages; i++) {
            pages.push(
                <li className="page-item">
                    <Link className="page-link" to="#" onClick={(e) => this.onPageClick(e, i)}>{i+1}</Link>
                </li>
            )
        }
        return pages
    }

    render(){
        const {currentPage, totalPages} = this.props        
        return(
            <nav aria-label="...">
                <ul className="pagination pagination-sm">
                    <li className="page-item">
                        <Link className="page-link" to="#" onClick={this.onPreviusClick}>Anterior</Link>
                    </li>     
                    {
                        this.renderPreviusPages(currentPage, totalPages)
                    }               
                    <li className="page-item active">
                        <span className="page-link">
                            {currentPage}
                            <span className="sr-only">(current)</span>
                        </span>
                    </li>                    
                    {
                        this.renderNextPages(currentPage, totalPages)
                    }               
                    <li className="page-item">
                        <Link className="page-link" to="#" onClick={this.onNextClick}>Siguiente</Link>
                    </li>
                </ul>
            </nav>
        )
    }
}