import React, { useState, useEffect, useLayoutEffect } from 'react';
import { Row, Col, Card, CardBody, CardHeader, CardImg, CardTitle, Form,FormGroup, Label, Input } from 'reactstrap';

import { Link, Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';

import SelectSearchList from './selectSearchList';

import { getCategoryBrands, getBrands, postCategoryBrands, deleteRelCatBrands, getBrandsByParam } from '../../../api/api';

function getAndSetBrands(setter) {
  getBrands().then((res) => {
    if (Array.isArray(res.data)) setter(res.data)
    else setter([])
  }).catch((err) => {
    console.log('---------');
    console.log(err);
    console.log('---------');
  });
}

function getASBrands(categoryID, setter) {
  getCategoryBrands(categoryID).then((res) => {
    if (Array.isArray(res.data)) {
      // console.log(res.data);
      let arr = []
      res.data.map(x => {
        if (x.brand && !arr.filter(y => y.id === x.brand.id)[ 0 ]) {
          arr.push({
            ...x.brand, relation: x.id
          })
        }
      })
      setter(arr)
    } else if (!res.data || !res.data[ 0 ]) setter([])
    else setter([])
  }).catch((err) => {
    console.log('---------');
    console.log(err);
    console.log('---------');
  });
}

const updateCatBrands = (
  categoryId = '123',
  brandIds = [],
  loginDetails = {},
  reply = true,
  asArr = [],
  setter = (e) => console.log(e)
) => new Promise(async (resolve, reject) => {
  let a = []
  if (brandIds && brandIds.length > 0) brandIds.map((x, i) => {
    if (!asArr.find(y => y.id === x.id)) a.push({ category_id: categoryId, relation_id: x.id, reply_to_parents: x.reply, order: i, public: true })
  })
  if (loginDetails && a && a.length > 0) {
    await postCategoryBrands({
      categoryId, brandIds: a, loginDetails, reply
    }).then(resp => {
      if (Array.isArray(resp.data)) {
        // console.log(resp.data);
        let arr = []
        resp.data.map(x => {
          if (x.brand && !arr.filter(y => y.id === x.brand.id)[ 0 ]) {
            arr.push({
              ...x.brand, relation: x.id
            })
          }
        })
        // console.log([...asArr, ...arr]);
        setter([...asArr, ...arr])
      } else if (!resp.data || !resp.data[ 0 ]) setter([])
    }).catch(err => {
      console.log(err);
    })
    resolve()
  }
  else reject()
})

const delRelCatBrands = ({
  categoryId = '',
  brandId = [],
  loginDetails = {},
  reply = true,
  setter = () => console.log('hi'),
}) => new Promise((resolve, reject) => {
  /* let a = []
  if (brandIds && brandIds.length > 0) brandIds.map((x) => {
    a.push(x.id)
  })
  console.log(a, a.join(','));
  console.log(loginDetails); */
  deleteRelCatBrands({
    categoryId, brandId, loginDetails, reply
  }).then((res) => {
    // console.log(res);
    if (!res) reject()
    else resolve()
  }).catch((err) => {
    console.log('---------');
    console.log(err);
    console.log('---------');
    reject()
  });
})

const searchByParam = (name, categoryId) => new Promise(async (resolve) => {
  resolve(await getBrandsByParam({
    term: name, categoryId
  }))
})

export default function CatBrands({ props = {}, bindState = {}}) {
  // console.log(props, bindState);
  const [ categoryID, setcategoryID ] = useState(bindState && bindState.categoryID ? bindState.categoryID : props.match.params && props.match.params.categoryId ? props.match.params.categoryId : undefined)
  const [ brands, setBrands ] = useState([])
  const [ ASBrands, setASBrands ] = useState([])
  const [ selbrands, setselBrands ] = useState([])
  const [ deselbrands, setdeselBrands ] = useState([])
  const [ reply, setReply ] = useState(true)

  const loginDetails = props.login

  useLayoutEffect(() => {
    // if (brands.length == 0) getAndSetBrands(setBrands)
    if (categoryID) getASBrands(categoryID, setASBrands)
  }, [categoryID])

  useEffect(() => {
    setcategoryID(bindState && bindState.categoryID ? bindState.categoryID : props.match.params && props.match.params.categoryId ? props.match.params.categoryId : undefined)
    // console.log(categoryID);
  }, [ props ])

  useEffect(() => {
    setdeselBrands([])
    if (ASBrands && ASBrands.length > 0) {
      let arr = []
      ASBrands.map((x, i) => {
        if (!selbrands.find(z => z.id === x.id)) {
          arr.push(x)
        }
      })
      setdeselBrands(arr)
    }

  }, [ brands, selbrands ])

  useEffect(() => {
    if (ASBrands && ASBrands.length > 0 && selbrands !== ASBrands) {
      let arr = []
      ASBrands.map(x => {
        if (!selbrands.find(z => z.id === x.id)) {
          arr.push(x)
        }
      })
      setselBrands([...selbrands, ...arr])
    }
  }, [ASBrands])

  useEffect(() => {
    if (deselbrands && deselbrands.length > 0 && deselbrands !== ASBrands) deselbrands.map(z => {
      // console.log(z.id);
      if (z.id && !selbrands.find(k => k.id === z.id)) delRelCatBrands({
        categoryId: categoryID,
        brandId: z.relation,
        loginDetails,
        reply,
      }).then((res) => {
        setASBrands([...ASBrands.filter(e => e.id !== z.id)])
      }).catch((err) => {
        console.log(err);
      });
    })
  }, [ deselbrands ])

  // console.log(brands, ASBrands, selbrands, 'deselbrands:', deselbrands);

  return (
    <div className="container-fluid" id="cat_config_container" >
      <Row >
        <Col sm='12' md='12' lg='12' xl='12' >
          <Card>
            <CardHeader className="d-flex">
              <span className="title mr-auto ml-2">
                Marcas disponibles
              </span>
            </CardHeader>
            <CardBody>
              <div>
                <span className="d-flex py-2 mb-2">
                  Arrastrar para especificar el orden de aparici&oacute;n
                </span>

                <SelectSearchList
                  loginDetails={ loginDetails }
                  asArr={ ASBrands }
                  setasArr={ setASBrands }
                  deselArr={ deselbrands }
                  selArr={ selbrands }
                  setselArr={ setselBrands }
                  reply={ reply }
                  setReply={ setReply }
                  searchFunc={ searchByParam }
                  categoryId={ categoryID }
                  saveFunc={ updateCatBrands }
                  deleteFunc={ delRelCatBrands }
                  placeholder="Selecciona una marca" />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}