import React from "react";
import { Link } from "react-router-dom";

export default function qaofficemainnav(props) {
  const baseURL= props.url;
  return (
    <li>
      <Link to="/#" className="has-arrow waves-effect">
        <i style={{backgroundColor:'transparent'}}><span className="iconify" data-icon="ion:earth" data-inline="false"></span></i>
        <span> Sucursales / Regiones</span>
      </Link>
      <ul className="sub-menu" aria-expanded="false">
   
        <li><a href={baseURL+"/commons/cms/offices/"}><span className="text">Sucursales</span></a></li>
            <li><a href={baseURL+"/commons/cms/regions/"}><span className="text">Regiones</span></a></li>
            <li><a href={baseURL+"/commons/cms/metropolitan-zones/"}><span className="text">Zonas Metropolitanas</span></a></li>
            <li><a href={baseURL+"/commons/cms/zones/"}><span className="text">Zonas</span></a></li>
            <li><a href={baseURL+"/commons/cms/geographies/"}><span className="text">Coberturas</span></a></li>
            <li><a href={baseURL+"/travel/cms/rooms/"}><span className="text">Mis Habitaciones</span></a></li>
      </ul>
    </li>
  );
}
