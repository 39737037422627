import React, { Component } from 'react'

import { Row, Col, Card, CardBody, CardFooter, FormGroup, Button, Label, Modal, Spinner } from 'reactstrap';
import { AvForm, AvField } from "availity-reactstrap-validation";

import { getClients, createBuyOrder, getMasterConfigs, getShopConfigs, createGuides, getCountries, baseURL } from '../../../api/api';
import { connect } from 'react-redux';
import {toast} from 'react-toastify'
import moment from 'moment'
import ClientSelecter from './ClientSelecter';
import ProductsSelecter from './ProductsSelecter';
import DeliveryQuote from './DeliveryQuote';
import Order from '../objects/Order';
import AvGroup from 'availity-reactstrap-validation/lib/AvGroup';
import AvInput from 'availity-reactstrap-validation/lib/AvInput';
import ClientSearch from './ClientSearch';
import SweetAlert from 'react-bootstrap-sweetalert';
import Address from '../objects/Address';

class CotizadorPage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            clients: [               
            ],         
            productsSelected: [],
            comment:'',
            acceptCheck: false,
            control: '', 
            guia: '', 
            rastreo: '',

            shopConfigs:{},    
            
            //sucess
            orderDetails: {id:17541},
            success_msg: false,
            key: new Date().getTime(),
            countries:[],

            //warning
            showWarning: false,
            warningTitle: 'Advertencia',
            warningMessage: 'Mensaje',

            processing: false,

            //pdf in alert
            pdf: '',
        }
    }

    componentDidMount() {
        this.fetchShopConfigs()  
        this.fetchCountries()        
    }

    fetchShopConfigs() {        
        const {master} = this.props
        if (master && master.length > 0) {
            getShopConfigs(master[0].id).then(result => {
                if (result.status == 200) {
                    this.setState({
                        shopConfigs: result.data
                    })
                }
            }).catch(error => console.log('Error', error))
        } else {
            setTimeout(() => {
                this.fetchShopConfigs()
            }, 1000);
        }
    }

    fetchCountries() {
        getCountries().then(result => {
            if (result.status === 200) {
                this.setState({
                    countries: result.data
                })
            }
        }).catch(error => console.log('Error', error))
    }

    render() {        
        console.log('renderMain', this.state.clientSource)
        const {clients, comment, acceptCheck, control, guia, rastreo, shopConfigs} = this.state

        const orderDetails = (
            <Col lg={6}>
                <Card>
                    <CardBody>                        
                        <AvForm>                                          
                            <Col>
                                <AvField
                                    name="comment"
                                    label="Comentario:"
                                    validate={{
                                        maxLength: { value: 25, errorMessage: "Máximo 30 carácteres." }
                                    }}
                                    value={comment}
                                    onChange={e => this.setState({comment: e.target.value})}
                                />                            
                            </Col>
                            <Col>    
                                <AvGroup check>
                                    <AvInput type="checkbox" name="checkItOut" 
                                        value = {acceptCheck}
                                        onChange={(e) => this.setState({acceptCheck: !acceptCheck})} />
                                    <Label check for="checkItOut">El usuario acepto inspección:</Label>
                                </AvGroup>                                
                            </Col>
                        </AvForm>
                    </CardBody>
                </Card>
            </Col>
        )
        
        const deliveryConfigs = (
            <Col lg={6}>
                <Card>
                    <CardBody>                        
                        <AvForm>                                          
                            <Col>
                                <AvField
                                    name="control"
                                    label="Control:"                                                                        
                                    value={control}  
                                    onChange={e => this.setState({control: e.target.value})}                     
                                />                            
                            </Col>                            
                            <Col>
                                <AvField
                                    name="guia"
                                    label="Guia:"                                                                        
                                    value={guia}  
                                    onChange={e => this.setState({guia: e.target.value})}                     
                                />                            
                            </Col>                            
                            <Col>
                                <AvField
                                    name="rastreo"
                                    label="Rastreo:"                                                                        
                                    value={rastreo}  
                                    onChange={e => this.setState({rastreo: e.target.value})}                     
                                />                            
                            </Col>                            
                        </AvForm>
                    </CardBody>
                </Card>
            </Col>
        )

        return (
            <React.Fragment key={this.state.key}>

                {this.state.success_msg ? (
                    <SweetAlert
                      title="¡Pedio generado!"
                      success
                      showCancel
                      confirmBtnBsStyle="success"
                      confirmBtnText="Ver Detalles"
                      cancelBtnBsStyle="primary"
                      cancelBtnText="Aceptar"
                      onConfirm={() => this.dismissAnGoToDetails()}
                      onCancel={() => this.dismissAndClearForms()}
                    >
                      Su pedio fue generado correctamente
                    </SweetAlert>
                  ) : null}

                {
                    this.state.showWarning && (
                        <SweetAlert
                            warning                            
                            confirmBtnText="Cerrar"
                            confirmBtnBsStyle="danger"
                            title={this.state.warningTitle}
                            onConfirm={() => this.setState({showWarning: false})}                            
                        >
                            {this.state.warningMessage}
                        </SweetAlert>
                    )
                }

                <Row>
                    <ClientSearch 
                        login={this.props.login} 
                        master={this.props.master[0]} 
                        countries={this.state.countries}
                        title='Origen' 
                        onClientChange={(clientSelected) => this.onClientSourceSelected(clientSelected)} 
                        onZipCodeChange={(zipCode => this.onSourceZipCodeChange(zipCode))}/>
                    <ClientSearch 
                        login={this.props.login} 
                        master={this.props.master[0]} 
                        countries={this.state.countries}
                        title='Destino' 
                        onClientChange={(clientSelected) => this.onClientTargetSelected(clientSelected)} 
                        onZipCodeChange={(zipCode => this.onTargetZipCodeChange(zipCode))}/>
                </Row>                

                <Row>
                    <ProductsSelecter 
                        login={this.props.login} 
                        client={this.state.clientSource}
                        productsSelectedChange={(products) => this.setProductSelected(products)}/>
                </Row>

                <Row>
                    {orderDetails}
                    {shopConfigs.packing && deliveryConfigs}
                </Row>
                {/* <Row>
                    <PayMethods />
                </Row> */}

                <Row>
                    <Col md={12}>
                     <Card>
                        <CardBody> 
                            <DeliveryQuote 
                                zipCodeSource={this.state.zipCodeSource} 
                                zipCodeTarget={this.state.zipCodeTarget}
                                onQuoteSelected={(quote) => this.setState({deliveryQuote: quote})}
                                onOcurreChange={(ocurre) => this.setState({ocurre})}
                                quoteSelected = {this.state.deliveryQuote}
                                />
                        </CardBody>
                     </Card>
                    </Col>
                </Row>

                <Row>
                    <Col>
                     <Card>
                        <CardBody> 
                            {
                                this.state.processing ?
                                    <Spinner color="secondary" />
                                :
                                    <Button
                                        color="success"
                                        className="btn btn-success waves-effect waves-light"
                                        onClick={() => this.onAceptarPress()}
                                        >
                                        Aceptar
                                    </Button>                            
                            }
                        </CardBody>
                     </Card>
                    </Col>
                </Row>
                
                
            </React.Fragment>
        )
    }


    onClientSourceSelected(clientSelected) {
        this.setState({
            clientSource: clientSelected
        })
    }

    onClientTargetSelected(clientSelected) {
        this.setState({
            clientTarget: clientSelected
        })
    }
    
    onSourceZipCodeChange(zip_code){        
        this.setState({
            zipCodeSource: zip_code            
        })
    }

    onTargetZipCodeChange(zip_code){
        this.setState({
            zipCodeTarget: zip_code
        })
    }

    setProductSelected(productsSelected) {        
        this.setState({productsSelected})
    }

    async onAceptarPress() {        
        const {login} = this.props

        if (login && login.key) {

            const {clientSource, clientTarget, productsSelected, deliveryQuote} = this.state
            if (!clientSource){
                toast.warn('Falta el cliente origen.')
                return
            }
            if (!clientTarget){
                toast.warn('Falta el cliente destino.')
                return
            }
            if (!this.isAdressValid(clientSource.address)){
                toast.warn('Falta información en la dirección origen: ' + this.getInvalidFieldsAdress(clientSource.address))
                return
            }
            if (!this.isAdressValid(clientTarget.address)){
                toast.warn('Falta información en la dirección destino: ' + this.getInvalidFieldsAdress(clientTarget.address))
                return
            }
            if (productsSelected && productsSelected.length == 0){
                toast.warn('No tiene productos seleccionados')
                return
            }
            if (!deliveryQuote){
                toast.warn('Faltan "Datos del Envio"')
                return
            }

            this.setState({processing: true})
            const resultCreateGuide = await this.createGuide()
            console.log('createGuide', resultCreateGuide)

            if (resultCreateGuide.status === 200 && resultCreateGuide.data.tracking_number) {
                const orderData = this.createOrderData(resultCreateGuide.data)            
                createBuyOrder(orderData, login).then(result => {
                    console.log('createBuyOrder', result)
                    this.setState({processing: false})
                    if (result.status == 201) {
                        this.setState({
                            success_msg: true,
                            orderDetails: result.data,
                            pdf: resultCreateGuide.data.pdf
                        })
                    } else {
                        toast.error('No se pudo registrar la orden de compra. ')
                        if (result.status == 400)
                            toast.error(JSON.stringify(result.data))
                        else 
                            toast.error(result.problem)
                    }    
                }).catch(error => console.error('Error', error))
            } else {
                this.setState({processing: false})
                toast.warn('No se pudo crear la guia del pedido.')                            
                this.setState({
                    showWarning: true,
                    warningTitle:'No se pudo crear la guia del pedido',
                    warningMessage: `Detalles:\n${JSON.stringify(resultCreateGuide.data)}`
                })
            }

        }
    }

    async createGuide(order) {
        const {clientSource, clientTarget, productsSelected, deliveryQuote, ocurre, comment} = this.state
        const {login} = this.props
        const addressSource = new Address(clientSource.address)
        const addressTarget = new Address(clientTarget.address)
        const aditionalInfo = ocurre && comment ? `Ocurre. ${comment.substring(0,16)}` : comment ? comment : "---"

        let guide = {
            "aditionalInfo": aditionalInfo,
            "content": productsSelected[0].name,
            "contentDescription": productsSelected[0].headline ? productsSelected[0].headline : productsSelected[0].name,
            "deliveryToEstafetaOffice": false,
            "destinationCountryId": "MX",
            // "costCenter": "COST123",
            // "officeNum": 130,
            // "serviceTypeId": "70",
            // "reference": "12345GDFGS",
            "originZipCodeForRouting": clientSource.address.zip_code,
            "parcelTypeId": deliveryQuote.TipoEnvio.EsPaquete ? 4: 1,
            "returnDocument": false,
            //"effectiveDate": efectiveDate,
            "weight": deliveryQuote.TipoEnvio.Peso,
            "length": deliveryQuote.TipoEnvio.Largo,
            "width": deliveryQuote.TipoEnvio.Ancho,
            "height": deliveryQuote.TipoEnvio.Alto,

            "src_address1": addressSource.getStreet(),
            "src_address2": addressSource.getNumExt(),
            "src_city": addressSource.getCityString(),
            "src_contactName": clientSource.get_full_name ? clientSource.get_full_name : `${clientSource.name} ${clientSource.last_name}`,
            "src_neighborhood": addressSource.getNeighborhood(),
            "src_state": addressSource.getStateString(),
            "src_zipCode": addressSource.getZipCode(),
            "src_corporateName": clientSource.company ? clientSource.company : '',
            "src_customerNumber": "0000000",
            "src_externalNum": addressSource.getNumExt(),


            "tgt_address1": addressTarget.getStreet(),
            "tgt_address2": addressTarget.getNumExt(),
            "tgt_city": addressTarget.getCityString(),
            "tgt_contactName": clientTarget.get_full_name ? clientTarget.get_full_name : `${clientTarget.name} ${clientTarget.last_name}`,
            "tgt_neighborhood": addressTarget.getNeighborhood(),
            "tgt_state": addressTarget.getStateString(),
            "tgt_zipCode": addressTarget.getZipCode(),
            "tgt_corporateName": clientTarget.company ? clientTarget.company : '',
            "tgt_customerNumber": "0000000",
            "tgt_externalNum": addressTarget.getNumExt(),
            
        }
        if (this.isPhoneNumberValid(clientSource.tel_home)){
            guide = {...guide, "src_phoneNumber": clientSource.tel_home}
        }
        if (this.isPhoneNumberValid(clientSource.tel_mobile)){
            guide = {...guide, "src_CellPhone": clientSource.tel_mobile}
        }
        if (this.isPhoneNumberValid(clientTarget.tel_home)){
            guide = {...guide, "tgt_phoneNumber": clientTarget.tel_home}
        }
        if (this.isPhoneNumberValid(clientTarget.tel_mobile)){
            guide = {...guide, "tgt_CellPhone": clientTarget.tel_mobile}
        }
        let data = {
            "quadrant": 0,
            "paperType": 1,
            "suscriberId": 28,
            "pickup": ocurre ? true : false,
            "serviceTypeId": deliveryQuote.TipoServicio.wfPackageServiceType.service.id,            
            "guides": [
                guide                
            ]
        }
        if (order) {
            data = {
                ...data,                
                "order_id": order.id                
            }
        }
        
        const result = await createGuides(data, login)        
        return result        
    }

    isPhoneNumberValid(phone) {
        // let phoneValidator = /^[0-9\-\+]{5,15}$/
        // return phoneValidator.test(phone)
        if (phone && phone.length > 0)
            return true
        return false
    }

    createOrderData(guideData) {        
        const { 
            clientSource, 
            clientTarget, 
            productsSelected, 
            deliveryQuote, 
            comment, 
            acceptCheck,
            control, 
            guia, 
            rastreo
        } = this.state
        const {user} = this.props
        const order = new Order(productsSelected)
        const deliveryCost = 0, requieres_invoice = false
        const currentDate = new Date()
        const orderItems = order.listAsOrderItems()        
        let orderObject = {
            "collaborator_id": user.id,
            // "storage_id": 299,
            "client_id": clientSource.id,
            "from_address_id": clientSource.address.id,
            "delivery_client_id": clientTarget.id,
            "shipping_address_id" : clientTarget.address.id,
            "first_name": clientSource.name, 
            "last_name": clientSource.last_name, 
            "email": clientSource.email,
            "phone": clientSource.tel_mobile,            
            "pay_method": 1,
            "ip_address": "127.0.0.1",
            "reception_name": clientTarget.name,
            "transaction_id": "11111",
            "comment": comment,
            "subtotal": order.getSubTotal(),
            "load_cost": 0,
            "delivery_cost": deliveryCost,
            "total": order.getTotal(),            
            "deliver_at": currentDate.toISOString(),
            "request_at": null,
            "route_order": null,
            "requieres_invoice": requieres_invoice,            
            "order_item": orderItems,            
            "card_token": {                
              },
            "accepted_check": acceptCheck,
            "guide": guideData.tracking_number,
            "internal_ctrl": control,
            "track_id": guideData.tracking_number
        }
        return orderObject
    }


    isAdressValid(address) {
        if (address) {            
            return new Address(address).isValid()            
        } else {
            return false
        }
    }

    getInvalidFieldsAdress(address) {        
        if (address) {            
            return new Address(address).getInvalidFieldsAdress()        
        } 
        return ''
    }


    dismissAnGoToDetails() {
        const {orderDetails, pdf} = this.state
        this.dismissAndClearForms()
        if (orderDetails) {
            let handle = window.open(`${baseURL}${pdf}`, "_blank");
            handle.blur()
            window.focus()
        }
    }


    dismissAndClearForms() {
        this.setState({ 
            success_msg: false,
            clientSource: null,
            clientTarget: null,
            productsSelected: [],
            comment:'',
            acceptCheck: false,
            control: '', 
            guia: '', 
            rastreo: '',
            deliveryQuote: null,
            orderDetails: null,
            key: new Date().getTime()
        })
    }

}

const mapStateToProps= state => {
    return {
        login: state.Login,
        user: state.User.user,
        master: state.User.master
    }
}

export default connect(mapStateToProps, null)(CotizadorPage)