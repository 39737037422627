import React, { Component } from "react";
//import Taskboard from "./Taskboard";
import Taskboard from '../../components/Kanban/Taskboard';
import {changeLayoutWidth,changeSidebarType} from '../../store/layout/actions';
import { connect } from "react-redux";
import {
  Row,
  Col,
  Alert
} from "reactstrap";
import { Link } from "react-router-dom";
import moment from 'moment'
import accounting from 'accounting'
import { getOrderHistory, getBoards, baseURL } from "../../api/api";

class ordersBoard extends Component {

  constructor(props) {
    super(props)
    this.state = {
      columns:[],
      processing: false,    
    }
  }

  componentDidMount() {
    //console.log('componentDidMount', this.props)
    this.props.changeLayoutWidth("boxed");
    this.props.changeSidebarType("icon", false);
    this.fetchBoard()
  }

  fetchBoard() {
    const {login} = this.props
    if (login && login.key) {     
      this.setState({processing: true}) 
      getBoards(login).then(result => {
        if (result.status == 200) {
          const board = result.data.results.find(it => it.slug === "ordenes-de-compra")          
          if (board){
            this.setState({
              board
            }, () => this.fetchOrders(board))
          } else {
            this.setState({processing: false})
          }
        } else {          
          const error = result.status >= 500 ? 'Error en el servidor':
              result.status >= 400 ? (result.data && result.data.detail ? result.data.detail : JSON.stringify(result.data))
                : result.problem
          this.setState({
            processing: false,
            error: `Ocurrio un problema: ${error}`
          })          
        }
      }).catch(error => console.warn('Error', error))

    }
  }

  fetchOrders(board) {
    const {login} = this.props
    if (login && login.key) {
      this.setState({processing: true})
      getOrderHistory(null, login, 1000).then(result => {
        if (result.status == 200) {
          this.fillBoard( board, result.data.results)
        }
        this.setState({processing: false})
      }).catch(error => console.warn('Error', error))
    }
  }

  fillBoard( board, orders) {
    //console.log('fillBoard( board, orders)', board, orders)
    const columnsMap = new Map()    
    for (let i = 0; i < board.stages.length; i++) {
      const stage = board.stages[i];      
      const columnObject = {...stage, tasks:[]}
      columnsMap.set(stage.slug, columnObject)
    }
    
    for (let i = 0; i < orders.length; i++) {
      const order = orders[i];
      const column = columnsMap.get(order.status.slug)
      if (column) {
        column.tasks.push(
          {            
            title: `Pedido #${order.sid}  - SKU: ${order.sku}`,
            description: (
              <React.Fragment>
                <span>{`Cliente: ${order.client.name} ${order.client.last_name}`}</span>
                <br />
                <span>{`Creado: ${moment(order.created_at).format('lll')}`}</span>
                <br />
                <span>{`Total: ${accounting.formatMoney(order.total)}`}</span>
                <div style={{display:'flex', justifyContent:'flex-end', paddingRight:10}}>
                  <a href={`${baseURL}/shop/admin/dashboard/pedidos/detalle/${order.id}/`}>Ver detalles</a>
                </div>
              </React.Fragment>
            ),
            ...order
          })
        }      
    }
    
    const columns = []
    columnsMap.forEach((value, key) => {      
      columns.push(value)
    })
    this.setState({
      columns
    })
  }
    
  onDragEnd = (result) => {
    //console.log('onDradEnd', result)
    if (!result.destination) {
      return;
    }
    const {login} = this.props
    const source = result.source;
    const destination = result.destination;
    var providerId = result.draggableId;
    if (source && destination && providerId) {
      // console.log('updateProviderStatus', destination.droppableId)
      // this.setState({processing: true})
      // updateProviderStatus(providerId, destination.droppableId, login).then(result =>{
      //   if (result.status ==200) {
      //     this.updateProviderInColumns(result.data)
      //   }
      //   this.setState({processing: false})
      // }).catch(error => console.warn('Error', error))
    }
  }

  updateProviderInColumns(provider) {
    const {columns} = this.state
    for (let i = 0; i < columns.length; i++) {
      const column = columns[i];
      const tasks = column.tasks.filter(it => it.id !== provider.id)
      if (column.slug === provider.status.slug) {
        tasks.push({
          title: provider.name,
          ...provider
        })
      }
      column.tasks = tasks      
    }    
    this.setState({columns})
  }

  render() {
     return (
     <div>
        <Row className="align-items-center">
            <Col sm={6}>
              <div class="page-title-box">
                <h4 className="font-size-18">Ordenes de Compra</h4>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="#">Kanban</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#">Ordenes de compra</Link>
                  </li>
                  <li className="breadcrumb-item active">Adminsitrador de estados</li>
                </ol>
              </div>
            </Col>

            <Col sm="6">
              <div className="float-right d-none d-md-block">
                {/* <SettingMenu /> */}
              </div>
            </Col>
          </Row>

          {this.state.error ? (
            <Alert color="danger">{this.state.error}</Alert>
          ) : null}

       {
          this.state.columns && 
            (<Taskboard columns={this.state.columns} 
                onDragEnd={this.onDragEnd} 
                processing={this.state.processing}
                readOnly={this.state.board ? this.state.board.workflow.read_only: true}
                type={"orders"} />)
        }  
       </div>); 
  }
}

const mapStatetoProps = (state) => {
  return { 
    login: state.Login,
    ...state.User,
  };
};

const mapDispatchToProps = {
  changeLayoutWidth,
  changeSidebarType
};

export default connect(mapStatetoProps, mapDispatchToProps)(ordersBoard);

