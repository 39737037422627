import React, { Component } from 'react'
import { Button, Card, Col, Container, Form, InputGroup } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { Modal, ModalHeader } from 'reactstrap'

export default class OpenpayTokenizer extends Component {

    constructor(props) {
        super(props)
        this.state = {
            "number": "",
            "name": "",
            "exp_year": "",
            "exp_month": "",
            "cvc": "",
            message: ""
        }
    }

    componentDidMount() {
        //console.log('OpenPay', window.OpenPay)
        if (!window.OpenPay) {
            toast.warn('Configuración inválida, no se encontro la libreria de OpenPay en la página.')
            this.setState({disabled: true})
        }
    }

    onAceptarPress() {
        const {number, name, exp_year, exp_month, cvc} = this.state
        if (this.isValidCardData()) {            
            let tokenParams = {
                "card_number": number,
                "holder_name": name,
                "expiration_year": exp_year,
                "expiration_month": exp_month,
                "cvv2": cvc
                }
            
            window.OpenPay.token.create(tokenParams, 
                (token) => {
                    toast.info('Tarjeta válida.')
                    token.params = tokenParams
                    this.props.onSuccessToken(token)
                    this.setState({
                        message: "Tarjeta válida"
                    })
                }, 
                (error) => {
                    console.log('error', error)
                    toast.error("Error al validar tu tarjeta: \n" + error.data.description)
                    this.setState({
                        message: "Tarjeta NO válida"
                    })
                }
                );
        }
    }

    isValidCardData() {
        const {number, name, exp_year, exp_month, cvc} = this.state
        let isOk = true        
        if (!window.OpenPay.card.validateCardNumber(number)){
            toast.warn('El numero de tarjeta es inválido.')
            isOk = false
        }
        if (!window.OpenPay.card.validateExpiry(exp_month, exp_year)){
            toast.warn('La fecha de expiración es inválida.')
            isOk = false
        }
        if (!window.OpenPay.card.validateCVC(cvc)){
            toast.warn('El código de seguridad es inválido.')
            isOk = false
        }
        if (!(name && name.length > 0)) {
            toast.warn('Falta el nombre del tarjetahabiente.')
            isOk = false
        }
        return isOk
    }

    render() {
        const {isOpen, onToggle, asDiv} = this.props

        let form = (
            <Card style={{border: '1px solid #fafafa'}}>
                    <Card.Body>

                        <Container>
                            <Form.Row>                        
                                <Col>
                                    <Form.Group controlId="name">
                                        <Form.Label>NOMBRE DEL TITULAR</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Titular de la tarjeta"
                                            value={this.state.name}
                                            onChange={(e) => this.setState({ name: e.target.value })}
                                        />
                                    </Form.Group>
                                </Col>
                            </Form.Row>
                            <Form.Row>
                                <Col>
                                    <Form.Group controlId="number">
                                    <Form.Label>NUMERO DE TARJETA</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Número de tarjeta"
                                            maxLength={19}
                                            value={this.formattingCardNumber()}
                                            onChange={(e) => this.setState({ number: e.target.value.replace(/-/g,'') })}
                                        />
                                    </Form.Group>
                                </Col>                        
                            </Form.Row>                            
                            <Form.Row>
                                <Col>
                                    <Form.Group controlId="exp_month">
                                    <Form.Label>EXPIRACION</Form.Label>
                                        <InputGroup className="mb-3">
                                            <InputGroup.Prepend>
                                            <InputGroup.Text id="basic-addon3">
                                                MES
                                            </InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <Form.Control
                                                type="number"
                                                placeholder="MM"
                                                maxLength={2}
                                                max="99"
                                                value={this.state.exp_month}
                                                onChange={(e) => this.setState({ exp_month: e.target.value })}
                                            />
                                        </InputGroup>
                                        
                                    </Form.Group>
                                </Col>                        
                                <Col>
                                    <Form.Group controlId="exp_year">
                                        <Form.Label style={{color:'white'}}>{`.`}</Form.Label>
                                        <InputGroup className="mb-3">
                                            <InputGroup.Prepend>
                                            <InputGroup.Text id="basic-addon3">
                                                AÑO
                                            </InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <Form.Control
                                                type="number"
                                                placeholder="YY"
                                                maxLength={2}
                                                max="99"
                                                value={this.state.exp_year}
                                                onChange={(e) => this.setState({ exp_year: e.target.value })}
                                            />
                                        </InputGroup>                                        
                                    </Form.Group>
                                </Col>                        
                                <Col md={3} style={{paddingLeft:20}}>
                                    <Form.Group controlId="cvc">
                                        <Form.Label>CVC</Form.Label>
                                        <Form.Control
                                            type="number"
                                            placeholder="CVC"
                                            value={this.state.cvc}
                                            maxLength={4}
                                            onChange={(e) => this.setState({ cvc: e.target.value })}
                                        />
                                    </Form.Group>
                                </Col>
                            </Form.Row>
                            <Form.Row style={{justifyContent:'center', alignItems:'center'}}>
                                <Button variant="primary" 
                                    disabled = {this.state.disabled}
                                    onClick={() => this.onAceptarPress()}>
                                        Validar
                                </Button>
                                <div className="mx-2">
                                    <span>{this.state.message}</span>
                                </div>
                            </Form.Row>
                        </Container>
                    </Card.Body>
                </Card>
        )
        if (asDiv) {
            return (
                <div>                    
                    {form}                
                </div>
            )
        } else {
            return (
                <Modal isOpen={isOpen}  centered size="md">
                    <ModalHeader toggle={() => onToggle()}>Datos de tu Tarjeta de Pago</ModalHeader>
                    {form}                
                </Modal>
            )
        }
    }

    formattingCardNumber() {
        const number = this.state.number            
        if (number.length > 12)
            return `${number.substr(0,4)}-${number.substr(4,4)}-${number.substr(8,4)}-${number.substr(12)}`
        else if (number.length > 8)
            return `${number.substr(0,4)}-${number.substr(4,4)}-${number.substr(8)}`
        else if (number.length > 4)
            return `${number.substr(0,4)}-${number.substr(4)}`
        else
            return number
    }

}