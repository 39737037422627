import React from "react";
import { Link } from "react-router-dom";

export default function qaexposmainnav(props) {
  const baseURL= props.url;
  return (
    <li>
      <Link to="/#" className="has-arrow waves-effect">
        <i ><span className="iconify" data-icon="bx:bx-bolt-circle" data-inline="false"></span></i>
        <span>Expos</span>
      </Link>
      <ul className="sub-menu" aria-expanded="false">
        <li>
          <a href={baseURL+"/expos/dashboard/"}>
            Dashboard
          </a>
        </li>
        <li>
          <a href={baseURL+"/expos/cms/events/"}>
          Mis Eventos
          </a>
        </li>
      </ul>
    </li>
  );
}
