/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row, Col, Alert, Card, CardBody } from 'reactstrap';
//import { getCreditBalanceList } from '../../api/webFramework_api';
import { getCreditBalanceList } from '../../api/api';

const AccountStatementsBoard = (props) => {
  const { login } = props;
  const [statements, setStatements] = useState([]);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    getCreditBalanceList(login)
    .then((response) => {
      const { data } = response;
      if (data.count > 0) {
        setStatements(data.results);
      } else {
        console.log('false');
        setShowAlert(true);
      }
    })
    .catch((error) => {
      setShowAlert(true);
      console.error(error);
    });
  }, []);

  return (
    <div>
      <Row className='align-items-center'>
        <Col sm={6}>
          <div className='page-title-box'>
            <h4 className='font-size-18'>Estados de cuentas</h4>
            <ol className='breadcrumb mb-0'>
              <li className='breadcrumb-item'>
                <Link to='/account-statements'>Estados de cuenta</Link>
              </li>
            </ol>
          </div>
        </Col>

        <Col sm='6'>
          <div className='float-right d-none d-md-block'>
            {/* <SettingMenu /> */}
          </div>
        </Col>
      </Row>
      {showAlert ? (
        <Alert variant='danger'>
          <div className='alert-heading h4'>Sin estados de cuenta</div>
          <p>
            Lamentablemente no pudimos encontrar estados de cuenta para este perfil,
            intente nuevamente mas tarde.
          </p>
        </Alert>
      ) : (
        <Row>
          <Col>
            <Card>
              <CardBody>
                <h4 className='card-title mb-4'>Estados de Cuenta</h4>
                <div className='table-responsive'>
                  <table className='table table-hover table-centered table-nowrap mb-0'>
                    <thead>
                      <tr>
                        <th scope='col'>(#) Id</th>
                        <th scope='col'>Fecha</th>
                        <th scope='col'>Total</th>
                        <th scope='col'>Detalles</th>
                      </tr>
                    </thead>
                    <tbody>
                      {statements.map((statement) => (
                        <tr key={statement.id}>
                          <th scope='row'>
                            #
                            {statement.id}
                          </th>
                          <td>{new Date(statement.date_end).toLocaleDateString('es-MX')}</td>
                          <td>
                            $
                            {statement.total}
                          </td>
                          <td>
                            <div>
                              <Link to={`/credit/sellers/balance-sheet-profile/${statement.id}`} className='btn btn-primary btn-sm'>
                                Ver
                              </Link>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
};

const mapStatetoProps = (state) => {
  return {
    login: state.Login,
    ...state.User,
  };
};

export default connect(mapStatetoProps, null)(AccountStatementsBoard);

// export default AccountStatementsBoard;
