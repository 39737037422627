import React, { Component } from "react";
import { Row, Col, Button, Input, Card, CardBody, Modal, FormGroup } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Switch from "react-switch";
import { Link } from "react-router-dom";
// Custom Scrollbar

class DeviceView extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return(
      <React.Fragment>
      </React.Fragment>
    )
  }
}

export default DeviceView;