import React, { useState, useEffect, useLayoutEffect } from 'react';
import { Row, Col, Card, CardTitle, CardBody, CardHeader, CardFooter } from 'reactstrap';
import './utils/CardUtils.css'

import { Link, Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';

import { getCategoryById } from '../../../api/api';

export default function CatInfo({ props = {}, bindState = {}}) {
  // console.log(props, bindState);
  const [ category, setcategory ] = useState(bindState && bindState.category ? bindState.category : undefined)
  const [ categoryID, setcategoryID ] = useState(bindState && bindState.categoryID ? bindState.categoryID : props.match.params && props.match.params.categoryId ? props.match.params.categoryId : undefined)

  useLayoutEffect(() => {
    if (categoryID) getCategoryById(categoryID)
      .then((res) => {
        if (res.data) {
          setcategory(res.data)
        }
      }).catch((err) => {
        console.log(err);
        toast.warn('Error al obtener categoria')
      })
  }, [categoryID])

  useEffect(() => {
    setcategoryID(bindState && bindState.categoryID ? bindState.categoryID : props.match.params && props.match.params.categoryId ? props.match.params.categoryId : undefined)
    // console.log(categoryID);
  }, [props])

  // console.log(category);

  return (
    <div className="container-fluid" id="cat_config_container" >
      <Row className="align-items-center">
        <Col sm={ 6 }>
          <div className="page-title-box">
            <h4 className="font-size-18">Configurar Categor&iacute;as</h4>
            <ol className="breadcrumb mb-0">
                <li className="breadcrumb-item active">
                    Admin / Categor&iacute;as
                </li>
            </ol>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <CardTitle className="title">
                <img width="80" height="80" src={ category && category.image_get ? category.image_get : 'http://stg.herosuite.io/static/images/product_image_not_found.gif' } className="mr-4" />
                { category && category.name ? category.name : 'Cargando Datos' }
              </CardTitle>
            </CardHeader>
            <CardBody>
              <div>
                {category && category.description ? category.description : 'Descripcion de la categoria'}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}