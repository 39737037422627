import React from "react";
import { Link } from "react-router-dom";

export default function qaprolasmainnav(props) {
  const baseURL= props.url;
  return (
    <li>
      <Link to="/#" className="has-arrow waves-effect">
        <i><span className="iconify" data-icon="dashicons:admin-users" data-inline="false"></span></i>
        <span>Pilas Cimentación</span>
      </Link>
      <ul className="sub-menu" aria-expanded="false">
        <li>
          <a href={baseURL+"/prolas/cms/projects/"}>
            Proyectos
          </a>
        </li>
      </ul>
    </li>
  );
}
