// react
import React, { Component, Fragment } from 'react';

// third-party
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { Modal, ModalHeader, Button, ModalBody} from 'reactstrap';

// application
import { toast } from 'react-toastify';
import { Breadcrumb, Card, Accordion, Form, Row, Col, FormGroup, Container, ListGroup, Spinner } from 'react-bootstrap';
import accounting from 'accounting'


import { getUserAddresses, getDeliveryRulesFor, createOrderPlanHired, getMasterConfigs, getDiscountById, MARKETPLACE_URL, getCountries, getStatesByCountry, getCitiesByState, baseURL } from '../../api/api';
import { isMobile, isValidDate } from '../../utils/Utils';
import { PAYMENT_TYPES, PAY_PLATFORMS } from '../shop/configs';
import ConektaTokenizer from '../components/pay_platforms/ConektaTokenizer';
import OpenpayTokenizer from '../components/pay_platforms/OpenpayTokenizer';
import SweetAlert from 'react-bootstrap-sweetalert';

const emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

class ShopPageCheckout extends Component {    

    constructor(props) {
        super(props);

        this.state = {
            userName:'',
            userLastname:'',
            email:'',
            phone:'',

            //payment: 'bank',
            addresses: [],
            payMethods:[],
            payMethodSelected: null,
            deliveryCost: 0,
            requieres_invoice: false,
            haveReadTermsAndConditions: false,

            //MercadoPago
            mercadoPagoPreference: null,
            showMercadoPagoModal: false,
            createdOrderSuccess: false,

            //Openpay
            showOpenpay3DSecureModal: false,
            openpayDataRerefence: null,

            //Compras sin sesión
            countries:[],
            states:[],
            cities: [],

            nameAdreess:'Dirección',
            atype_id:1,
            zip_code:'',
            street:'',
            numext:'',            
            area:'',
            state_text:'',
            city_text:'',
            country_text:'México',

            initializing: true,
            showAddAddressModal: false,

            sharedCart: null,
            showOrderAsSuccess: false
        };
    }

    componentDidMount() {
        //console.log('ShopPageCheckout componentDidMount', this.props)                        
        this.setupUserInfo()        
        this.fetchMasterConfigs()           
        this.printUrl()   
        setTimeout(() => {
            this.setState({initializing: false})
        }, 2000);     
    }

    getCart() {
        if (this.state.sharedCart) {
            return this.state.sharedCart
        } else {
            return this.props.cart
        }
    }

    setupOpenpay(payplatform) {
        if (!window.OpenPay) {
            toast.warn('Configuración inválida, no se encontro la libreria de OpenPay en la página.')
            this.setState({disabled: true})
        } else {
            window.OpenPay.setId(payplatform.commerce_id)
            window.OpenPay.setApiKey(payplatform.key)
            window.OpenPay.setSandboxMode(this.isOpenpayPlatformSandBox(payplatform.pp_url));
            let deviceSessionId = window.OpenPay.deviceData.setup()
            //console.log('Openpay deviceSessionId', deviceSessionId )
            this.setState({
                deviceSessionId
            })
        }
    }

    setupUserInfo() {
        const {user} = this.props
        if (user ) {
            const { email, tel_office, tel_mobile, name, last_name } = user            
            this.setState({
                userName: name,
                userLastname: last_name,
                email: email,
                phone: tel_mobile ? tel_mobile : tel_office,
            })
        }
    }

    buyWithoutSession(){
        return false
    }    

    printUrl() {
        const marketplace = process.env.REACT_APP_MARKETPLACE ? process.env.REACT_APP_MARKETPLACE
            : (process.env.PUBLIC_URL ? process.env.PUBLIC_URL: MARKETPLACE_URL)
        console.log('marketplace', marketplace)  
        console.log('process.env.REACT_APP_MARKETPLACE', process.env.REACT_APP_MARKETPLACE)  
        console.log('process.env.PUBLIC_URL', process.env.PUBLIC_URL)  
        console.log('MARKETPLACE_URL', MARKETPLACE_URL) 
    }

    fetchMasterConfigs() {
        const {distributorSiteId} = this.props
        if(distributorSiteId) {
            getMasterConfigs(this.props.login, distributorSiteId).then(result => {
                if (result.status == 200) {                
                    this.setState({
                        masterConfigs: result.data[0]}
                    , () => this.setup())
                }
            }).catch(error => console.warn('error getMasterConfigs', error))
        } else {
            toast.warn('Url inválida, no se encontro el site del distribuidor.')
        }
    }

    setup() {
        const {masterConfigs} = this.state
        this.setupPayMethods(masterConfigs)        
    }

    setupPayMethods(masterConfigs) {  
        //console.log('setupPayMethods', masterConfigs)      
        if (masterConfigs) {
            if (masterConfigs && masterConfigs.payplatform_credentials_set){
                const payPlatforms = masterConfigs.payplatform_credentials_set.filter(it => it.active)
                if (payPlatforms) {
                    const payMethods = []
                    for (let i = 0; i < payPlatforms.length; i++){
                        const payplatform = payPlatforms[i]
                        for (let j = 0; j < payplatform.pay_methods.length; j++) {
                            const pay_method = payplatform.pay_methods[j]
                            //console.log('paymethod', pay_method)
                            if (pay_method && pay_method.active) {
                                let payMethod = {...pay_method}
                                payMethod.payplatform = payplatform
                                if (payplatform.payplatform.slug === PAY_PLATFORMS.Conekta
                                    && payMethod.slug === PAYMENT_TYPES.TARJETA_CREDITO_DEBITO) {
                                    if (window.Conekta) {                                        
                                        window.Conekta.setPublicKey(payplatform.key)                                        
                                        payMethod.showTokenizer= true
                                        payMethods.push(payMethod)
                                    } else {
                                        toast.warning('No se encontró la libreria de Conekta.')
                                        console.warn('No se encontró la libreria de Conekta.')
                                    }
                                } else if (payplatform.payplatform.slug === PAY_PLATFORMS.OpenPay
                                    && payMethod.slug === PAYMENT_TYPES.TARJETA_CREDITO_DEBITO) {
                                    if (window.OpenPay) {  
                                        //console.log('Openpay Keys', payplatform)                                      
                                        this.setupOpenpay(payplatform)                                        
                                        payMethod.showTokenizer= true
                                        payMethods.push(payMethod)
                                    } else {
                                        toast.warning('Incorrecta configuración de Openpay: falta la libreria javascript.')
                                        console.warn('Incorrecta configuración de Openpay: falta la libreria javascript.')
                                    }
                                }
                                else {
                                    payMethods.push(payMethod)
                                }
                            }
                        }
                    }                    
                    this.setState({payMethods})                    
                }
            }
        } 
    }

    isOpenpayPlatformSandBox(payplatformURL) {
        return payplatformURL.includes('https://sandbox-api')
    }
    

    handlePaymentChange(event, payment) {           
        if (payment) {
            this.setState({ payMethodSelected: payment });            
            if (payment.showTokenizer) {
                this.setState({
                    showTokenizer: true
                })
            }
        }
    };

    renderTotals() {
        const cart = this.getCart()

        if (cart && cart.extraLines && cart.extraLines.length <= 0) {
            return null;
        }

        const extraLines = cart.extraLines.map((extraLine, index) => (
            <tr key={index}>
                <th>{extraLine.title}</th>
                <td>{accounting.formatMoney(extraLine.price, null, 0)}</td>
            </tr>
        ));

        return (
            <React.Fragment>
                <tbody className="checkout__totals-subtotals">
                    <tr>
                        <th>Subtotal</th>
                        <td>{accounting.formatMoney(cart.subtotal)}</td>                        
                    </tr>
                    {extraLines}
                </tbody>
            </React.Fragment>
        );
    }

    isValid(item) {
        if (item && item.product.discount) {
            const discount = item.product.discount.discount            
            if (discount.active) {
                if (discount.otype.slug === "limited-time") {
                    return isValidDate(discount.end_date)                    
                }                
            } 
            return discount.active ? true : false
        }
        return true
    }

    renderCart() {
        const cart = this.getCart()

        const invalidItems = cart.items.filter(it => !this.isValid(it))
        if (invalidItems.length > 0) {
            invalidItems.forEach(it => {
                toast.warn(`Ha caducado el descuento de: ${it.product.name}`)
            })
            return (<Redirect to="/shop/cart" />)
        }

        const items = cart.items.map((item) => (
            <tr key={item.id}>
                <td>{`${item.product.name} × ${item.quantity}`}</td>
                <td>{accounting.formatMoney(item.total)}</td>                
            </tr>
        ));

        return (
            <table className="checkout__totals" style={{width:'100%'}}>
                <thead className="checkout__totals-header">
                    <tr>
                        <th>Producto</th>
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody className="checkout__totals-products">
                    {items}
                </tbody>
                {this.renderTotals()}
                <tfoot className="checkout__totals-footer">
                    <tr>
                        <th>Total</th>
                        <td>{accounting.formatMoney(cart.total)}</td>                        
                    </tr>
                </tfoot>
            </table>
        );
    }

    renderPaymentsList() {
        //const { payment: currentPayment } = this.state;        
        const {payMethods, payMethodSelected} = this.state        
        if (payMethods) {
            const payments = payMethods.map((payment, index) => {                                
                return (
                    <Card>
                        <Accordion.Toggle as={Card.Header} 
                            eventKey={`${payment.id}-${payment.payplatform.payplatform.slug}`}                            
                            >        
                            <Row>
                                <Col>
                                    <Form.Check
                                        type='radio'
                                        name="checkout_payment_method"
                                        label={payment.name}
                                        checked= {payMethodSelected 
                                            && payMethodSelected.id === payment.id 
                                            && payMethodSelected.payplatform.payplatform.id === payment.payplatform.payplatform.id
                                        }
                                        id={`${index}`}
                                        onChange = {(e) => this.handlePaymentChange(e, payment)}
                                    />
                                </Col>
                                <Col className="content-center-column">
                                {
                                    payment.showTokenizer ?
                                        <Button onClick={()=> this.setState({
                                            showTokenizer: true,
                                            payMethodSelected: payment
                                        })}>Datos de Tarjeta</Button>
                                        : null
                                    }
                                {                                        
                                    payment.additionalData                                            
                                }
                                </Col>
                            </Row>                
                        </Accordion.Toggle>
                        {/* <Accordion.Collapse eventKey={payment.id}>
                            <Card.Body>
                                <Row className="content-center-column">
                                    {
                                        payment.showTokenizer ?
                                            <Button onClick={()=> this.setState({
                                                showTokenizer: true,
                                                payMethodSelected: payment
                                            })}>Datos de Tarjeta</Button>
                                            : null
                                    }
                                    {
                                        payment.additionalData                                            
                                    }
                                </Row>
                                <Row>
                                    {payment.description}
                                </Row>
                            </Card.Body>
                        </Accordion.Collapse> */}
                    </Card>
                )    
            });
    
            return (
                <Accordion defaultActiveKey={payMethodSelected ? payMethodSelected.id : 0 }>                    
                    {payments}                    
                </Accordion>                
            );
        }

    }
    
    onCheckBoxChange(e){        
        this.setState({haveReadTermsAndConditions: e.target.checked})
    }

    renderMercadoPagoModal() {
        const {showMercadoPagoModal, mercadoPagoPreference} = this.state
        //console.log('renderMercadoPagoModal', showMercadoPagoModal)    
        if (mercadoPagoPreference) {
            return (
                <Modal isOpen={showMercadoPagoModal}  centered size="xl">
                    <ModalHeader toggle={() => this.setState({showMercadoPagoModal: !this.state.showMercadoPagoModal})}>Mercado Pago</ModalHeader>
                    <div >                        
                        <iframe
                            // src="https://www.mercadopago.com.mx/checkout/v1/redirect?pref_id=273328135-c22167f6-295a-4640-8958-1676f60f462c"
                            src={mercadoPagoPreference.init_point}
                            width="100%"
                            height="600px">
                        </iframe>
                    </div>
                </Modal>
            )
        }    
    }

    renderOpenpay3DSecureValidation() {
        const {showOpenpay3DSecureModal, openpayDataRerefence, orderResult} = this.state        
        if (openpayDataRerefence) {
            return (
                <Modal isOpen={showOpenpay3DSecureModal}  centered size="xl">
                    <ModalHeader toggle={() => {
                            this.setState({showOpenpay3DSecureModal: !this.state.showOpenpay3DSecureModal})
                            if (orderResult){
                                this.showOrderAsPending(orderResult)
                            }                            
                        }
                    }>Validación Requerida</ModalHeader>
                    <div >                        
                        <iframe
                            //src="https://sandbox-api.openpay.mx/v1/m7h4n0v8x4e8d2qefouq/charges/trhqswulakkbxzyehtmi/redirect/"
                            src={openpayDataRerefence.url}
                            width="100%"
                            height="600px">
                        </iframe>
                    </div>
                </Modal>
            )
        }    
    }

    renderTokenizerModal() {
        const {showTokenizer, payMethodSelected} = this.state
        if (showTokenizer) {
            if (payMethodSelected.payplatform.payplatform.slug === PAY_PLATFORMS.Conekta) {
                return (
                    <ConektaTokenizer isOpen={showTokenizer} 
                        onToggle={() => this.setState({showTokenizer: false})}
                        onSuccessToken={(tokenData) => {
                            payMethodSelected.additionalData = `Tarjeta válida: *${tokenData.params ? tokenData.params.card.number.substr(-4) : ''}`
                                this.setState({
                                    conektaTokenData: tokenData,
                                    showTokenizer: false
                                })
                        }}
                        />
                )
            } else if (payMethodSelected.payplatform.payplatform.slug === PAY_PLATFORMS.OpenPay) {
                return (
                    <OpenpayTokenizer isOpen={showTokenizer}
                        onToggle={() => this.setState({ showTokenizer: false })}
                        onSuccessToken={(tokenData) => {
                            payMethodSelected.additionalData = `Tarjeta válida: ${tokenData.params ? tokenData.params.card_number.substr(-4) : ''}`
                                this.setState({
                                    openpayTokenData: tokenData,
                                    showTokenizer: false
                                })
                        }}
                    />
                )
            }
        }
    }


    render() {
        const { login, user, shop_configs } = this.props;
        const cart = this.getCart()
        const { 
            addresses, 
            addressSelected,
            mercadoPagoPreference, 
            haveReadTermsAndConditions,
            redirect,
            createdOrderSuccess,
            orderResult,
            countries,
            states,
            cities,
            country,
            state,
            city,
            initializing            
        } = this.state;

        const {
            userName,
            userLastname,
            email,
            phone} = this.state     
        
        
        let disabledText = true
        
        if (!(login && login.key
            && user && user.email)) {
                console.log('this', this.props, this.state)
            return <Redirect to={{
                pathname: "/login",
                state: {referrer: this.props.location}
            }} />;
        }

        
        return (
            <React.Fragment>                
                <React.Fragment>
                {
                    this.state.showOrderAsSuccess ? (
                        <SweetAlert
                            title="¡Pedio generado!"
                            success
                            confirmBtnBsStyle="success"
                            confirmBtnText="Aceptar"
                            onConfirm={() => this.dismissAnGoToRoute('plans')}
                        >
                        Su pedio fue generado correctamente, regresa a la app y refresca para ver el plan
                        </SweetAlert>
                    ) : null
                }
                </React.Fragment>
                <React.Fragment>                      

                {
                    this.renderMercadoPagoModal()                    
                }                
                {
                    this.renderTokenizerModal()
                }
                {
                    this.renderOpenpay3DSecureValidation()
                }
                    <Container fluid>
                        <Row>
                            <Col lg={6} xl={7}>                                
                                <Card>
                                    <Card.Body>
                                        <Card.Title>Detalles de compra</Card.Title>                                        
                                        <Form.Row>
                                            <Col md={6}>
                                                <label htmlFor="checkout-first-name">Nombre</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="checkout-first-name"
                                                    placeholder="Nombre"
                                                    disabled = {disabledText}
                                                    value = {userName}
                                                    onChange={(e) => this.setState({ userName: e.target.value })}
                                                />
                                            </Col>
                                            <Col md={6}>
                                                <label htmlFor="checkout-last-name">Apellidos</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="checkout-last-name"
                                                    placeholder="Apellidos"                                                    
                                                    disabled = {disabledText}
                                                    value = {userLastname}
                                                    onChange={(e) => this.setState({ userLastname: e.target.value })}
                                                />
                                            </Col>
                                        </Form.Row>
                                        <Form.Row>
                                            <Col md={6}>
                                                <label htmlFor="checkout-email">Email</label>
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    id="checkout-email"
                                                    placeholder="Email"                                                    
                                                    disabled = {disabledText}
                                                    value = {email}
                                                    onChange={(e) => this.setState({ email: e.target.value })}
                                                    
                                                />
                                            </Col>
                                            <Col md={6}>
                                                <label htmlFor="checkout-phone">Teléfono</label>
                                                <input type="text" 
                                                    className="form-control" 
                                                    id="checkout-phone" 
                                                    placeholder="Phone"                                                     
                                                    disabled = {disabledText}
                                                    value = {phone}
                                                    onChange={(e) => this.setState({ phone: e.target.value })}
                                                    />
                                            </Col>
                                        </Form.Row>
                                        
                                    </Card.Body>                                    
                                                                                                        
                                </Card>

                            </Col>                            
                            <Col lg={6} xl={5}>
                                <Card>
                                    <Card.Body>
                                        <Card.Title>Tu pedido</Card.Title>
                                        <ListGroup variant="flush">
                                            <ListGroup.Item>
                                                {this.renderCart()}
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                {this.renderPaymentsList()}                                                
                                                <FormGroup style={{margin:'20px 0px 20px'}}>
                                                    <Form.Check type="checkbox" id={`check-terms`}>
                                                        <Form.Check.Input 
                                                            type="checkbox" 
                                                            isValid 
                                                            checked={haveReadTermsAndConditions}
                                                            onChange={(e) => this.onCheckBoxChange(e)}
                                                            />
                                                        <Form.Check.Label>He leído y acepto los                                                                                                             
                                                        <a href={`https://elbuencomercio.mx/terminos-condiciones`} target="_blank"> términos y condiciones </a>
                                                        del sitio web.</Form.Check.Label>                                                        
                                                    </Form.Check>                                                    
                                                </FormGroup>

                                            {
                                                this.state.processing ? 
                                                        <div style={{ textAlign: 'center' }}>
                                                            <Spinner animation="border" role="status">
                                                                <span className="sr-only">Loading...</span>
                                                            </Spinner>
                                                        </div>
                                                    :
                                                    createdOrderSuccess && orderResult ?
                                                        (
                                                            mercadoPagoPreference && mercadoPagoPreference.id ?
                                                                <div style={{textAlign:'center'}}>
                                                                    <button type="submit" className="btn btn-primary btn-xl btn-block"
                                                                        onClick={() => this.retryPayProcess()} >Reintentar pago</button>
                                                                        <p>Su pedido ya fue registrado</p>
                                                                </div>
                                                                :
                                                                <div>
                                                                    <div style={{textAlign:'center'}}>
                                                                        <button type="submit" className="btn btn-primary btn-xl btn-block"
                                                                            onClick={() => this.showOrderResult(orderResult)} >Ver Estatus</button>
                                                                            <p>No se realizó el pago.</p>
                                                                    </div>

                                                                    <button type="submit"
                                                                        className="btn btn-warning btn-xl btn-block"
                                                                        onClick={() => this.onPlaceOrder()} >
                                                                        <div style={{ color: 'black', padding: '0.25rem 0rem' }}>
                                                                            <strong>Reintentar Pagar</strong>
                                                                        </div>
                                                                    </button>

                                                                </div>

                                                        )
                                                        :
                                                        <button type="submit" 
                                                            className="btn btn-warning btn-xl btn-block"                                                            
                                                            onClick={() => this.onPlaceOrder()} >
                                                                <div style={{color:'black', padding:'0.25rem 0rem'}}>
                                                                    <strong>Pagar</strong>
                                                                </div>
                                                            </button>
                                            }
                                            </ListGroup.Item>
                                        </ListGroup>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </React.Fragment>
            </React.Fragment>   
        )
    }

    onPlaceOrder() {
        console.log('onPlaceOrder()', this.state, this.props)
        const {haveReadTermsAndConditions} = this.state
        const {login} = this.props
        if (haveReadTermsAndConditions) {
            if ((login && login.key) || this.buyWithoutSession()) {
                if (this.thereAreAllData()) {
                    this.setState({processing: true})
                    const orderData = this.createOrderData()
                    createOrderPlanHired(orderData, login).then(result => {
                        if (result.status === 201) {
                            this.handleCreateSuccessfull(result.data)                            
                        } else {
                            if (result.status === 500) {
                                toast.error('No se pudo registrar su pedido. Error del servidor intente más tarde.')
                            } else {
                                const message = result.data ? result.data : {Respuesta: result.status}
                                toast.error('No se pudo registrar su pedido: \n' + JSON.stringify(message) )
                            }
                        }  
                        this.setState({processing: false})  
                    }).catch(error => {
                        console.warn('Error createOrderPlanHired', error)
                        this.setState({processing: false})
                    })
                }
            } else {
                toast.info('Inice sesión para comprar.')
            }
        } else {
            toast.warn('Marque la casilla si acepta los términos y condiciones.')
        }
    }

    handleCreateSuccessfull(orderResult) {          
        this.setState({
            createdOrderSuccess: true,
            orderResult
        })

        if (this.isPaymentPlatform(PAY_PLATFORMS.MercadoPago)) {
            if (orderResult.preference 
                    && orderResult.preference.response
                    && orderResult.preference.status == 201) {                                
                this.setState({
                    mercadoPagoPreference: orderResult.preference.response,
                    createdOrderSuccess: true
                },  () => this.openMercadoPagoPreferenceCheckout(orderResult))
                
                toast.success('Su pedido fue registrado')
    
            } else {
                if (orderResult.preference 
                    && orderResult.preference.response
                    && orderResult.preference.status != 201){
                        toast.warn(`La referencia de pago no se generó correctamente: \n${orderResult.preference.response.message}`)                                        
                } else {
                    toast.warn(`No existe referencia de pago, por favor contacte a un vendedor.`)
                }            
            }
        } else if (this.isPaymentPlatform(PAY_PLATFORMS.Conekta)) {
            this.setState({
                createdOrderSuccess:true
            }, () => {
                const pp_order = orderResult.pp_order ?
                    orderResult.pp_order :
                    (orderResult.payment_result[0] && orderResult.payment_result[0].order) ?
                        orderResult.payment_result[0].order.pp_order : null
                if(this.isCreditOrDebitPayment()) {
                    if (pp_order){
                        if (pp_order.status === "paid" || pp_order.status === "active") {                            
                            toast.success('Su pedido fue registrado')
                            this.showOrderAsSuccess(orderResult)
                        } else {
                            this.showOrderResult(orderResult)
                            this.showOrderAsFailure(orderResult)
                        }
                    } else {
                        toast.warn('Puede que el pago con su tarjeta no se haya procesado, verifique con el vendedor.')
                        this.showOrderAsFailure(orderResult)
                    }
                } else {
                    if (pp_order) {
                        if (pp_order.status === "paid") {                            
                            toast.success('Su pedido fue registrado')
                            this.showOrderAsSuccess(orderResult)
                        } else if (pp_order.status === "Pendiente de pago") {                        
                            this.showOrderAsPending(orderResult)
                        } else {
                            toast.warn('Ocurrio un error con la plataforma de pagos, verifique con el vendedor.')
                            this.showOrderAsFailure(orderResult)
                        }
                    } else {
                        toast.warn('No hay respuesta de la plataforma de pago, verifique con el vendedor.')
                        this.showOrderAsFailure(orderResult)
                    }                    
                }
            })
        } else if (this.isPaymentPlatform(PAY_PLATFORMS.OpenPay)) {
            // Openpay regresa pp_order
            const pp_order = orderResult.pp_order ? 
                orderResult.pp_order : 
                    (orderResult.payment_result[0] && orderResult.payment_result[0].order) ? 
                        orderResult.payment_result[0].order.pp_order : null
            if (pp_order && (pp_order.status == 'completed' || pp_order.status == 'active')) {
                toast.success('Su pedido fue registrado')
                this.showOrderAsSuccess(orderResult)
            } else if (pp_order && pp_order.status == 'in_progress') {
                this.showOrderAsPending(orderResult)
            } else if (pp_order && pp_order.status == 'charge_pending') {
                //Manejo a redirect de 3D Secure
                this.handleChargePending(orderResult)
            } else if (pp_order && pp_order.status == 'error') {                
                toast.warn('Error de pago: ' + pp_order.data_error)
                this.showOrderAsFailure(orderResult)
            } else {
                toast.warn('No hay respuesta de la plataforma de pago, verifique con el vendedor.')
                this.showOrderAsFailure(orderResult)
            }
        }
        else {            
            toast.success('Su pedido fue registrado')
            this.setState({
                createdOrderSuccess:true
            })
        }       
    }

    showOrderResult(orderResult) {
        if (orderResult) {
            let order = null
            if (orderResult.pp_order){
                order = orderResult
            } else if (orderResult.payment_result[0] && orderResult.payment_result[0].order){
                order = orderResult.payment_result[0].order
            }            
            let message = `${order.status.name}.\n 
            Detalles: ${order.status.description}`

            if (order.pp_order) {
                message = message + `\n\n 
                Plataforma de pago \n
                Estatus: ${order.pp_order.status}\n 
                Error:${order.pp_order.data_error}`
            }

            toast.error(message)
        }
    }

    handleChargePending(orderResult) {
        const pp_order = orderResult.pp_order ? orderResult.pp_order : orderResult.payment_result[0].order.pp_order
        const dataReference = JSON.parse(pp_order.data_reference)
        if (dataReference){            
            if (dataReference.type == 'redirect') {
                this.setState({
                    createdOrderSuccess:true,
                    showOpenpay3DSecureModal: true,
                    openpayDataRerefence: dataReference,
                    orderResult
                })
            } else {
                this.showOrderAsPending(orderResult)                
            }
        } else {
            this.showOrderAsPending(orderResult)
        }
    }

    thereAreAllData() {        
        const {
            user, 
            user_master,
            distributorSiteId,
            sellerSiteId
        } = this.props
        const {addressSelected, payMethodSelected} = this.state
        const {
            country, 
            state,
            city, 
            street, 
            numint, 
            state_text,  
            numext, 
            area, 
            zip_code, 
            city_text, 
            country_text} = this.state 
        const {
            userName, 
            userLastname,
            email, 
            phone, 
            } = this.state 

        let ok = true
        if (!(user && user.email)) {
            if (this.buyWithoutSession()) {
                if (!(userName && userLastname && email && phone)){
                    toast.warn('Faltan datos de la compra')
                    ok = false
                } else {
                    if (!emailPattern.test(email)){
                        toast.warn('Email inválido')
                        ok = false
                    }
                }
            } else {
                toast.warn('No hay datos del usuario, Inicie sesión.')
                ok = false
            }
        }

        // if (!addressSelected) {
        //     if (this.buyWithoutSession()) {
        //         if (!country || !state || !(city || city_text) || !street || !numext || !area || !zip_code){
        //             toast.warn('Falta información de la dirección de entrega')
        //             ok = false
        //         }
        //     } else {
        //         toast.warn('No hay dirección de entrega, agregue una dirección en el perfil de su cuenta.')
        //         ok = false
        //     }
        // } 

        if (!user_master) {
            toast.warn('No hay información del sitio del usuario.')
            ok = false
        }

        if (!distributorSiteId) {
            toast.warn('No hay información del sitio del Distribuidor.')
            ok = false
        }

        if (!sellerSiteId) {
            toast.warn('No hay información del sitio del cliente (Proveedor / Seller).')
            ok = false
        }

        if (!payMethodSelected) {
            toast.warn('Elija un método de pago.')
            ok = false
        }

        if (this.isCreditOrDebitPayment()) {
            if (this.isPaymentPlatform(PAY_PLATFORMS.Conekta)) {
                const {conektaTokenData} = this.state
                if (!(conektaTokenData && conektaTokenData.id)){
                    toast.warn('Falta informacíon de su tarjeta de pago.')
                    ok = false
                }
            } else
            if (this.isPaymentPlatform(PAY_PLATFORMS.OpenPay)) {
                const {openpayTokenData} = this.state
                console.log('openpayTokenData', openpayTokenData)
                if (!(openpayTokenData && openpayTokenData.data && openpayTokenData.data.id)){
                    toast.warn('Falta informacíon de su tarjeta de pago.')
                    ok = false
                }
            }
        }

        return ok
    }

    createOrderData() {        
        const { 
            email, 
            tel_office, 
            tel_mobile, 
            name, 
            last_name } = this.props.user
        const {
            sellerSiteId
        } = this.props

        let orderEmail = '', orderPhone='', orderFirtsName='', orderLastName=''        
        orderEmail = email
        orderPhone =  tel_mobile ? tel_mobile : tel_office
        orderFirtsName = name
        orderLastName = last_name
        

        const cart = this.getCart()
        const {payMethodSelected, requieres_invoice} = this.state

        const currentDate = new Date()
        const total = cart.total
        const subtotal = cart.subtotal
        const deliveryCost = 0
        const orderItems = this.getOrderItems(cart.items)
        const marketplace = process.env.REACT_APP_MARKETPLACE ? process.env.REACT_APP_MARKETPLACE
            : (process.env.PUBLIC_URL ? process.env.PUBLIC_URL: MARKETPLACE_URL)
        
        let orderObject = {
            "first_name": orderFirtsName, //Campo nuevo para crear usuario sin registro
            "last_name": orderLastName, //Campo nuevo para crear usuario sin registro
            "email": orderEmail,
            "phone": orderPhone,            
            "pay_method": payMethodSelected.id,
            "payplatform_id": payMethodSelected.payplatform.payplatform.id,
            // "ip_address": "127.0.0.1",
            // "reception_name": "",
            // "transaction_id": "11111",
            // "comment": "",
            "subtotal": subtotal,
            "load_cost": 0,
            "total": total,            
            "deliver_at": currentDate.toISOString(),
            "request_at": null,
            "route_order": null,           
            "order_item": orderItems,            
            "provider_site_id": sellerSiteId, 
            "card_token": {                
              },
            "marketplace_url":marketplace
        }

        if (this.isCreditOrDebitPayment()) {
            if (this.isPaymentPlatform(PAY_PLATFORMS.OpenPay)) {
                const card_token = {
                    "card_token": {
                        "card_token_id": this.state.openpayTokenData.data.id,
                        "device_session": this.state.deviceSessionId ? this.state.deviceSessionId : "",
                        "card_name": "",
                        "card_number": "",
                        "card_exp_month": "",
                        "card_exp_year": "",
                        "card_cvc": ""
                    }
                }
                orderObject = { ...orderObject, ...card_token }
            } else
            if (this.isPaymentPlatform(PAY_PLATFORMS.Conekta)) {
                const {conektaTokenData} = this.state
                const cardToken = {
                    "card_token": {
                        "card_token_id": conektaTokenData.id,
                        "card_name": "",
                        "card_number": "",
                        "card_exp_month": "",
                        "card_exp_year": "",
                        "card_cvc": ""
                    }
                }
                orderObject = {...orderObject, ...cardToken}
            }
        }

        if (process.env.NODE_ENV === 'development')
            console.log('Order Sended', orderObject)        

        return orderObject
    }

    getOrderItems(cart_items) {  
        const items = cart_items.map(cartItem => {
            let orderItem = {
                "product_id": cartItem.product.id,
                "quantity": cartItem.quantity,
                "price": cartItem.price,
                "comment": ""                
            }            
            return orderItem
        })        
        return items
    }

    openMercadoPagoPreferenceCheckout(orderResult) {
        const {mercadoPagoPreference} = this.state
        if (mercadoPagoPreference) {
            this.setState({showMercadoPagoModal: true})
        }        
    }

    retryPayProcess(mercadoPagoPreference) {
        //console.log('retryPayProcess', mercadoPagoPreference)
        this.openMercadoPagoPreferenceCheckout()
    }

    showOrderAsPending(orderResult) {
        //this.setState({redirect: `/shop/checkout/pending/${orderResult.id}`})
    }

    showOrderAsSuccess(orderResult) {
        //this.setState({redirect: `/shop/checkout/success/${orderResult.id}`})
        this.setState({showOrderAsSuccess: true})
    }

    dismissAnGoToRoute(path) {
        if (isMobile()) {
            this.setState({
                showOrderAsSuccess: false
            }, () => {
                const {onCloseRequest} = this.props
                if (onCloseRequest){
                    onCloseRequest()
                }
                setTimeout(() => {
                    window.location.href = "elbuencomercio://app"                    
                }, 200);
            })
        } else {
            const {distributorSiteId, sellerSiteId} = this.props;
            if (path == 'plans') {
                window.location.href = `/plans/${distributorSiteId}/${sellerSiteId}/`
            }
        }
    }

    showOrderAsFailure(orderResult) {
        this.setState({
            markAsFailure: true
        })
    }

    isCreditOrDebitPayment() {
        const {payMethodSelected} = this.state
        if (payMethodSelected && payMethodSelected.slug == PAYMENT_TYPES.TARJETA_CREDITO_DEBITO) {
            return true
        } else 
            return false
    }

    isPaymentPlatform(platformSlug) {
        const { payMethodSelected } = this.state
        if (payMethodSelected.payplatform
            && payMethodSelected.payplatform.payplatform
            && payMethodSelected.payplatform.payplatform.slug === platformSlug) {
            return true
        } else
            return false
    }


}


const mapStateToProps = (state) => ({        
    login: state.Login,
    user_master: state.User.master,    
});

const mapDispatchToProps = {    
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopPageCheckout);
