import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { logoutUser } from '../../store/actions';

class Logout extends Component {
    /**
     * Redirect to login
     */
    componentDidMount = () => {
        // emit the event        
        this.props.logoutUser( this.props.login, this.props.history);
    };

    render() {
        return <React.Fragment></React.Fragment>;
    }
}

const mapStateToProps= state => {
    return {
        login: state.Login        
    }
}

export default withRouter(
    connect(
        mapStateToProps,
        { logoutUser }
    )(Logout)
);
