import React from "react";
import { Link } from "react-router-dom";

export default function qashopmainnav(props) {
  const baseURL= props.url;
  return (
    <li>
      <Link to="/#" className="has-arrow waves-effect">
        <i>
          <span
            className="iconify"
            data-icon="entypo:shopping-cart"
            data-inline="false"
          ></span>
        </i>
        <span> Ventas</span>
      </Link>
      <ul className="sub-menu" aria-expanded="false">
        <li>
          <a href={baseURL+'/shop/admin/dashboard/'}>
            Dashboard
          </a>
        </li>
        <li>
          <a href={"/shop/admin/sale-delivery/"}>
            Punto de Venta
          </a>
        </li>
        <li>
          <Link to="/#" className="has-arrow waves-effect">
            <span> Reportes</span>
          </Link>
          <ul className="sub-menu" aria-expanded="false">
            <li>
              <a href={baseURL+"/shop/admin/reports/gain/all/"}>
                Ganancias
              </a>
            </li>
          </ul>
        </li>
        <li>
          <Link to="/#" className="has-arrow waves-effect">
            <span> Pedidos</span>
          </Link>
          <ul className="sub-menu" aria-expanded="false">
            <li>
              <a href={baseURL+"/shop/admin/dashboard/"}>
                Pedidos
              </a>
            </li>
            <li>
              <a href={baseURL+"/shop/admin/sale-delivery/"}>
                Todos
              </a>
            </li>
            <li>
              <a href={baseURL+"/shop/admin/sale-delivery/"}>
                Acumulados
              </a>
            </li>
          </ul>
        </li>
        <li>
          <a href={baseURL+"shop/admin/sale-delivery/"}>
            Bargains
          </a>
        </li>

        <li>
          <Link to="/#" className="has-arrow waves-effect">
            <span> Configuración</span>
          </Link>
          <ul className="sub-menu" aria-expanded="false">
            <li>
              <a href={baseURL+"/shop/cms/shop-config/"}>
                Módulo Ventas
              </a>
            </li>
            <li>
              <a href={baseURL+"/shop/admin/price-rules/"}>
                Reglas de Precios
              </a>
            </li>
            <li>
              <a href={baseURL+"/shop/admin/delivery-rules/"}>
                Reglas de Envios
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </li>
  );
}
