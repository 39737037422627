import React, { Component, useLayoutEffect, useState, useEffect } from 'react'

import { Link, Redirect } from 'react-router-dom';

import CatInfo from "./CatConfig/CatInfo";
import CatBrands from "./CatConfig/CatBrands"
import CatProviders from "./CatConfig/CatProviders";
import CatVariants from "./CatConfig/CatVariants";
import CatTags from "./CatConfig/CatTags";

import { connect } from 'react-redux';
import { toast } from 'react-toastify';

class CatConfig extends Component {
  constructor (props) {
    super(props)
    this.state = {
      category: undefined
    }
  }

  checkSession() {
    const {login} = this.props
    // console.log(login);
    if (!login) {
        toast.info('Su sesión caducó o no esta disponible.')
        this.setState({redirect: '/logout'})
    } else {
      toast.success('Bienvenido a perfil de categoria')
    }
  }

  componentDidMount() {
    this.checkSession()
    this.setState({ categoryID: this.props.match.params.categoryId })
  }

  render() {
    const {redirect, showNoGuides} = this.state
    if (redirect) {
      return (
        <Redirect to={redirect} />
      )
    }
    return (
      <>
        <CatInfo props={this.props} bindState={ this.state } />
        <CatVariants props={ this.props } bindState={ this.state } />
        <CatBrands props={this.props} bindState={ this.state } />
        <CatProviders props={ this.props } bindState={ this.state } />
        <CatTags props={ this.props } bindState={ this.state } />
      </>
    )
  }
}

const mapStateToProps = (state) => ({
    master: state.User.master,
    login: state.Login
});

export default connect(mapStateToProps, null)(CatConfig);