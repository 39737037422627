import { Types } from './actions';

const INITIAL_STATE = {    
    user: null,
    master:{},    
  };

  export default function users(state = INITIAL_STATE, action) {
    switch (action.type) {

      case Types.GET_MENU_REQUEST: {
        return {
          ...state,
        };
      }

      case Types.GET_MENU_SUCCESS: {
        return {
          ...state,
          cargando: false, 
          master: action.payload.master,
        };
      }     

      case Types.CARGANDO:
        return { ...state, cargando: true };

      case Types.CLEAR_USER_DATA:
        return { ...INITIAL_STATE};
        
      case Types.GET_COLLABORATOR_REQUEST:
        return {
          ...state,
          user: null,
          request_user: true,
          collaborator_user_error: false
        }

      case Types.COLLABORATOR_SUCCESS:
        return {
          ...state,
          user: action.payload.userData,
          request_user: false
        }

      case Types.COLLABORATOR_ERROR:
        return {
          ...state,          
          request_user: false,
          collaborator_user_error: true
        }
  
      default: {
        return state;
      }
    }
  }
  