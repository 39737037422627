import React, { Component } from 'react'

import { Link } from 'react-router-dom';
import { Row, Col, Card, CardBody, CardFooter, Button } from 'reactstrap';

import user2 from "../../assets/images/users/user-2.jpg";
import user3 from "../../assets/images/users/user-3.jpg";
import { getMarketplaceMyCompanies } from '../../api/api';
import Paginator from '../../components/Paginator';

export default class CompaniesWeb extends Component {

    constructor(props) {
        super(props)
        this.state = {
            pages: [],
            currentPage: 0
        }
    }

    componentDidMount(){
        this.fetchMyCompanies(0)
    }

    fetchMyCompanies(currentPage, nextUrl) {
        getMarketplaceMyCompanies(nextUrl).then(result =>{
            if(result.status == 200){
                const {pages} = this.state
                pages[currentPage] = result.data
                this.setState({
                    pages
                })
            }
        }).catch(error => console.error('Error', error))
    }

    onNextClick() {
        const { pages, currentPage } = this.state
        const nextPage = pages[currentPage + 1]        
        if (nextPage) {
            this.setState({
                currentPage: currentPage + 1
            })
        } else {
            const page = pages[currentPage]        
            if (page && page.next) {            
                this.setState({
                    currentPage: currentPage + 1
                }, this.fetchMyCompanies(currentPage + 1, page.next))
            }
        }
    }

    onPreviusClick() {
        const { pages, currentPage } = this.state
        if (currentPage > 0) {
            this.setState({
                currentPage: currentPage - 1
            })            
        }
    }

    onPageClick(pageSelected) {
        const { pages } = this.state        
        if (pages[pageSelected]) {
            this.setState({
                currentPage: pageSelected
            })            
        }
    }

    renderCompanies() {
        const { pages, currentPage } = this.state
        const page = pages[currentPage]        
        if (page) {
            const results = page.results
            return results.map(it => {
                return (
                    <Col xl={4} md={6}>
                        <Card className="directory-card">
                            <CardBody>
                                <div className="media">
                                    <img
                                        src={it.logo}
                                        alt=""
                                        className="img-fluid img-thumbnail avatar-lg"
                                    />
                                    <div className="media-body ml-3">
                                        <h5 className="text-primary font-size-18 mt-0 mb-1">
                                            {it.name}
                                            </h5>
                                        <p className="font-size-12 mb-2">Contacto:</p>
                                        <p className="mb-0">{it.contact_name}</p>
                                    </div>
                                    {/* <ul className="list-unstyled social-links float-right">
                                        <li>
                                            <Link to="#" className="btn-primary">
                                                <i className="mdi mdi-facebook"></i>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="#" className="btn-info">
                                                <i className="mdi mdi-twitter"></i>
                                            </Link>
                                        </li>
                                    </ul> */}
                                </div>
                                <hr />
                                <div className="button-items">
                                    
                                    <Button
                                        color="info"
                                        outline
                                        className="waves-effect waves-light"
                                        >
                                        Invitar a Colaborar
                                    </Button>
                                    <Button
                                        color="info"
                                        outline
                                        className="waves-effect waves-light"
                                        >
                                        Ver Invitación
                                    </Button>

                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                )
            })
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="container-fluid">
                    <Row className="align-items-center">
                        <Col sm={6}>
                            <div className="page-title-box">
                                <h4 className="font-size-18">Mis Empresas</h4>
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item active">
                                        Marketplace / Mis Empresas
                                    </li>
                                </ol>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        {
                            this.renderCompanies()
                        }                      
                    </Row>
                    <Row>                    
                        <div style={{display:'flex', justifyContent:'flex-end', flex:1, paddingRight:'12px'}}>
                            <Paginator 
                                onPreviusClick = {() => this.onPreviusClick()}
                                onNextClick = {() => this.onNextClick()}
                                onPageClick = {(pageSelected) => this.onPageClick(pageSelected)}
                                currentPage = {this.state.currentPage + 1}
                                totalPages = {this.state.pages.length}
                                />                                        
                        </div>                      
                    </Row>
                </div>
            </React.Fragment>
        )
    }

}